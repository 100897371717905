.mainContainer {
    display: flex;
    align-items: center;
    gap: 8px;
}
.infoCircleBlue {
    background-image: url("./assets/info-circle.svg");
    background-repeat: no-repeat;
    background-position: center;
    height: 32px;
    width: 32px;
}
.textContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 4px;
    font-size: 16px;
}
.link {
    color: #0d2675;
}
@media screen and (max-width: 550px) {
    .textContainer {
        font-size: 13px;
        gap: 2px;
    }
}
