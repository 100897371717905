.confiCards {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.skeletonSize{
    width: 100%;
    height: 112px;
}

.card,
.addCard {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 24px;
    background: #f5f7fa;
    border-radius: 16px;
    position: relative;
    column-gap: 16px;
    width: 100%;
}

.fullWidthCard{
    grid-column: 1 / span 2;
}
.addCard {
    cursor: pointer;
}
.addCard,
.cardCredit {
    justify-content: center;
}
.cardCredit {
    cursor: pointer;
}
.cardIconPickUp {
    width: 64px;
    height: 64px;
    background-image: url("../../assets/sucursalesIcon.svg");
    background-repeat: no-repeat;
    background-size: contain;
}
.cardIcon {
    width: 64px;
    height: 64px;
    background-image: url("./assets/location.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}
.cardCaptionAdd {
    width: 40px;
    height: 40px;
    background-image: url("./assets/addLocation.svg");
    background-repeat: no-repeat;
    background-size: contain;
}
.addTitle {
    margin: 0;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #282828;
    background-color: transparent;
    border: none;
}
.iconContainer {
    display: flex;
    align-items: center;
}
.creditCardIcon {
    width: 64px;
    height: 64px;
    background-image: url("./assets/creditCardIcon.svg");
    background-size: contain;
    background-repeat: no-repeat;
}
.visaLogo {
    background-image: url("../../assets/Vector-visa.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 20.89px;
    width: 64.11px;
    min-width: 64.11px;
}
.calendarIcon{
    background-color: #0d2675;
    padding: 16px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 64px;
    width: 64px;
}
.calendarIcon::after{
    content: "";
    background-image: url("./assets/calendar.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 32px;
    width: 32px;
}
.masterCardLogo {
    background-image: url("../../assets/mastercard.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 47px;
    width: 64.11px;
    min-width: 64.11px;
}
.cardCaption {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    overflow: hidden;
}
.cardCaption > h1 {
    margin: 0;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #282828;
}
.sucursalTitleCard {
    margin: 0;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px !important;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #282828;
}
.cardCaption > p {
    margin: 0;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: #52606c;
}
.sucursal {
    font-size: 16px !important;
}
.direccionDetalles {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    /* max-width: 65%; */
}
.cardCaption > button,
.changeBtn {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #006EF2;
    margin-left: auto;
    border: none;
    background: none;
    cursor: pointer;
    margin-top: auto;
}
@media (min-width: 768px) {
    .confiCards {
        /*  flex-direction: row; */
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
    }
    .card {
        width: 100%;
        min-width: 100%;
        max-width: 454px;
    }
    .cardCaption > h1 {
        font-size: 16px;
    }
    .cardCaption > p {
        font-size: 16px;
    }
    .cardCaption > button,
    .changeBtn {
        font-size: 18px;
        line-height: 20px;;
    }
    .addTitle {
        font-size: 20px;
    }
    .direccionDetalles {
        /*  max-width: 65%; */
    }
}

@media screen and (max-width: 768px) {
    .cardIconPickUp, .calendarIcon, .creditCardIcon, .cardIcon {
        width: 51px;
        height: 51px;
    }
    .calendarIcon{
        padding: 9px;
    }
}
