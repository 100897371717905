.radio{
  display: grid;
  place-content: center;
  padding: 4px;
  border: #0D267580 1.5px solid;
  border-radius: 50%;
}
.innerCircle{
  transition: scale 0.2s;
  scale: 0;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  content: '';
  background-color: #0D2675;
}
.innerCircleActive{
  transition: scale 0.2s;
  scale: 1;
}