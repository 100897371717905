.selectArrow {
    position: absolute;
    width: 100%;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("./assets/drop_down_arrow.svg");
    background-repeat: no-repeat;
    background-position-x: calc(100% - 14px);
    background-position-y: 50%;
}
.container {
    position: relative;
    width: 100%;
}

.selectContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 37.5px;
    border: 1px solid #dce0e4;
    border-radius: 8px;
}
.noMarginBottom {
    margin-bottom: 0;
}
.marginBottom {
    margin-bottom: 14px;
}
.error .selectContainer {
    border: 1px solid red;
}
.helperText {
    padding: 0px 14px 0px;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    text-align: left;
    margin-top: 3px;
    width: 100%;
}

.error .helperText {
    color: #d32f2f;
}
@media (min-width: 426px) {
    .selectContainer {
        height: 46px;
    }
}
