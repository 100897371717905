.overlay {
    position: fixed !important;
    opacity: 0;
    background: #000;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    cursor: pointer;
    touch-action: none !important;
    transition: all 0.4s ease-in-out;
}

.overlay.animate {
    opacity: 0.3;
}

.modal,
.modal2 {
    position: fixed;
    background: #fff;
    padding: 0px;
    width: calc(100% + 2px);
    max-height: 90%;
    height: -moz-fit-content;
    height: fit-content;
    overflow-y: auto;
    border-radius: 20px 20px 0 0;
    box-shadow: 0 0 10px rgb(0 0 0 / 25%);
    z-index: 1001;
    bottom: 0;
    left: calc(50% + 1px);
    top: 150%;
    transform: translate(-50%, 150%);
    transition: 0.4s ease-in-out;
}

.modalContent {
    /* padding: 20px; */
}

.noTouch {
    touch-action: none;
}

.modal.animate,
.modal2.animate {
    transform: translate(calc(-50% - 1px), -100%);
    top: 100%;
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(87.99deg, #006ef2 3.65%, #01aefa 121.61%);
    padding-right: 20px;
}
.modalTitle {
    font-weight: 500;
    font-size: 25px;
    line-height: 29px;
    letter-spacing: 0.02em;
    color: #4d4d4d;
    /* margin-bottom: 8px; */
}
.close,
.closeWhite {
    height: 16px;
    width: 16px;
    z-index: 1002;
    position: absolute;
    cursor: pointer;
}
.close {
    right: 0px;
    top: 0px;
    margin: 24px;
}
.closeWhite {
    top: 31.75px;
    right: 32.5px;
}
.modalHeader img {
    margin-left: auto;
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.modalBody {
    /* padding: 20px; */
}

@media (min-width: 570px) {
    .modal {
        border-radius: 10px;
    }
    .modal2 {
        border-radius: 20px;
    }
    .modal,
    .modal2 {
        width: calc(100% - 16px * 2);
        max-width: 500px;
        opacity: 0;
        transform: translate(-50%, -50%);
    }
    .modal.animate,
    .modal2.animate {
        top: 50%;
        opacity: 1;
        transform: translate(-50%, -50%);
        transition: 0.2s ease-in-out;
    }
    .closeWhite {
        top: 31.64px;
        right: 32px;
        cursor: pointer;
    }
    .overlay {
        background: #000;
    }
    .modalContent {
        padding: 0;
    }
}
