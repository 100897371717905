.insideSectionWrapper {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
}

.headerWrapper {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}

@media (min-width: 768px) {
    .insideSectionWrapper {
        row-gap: 32px;
    }
    .headerWrapper {
        row-gap: 16px;
    }
}
