.mapa-form {
  padding: 20px;
  padding-top: 4px;
}

.mapa-form h1 {
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;
  color: #282828;
  letter-spacing: 0.02em;
}

.mapa-form h2 {
  margin: 16px 0;
  font-weight: normal;
  font-size: 18px;
  line-height: 21.09px;
}

.formulario-input{
  border: none;
  background-color: #F5F7FA;
  border-radius: 8px;
}

/* .mapa .gmnoprint, .mapa .gm-fullscreen-control, .mapa .gm-style-cc, .mapa .gm-style div a div img{   Esconde los botones y mensajes que trae el mapa por defecto  
    display: none !important;
} */

.mapa .gm-fullscreen-control,
.mapa .gm-style div a div img,
.gm-bundled-control,
.gm-style-cc {
  /* Esconde los botones y mensajes que trae el mapa por defecto  */
  display: none !important;
}

.gmnoprint {
  /* top: 30px !important; */
  border-radius: 10px;
  font-size: 3px !important;
}

.gm-style-mtc li {
  display: none;
}

.gm-style-mtc button {
  height: 30px !important;
}

.mapa div {
  border-radius: 10px !important;
}

button.abrir-mapa {
  position: relative;
  float: right;
  bottom: 39px;
  right: 12px;
  background-color: var(--blueRNB);
  color: white !important;
  border-radius: 5px;
  padding: 5px 10px;
  border: none;
}

button.abrir-mapa span {
  font-size: 13px !important;
  margin-right: 10px;
}

div.container-confirmar {
  width: 93%;
  max-width: 402px;
  position: absolute;
  height: 0;
}

button.confirmar-ubicacion {
  position: relative;
  float: center;
  bottom: 55px;
  right: -52%;
  transform: translateX(-50%);
  font-size: 14px !important;
  left: auto;
  width: 85%;
  background-color: var(--blueRNB);
  color: white !important;
  border-radius: 5px;
  padding: 5px 10px;
  border: none;
}

button.ubicacion-usuario {
  position: relative;
  float: right;
  bottom: 172px !important;
  right: 15px;
  background-color: transparent;
  color: white !important;
  border: none;
}

.container-marker {
  position: absolute;
  width: 92%;
  max-width: 402px;
  z-index: 999999999999;
  pointer-events: none;
}

.container-marker img {
  height: 30px;
  width: 30px;
  position: relative;
  right: -50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  bottom: -50%;
  z-index: 1 !important;
}

.container-searchbox {
  position: absolute;
  width: 92%;
  z-index: 1;
  height: 0;
  max-width: 402px;
}

input.searchbox {
  position: relative;
  right: -54%;
  top: 80px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  padding: 6px 4px 6px 10px;
  border: none;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.297);
  border-radius: 5px;
  /* box-sizing: border-box; */
  outline: none;
}

.container-searchbox > div {
  position: relative;
  right: -50%;
  top: 80px;
  padding: 6px 4px 6px 10px;
  max-width: 227px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  padding: 2px 4px;
  border-radius: 3px !important;
  z-index: 1000 !important;
}

.container-searchbox div div {
  padding: 4px 4px 4px 10px;
  border-radius: 3px !important;
  max-width: 227px;
}

.mapa-form .formulario-mapa {
  margin: 25px 0;
}

.mapa-form .formulario-mapa label {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 12px;
  letter-spacing: 0.02em;
  margin-bottom: 10px;
}

.formulario-mapa .input-container {
  margin: 15px 0;
}

.formulario-mapa .input-container label span {
  color: #e22e3d;
}

.mapa-form .guardar button {
  background-color: var(--blueRNB);
  border-radius: 5px;
  text-align: center;
  font-size: 15px;
  line-height: 12px;
  padding: 10px;
  color: white;
  width: 100%;
  border: none;
}

.mapa-form .guardar-exito button {
  border: none;
  border-radius: 5px;
  text-align: center;
  font-size: 15px;
  line-height: 12px;
  padding: 10px;
  background-color: white;
  border: 3px solid var(--blueRNB);
  color: var(--blueRNB);
  width: 100%;
}

.mapa-form .guardar-exito button span span {
  text-decoration: underline;
}

.error {
  align-items: center;
  text-align: center;
  padding: 0.5rem;
  background-color: #e22e3d;
  border-radius: 5px;
  font-size: 14px;
  /* font-weight: 500 !important; */
}
.error span {
  width: 50px;
  margin: 0;
  padding: 0;
  color: white;
  text-align: center;
}
.buttons-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  gap: 24px;
  max-width: 75%;
  margin-left: auto;
}

