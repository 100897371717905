.container {
  background: #cce2fc;
  padding: 16px 24px;
  border-radius: 8px;
  width: 100%;
  margin: 0 auto;
  color: #006ef2;
}

.container div {
  display: grid;
  grid-template-columns: max-content 1fr;
}

.container div div {
  display: flex;
  justify-content: center;
}

div.img {
  margin-right: 20px;
}

div.content {
  flex-direction: column;
}

div.content h1 {
  font-weight: 500;
  font-size: 16px;
  margin: 0 0 5px 0;
}

div.content p {
  font-size: 14px;
  margin: 0;
}

.noSubtitle h1 {
  margin: 0 !important;
  text-align: left;
}
