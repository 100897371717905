.resumen {
  background: #f8f8f8;
  border-radius: 10px;
  padding: 20px 15px;
  margin-top: 20px;
}

.title {
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  color: #4d4d4d;
  margin-bottom: 21px;
}

.info {
  display: flex;
  color: #4d4d4d;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: center;
}

.info_label {
  font-size: 15px;
}

.info_price {
  font-size: 15px;
}

.total {
  display: flex;
  color: #4d4d4d;
  padding-top: 21px;
  margin-top: 21px;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
}

@media (min-width: 768px) {
  .remresumen {
    padding: 30px 37px;
  }
  .container {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: center;
  }
  .title {
    font-size: 25px;
    line-height: 25px;
  }
  .info_label,
  .info_price {
    font-size: 15px;
  }
  .info_price {
    margin-left: auto;
  }
  .total_label,
  .total_price {
    font-size: 20px;
  }
  .total_price {
    margin-left: 10px;
  }
  .info {
    justify-content: flex-start;
  }
  .total {
    justify-content: flex-end;
    border-top: 1px solid #e1e3ea;
  }
  .right,
  .left {
    width: 50%;
  }
  .right {
    border-right: 1.5px solid #e1e3ea;
  }
  .left {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .inform {
    width: 200px;
  }
  .logo {
    width: 222px;
    height: 183px;
    margin: 0 auto;
    background-image: url(./assets/resumen_vector.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
}
