.containerMyInput {
    padding: 9.5px 16px;
    color: #4d4d4d;
    font-weight: normal;
    width: 100%;
    background-color: #f5f7fa;
    border-radius: 8px;
    outline: 0;
    border: 0;
    font-size: 16px;
    display: grid;
    grid-template-columns: max-content 1fr;
    gap: 15px;
}
.ContainerMyInput div {
    display: flex;
    justify-items: center;
    width: 100%;
    align-items: center;
}
.ContainerMyInput img {
    width: 24px;
    height: 24px;
}

.containerMyInput .myInput {
    padding: 0;
    background-color: transparent;
    width: 100%;
}
.myInput {
    padding: 9.5px 16px;
    color: #4d4d4d;
    font-weight: normal;
    width: 100%;
    background-color: #fff;
    border-radius: 8px;
    outline: 0;
    border: 1px solid #ccc;
    font-size: 16px;
    transition: all 0.3s ease-in-out;
}

.myInput:disabled {
    background-color: #f5f7fa;
    color: #999999a1;
}
/* input error  */
.inputError {
    border: 1px solid #c92432;
    transition: all 0.3s ease-in-out;
}
