.mainContainer{
  padding:28px 24px;
  border-radius: 10px;
  border: 1.5px solid rgba(255, 255, 255, 0.10);
  background: #0D2675;
  backdrop-filter: blur(7.5px);
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 45px;
}
.mainContainer:hover{
  opacity: 0.8;
  cursor: pointer;
  border-color:  rgba(255, 255, 255, 0.227);
}
.header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
    color: #FFF;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; 
  letter-spacing: -0.068px;
}

.timeContainer{
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}
.clockIcon{
  background-image: url('../../../../../assets/whiteClock.svg');
  background-position: center;
  background-repeat: no-repeat;
  width: 10px;
  height: 10px;
}
.remainingTime{
  font-weight: 400;
}
.description{
  color: #FFF;
font-family: Roboto;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 150%;
letter-spacing: -0.037px;
overflow: hidden;
display: -webkit-box;
-webkit-line-clamp: 2;
line-clamp: 2; 
-webkit-box-orient: vertical;
}
@media (max-width: 610px) {
  .mainContainer{
    margin: 0;
    max-width: none;
  }
}