@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
:root {
    --yellowRNB: #fdc000;
    --blueRNB: #006ef2;
    --grayRNB: #6e6e78;
    --grayRNB2: #4d4d4d;
    --RNB-radius: 8px;
    --MAX-WIDTHRNB: 1500px;
}

a:hover {
    text-decoration: none !important;
}

.web {
    display: block;
}
.mobile {
    display: none;
}
.grecaptcha-badge {
    visibility: hidden;
}
@media (max-width: 690px) {
    .web {
        display: none;
    }
    .mobile {
        display: block;
    }
}

body {
    overflow: auto !important;
    overflow-x: hidden !important;
    font-size: 15px !important;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

main {
    width: 100%;
    font-family: Roboto;
    color: var(--grayRNB2);
}
