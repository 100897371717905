button.boton_ACH {
    width: 100%;
    background-color: #48c296;
    height: auto;
    min-height: 48px;
    padding: 5px 10px;
    border-radius: 5px;
    border: medium none;
    outline: currentcolor none medium;
    color: white;
    font-weight: 500;
    font-size: 16px;
    line-height: 14px;
}
button.boton_ACH span img {
    margin-right: 15px;
}

div.boton_ACH_Modal_overlay {
    position: fixed;
    display: block;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100vw;
    z-index: 9998;
    background: rgb(0, 0, 0) none repeat scroll 0% 0%;
    opacity: 0.5;
}

div.boton_ACH_Modal {
    position: fixed;
    display: block;
    top: 50%;
    left: 50%;
    width: 95%;
    max-width: 498px;
    background: rgb(255, 255, 255) none repeat scroll 0% 0%;
    opacity: 1;
    transform: translate(-50%, -50%);
    z-index: 9999;
    padding: 20px;
    color: #4d4d4d;
    border-radius: 10px;
}

div.boton_ACH_Modal_header {
    padding: 20px 15px;
}

.boton_ACH_Modal_top {
    display: flex;
}

.boton_ACH_Modal_close {
    width: 24px;
    height: 24px;
    margin-top: -12px;
    background-image: url(./assets/close_modal.svg);
    background-size: 12px 12px;
    background-position: center;
    background-repeat: no-repeat;
    margin-left: auto;
    cursor: pointer;
}

div.boton_ACH_Modal_header h1 {
    font-weight: 500;
    font-size: 25px;
    margin-bottom: 20px;
}

div.boton_ACH_Modal_header p.boton_ACH_dsc {
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    color: #4d4d4d80;
    margin: 0;
}

section.boton_ACH_Modal_body_saldo {
    padding: 10px 15px;
    width: calc(100% - 30px);
    background-color: #f6f6f6;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    color: #4d4d4dcc;
    margin-bottom: 20px;
    margin-left: 15px;
    margin-right: 15px;
}

button.boton_ACH_Modal_body_infoBanco {
    display: block;
    left: 50%;
    padding: 10px;
    background-color: var(--blueRNB);
    border: none;
    color: white;
    border-radius: 5px;
    width: calc(100% - 30px);
    max-width: 431px;
    margin: auto;
}

button.boton_ACH_Modal_body_infoBanco svg {
    margin-right: 5px;
    margin-top: -3px;
}

.boton_ACH_transfe {
    font-size: 11px;
    font-weight: normal;
    color: #4d4d4d80;
    margin-top: 10px;
    margin-bottom: 0;
}

/* section.boton_ACH_Modal_body_saldo span{
    padding:  10px;
    width: 100%; 
    background-color: #f6f6f6;
    border-radius: 10px
} */

@media screen and (min-width: 650px) {
    div.boton_ACH_Modal_header p.boton_ACH_dsc {
        font-size: 15px;
    }
    section.boton_ACH_Modal_body_saldo {
        font-size: 15px;
    }
}
