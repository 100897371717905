.layout {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
    position: relative;
}
.container {
    display: flex;
    flex-direction: row;
    width: 100%;
}
.main {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: scroll;
    height: calc(100vh - 48px);
    position: relative;
}

.content {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 87px);
}

@media (min-width: 430px) {
    .sidebarExpandido .cuponesWrapper {
        grid-template-columns: 1fr 1fr !important;
    }
}

@media (max-width: 431px) {
    .overlay {
        top: 82px;
        position: absolute;
        overflow: hidden;
        overflow-y: scroll;
        background-color: rgb(0, 0, 0);
        opacity: 0.5;
        width: 100%;
        z-index: 999;
        touch-action: none;
    }
    .layout.ios {
        height: auto !important;
    }
    .ios .main {
        height: auto !important;
    }
}
