.header {
    margin-bottom: 25px;
}

.header h1 {
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 25px;
    line-height: 100%;
    letter-spacing: 0.02em;
    color: #4d4d4d;
}

.body {
    max-height: 253px;
    overflow-y: auto;
}

.card {
    border: 1px solid rgba(212, 212, 212, 0.5);
    box-sizing: border-box;
    border-radius: 10px;
    padding: 16px;
    margin: 20px 0 0 0;
    cursor: pointer;
}

.card:first-of-type {
    margin-top: 0;
}

.card.active {
    border: 1px solid rgba(0, 110, 242, 0.5);
}

.cardContainer {
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
}

.logo {
    width: 56px;
    height: 48px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.type_visa {
    background-image: url(./assets/visa.svg);
}

.type_mastercard {
    background-image: url(./assets/mastercard.svg);
}

.cardData {
    display: flex;
    flex-direction: column;
    justify-content: left;
    width: 50%;
    align-self: center;
    padding-left: 8px;
}

.cardAlias {
    font-size: 16px;
    line-height: 24px;
    color: #4d4d4d;
    font-weight: 500;
    width: 100%;
    margin: 0;
}

.cardNumberSM {
    font-size: 14px;
    line-height: 21px;
    color: #4d4d4d;
    font-weight: 400;
    width: 100%;
    margin: 0;
}

.cardNumber {
    font-size: 16px;
    line-height: 24px;
    color: #4d4d4d;
    font-weight: 500;
    width: 100%;
    margin: 0;
}

.cardExpires {
    color: rgba(77, 77, 77, 0.5);
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    margin: 0;
}

.expireSoon {
    color: #dca700;
}

.expired {
    color: #e00021;
}

.container {
    height: 370px;
    display: flex;
    flex-direction: column;
}

.radioIcon {
    width: 24px;
    height: 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(./assets/radioIconOff.svg);
    margin-right: 4px;
    cursor: pointer;
}

.radioIcon.active {
    background-image: url(./assets/radioIconOn.svg);
}

.favoriteChip {
    margin-left: auto;
    padding: 4px 8px;
    height: 23px;

    background: rgba(0, 110, 242, 0.1);
    border: 1px solid #006ef2;
    box-sizing: border-box;
    border-radius: 50px;

    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: #006ef2;
}

.errorContainer {
    width: 100%;
    margin-top: 20px;
}

.footer {
    padding: 9px 0 16px 0;
    margin-top: auto;
}

.footerButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #006ef2;
    background: #f5f7fa;
    border-radius: 5px;
    border: 1px solid rgba(212, 212, 212, 0.5);
    font-size: 15px;
    font-weight: 500;
    padding: 10px 0;
    width: 152px !important;
    text-align: center;
    cursor: pointer;
}

.addIcon {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(./assets/card-add.svg);
    width: 24px;
    height: 24px;
    margin-right: 8px;
}

.payButton span {
    /* background: #006ef2; */
    /* color: #ffffff;
  font-weight: 500;
  font-size: 15px;
  line-height: 12px; */
}

@media (min-width: 500px) {
}
