.container {
    padding: 0px 24px 24px;
}
.headerRegistrar {
    margin: 0px 0 0 24px;
}
.reCatpcha {
    position: absolute;
    z-index: 1011;
}

.onRow {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.telefonoContainer {
    width: 100%;
}

.error {
    border: 1px solid #d32f2f !important;
    margin-bottom: 0 !important;
}

.errorText {
    padding: 0px 14px 0px;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    text-align: left;
    margin-top: 3px;
    width: 100%;
}

.errorText {
    color: #d32f2f;
}
@media (min-width: 370px) {
    .onRow {
        flex-direction: row;
        justify-content: space-between;
        gap: 16px;
    }
}
@media (min-width: 425px) {
    .onRow:nth-child(3) {
        margin: -6px 0;
    }
    .onRow:nth-child(6) {
        margin: 0 0 -6px;
    }
}
.OutlinedInput_label {
    font-size: 10px;
}

.preferredLanguage {
    display: none;
    width: 100%;
}

.termsCondition {
    width: 100%;
    margin-left: 11px;
}

.termsConditionText {
    display: flex;
    flex-direction: row;
}

.submitContainer {
    margin: 16px 0;
}

.radioBtn {
    position: absolute;
    left: 6px;
    width: 24px;
    height: 24px;
}

.radioBtn {
    background-image: url("./assets/IconBase.svg");
}

.radioBtn:checked {
    background-image: url("./assets/ic_radio_on.svg");
    background-repeat: no-repeat;
}

.radioGroup {
    display: flex;
    column-gap: 16px;
}

.radioContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    height: 38px;
    border-radius: 8px;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #919eab;
}

.radioDisabled {
    display: none;
}

input {
    border-style: none;
}

/* The container */
.radioContainer {
    position: relative;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid #dce0e4;
}

.radioContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkmark {
    position: absolute;
    top: 50%;
    left: 10px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-image: url("./assets/IconBase.svg");
    background-size: contain;
    background-position: center;
    transform: translateY(-50%);
    transition: filter 0.8s;
}

.radioContainer:hover input ~ .checkmark {
    filter: brightness(1.1);
}

.radioContainer input:checked ~ .checkmark {
    background-image: url("./assets/ic_radio_on.svg");
}

.radioContainer:has(input:checked) {
    border: 1px solid #006ef2;
    color: #006ef2;
}

.tarifaInfo {
    display: flex;
    align-items: center;
    column-gap: 8px;
    margin-bottom: 24px;
    margin-left: 8px;
}
.tarifaInfoIcon {
    width: 16px;
    height: 16px;
    background-image: url("./assets/infoIcon.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.tarifaInfoText {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    text-align: center;
    color: #637381;
    margin: 0;
}

@media (min-width: 424px) {
    .radioContainer {
        height: 46px;
        font-size: 16px;
    }
}

@media (min-width: 678px) {
    .submitContainer {
        margin: 24px 0;
    }
    .onRow:nth-child(3) {
        column-gap: 24px;
    }
    .preferredLanguage {
        display: block;
    }
    .tarifaInfoText {
        font-size: 16px;
    }
}

@media (min-width: 769px) {
    .tarifaInfo {
        margin-bottom: 36px;
    }
    .headerRegistrar {
        margin-top: 80px;
    }
    .onRow:nth-child(3) {
        flex-direction: row;
    }
    .headerRegistrar {
        margin: 80px 0 0 0;
    }
    .container {
        padding: 0 0 24px;
        margin-top: 0;
    }
    .onRow {
        flex-direction: row;
        column-gap: 24px;
    }
    .radioGroup {
        column-gap: 24px;
    }
}
