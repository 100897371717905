.cardsContainer {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}
.relampagoCard,
.expressCard {
    display: flex;
    flex-direction: column;
    padding: 0px 16px 24px;
    /* width: 343px;
    height: 156px; */
    position: relative;
    border-radius: 12px;
    border: none;
    padding: 20px 16px 24px;
    gap: 10px;
    cursor: pointer;
}
.cardDisable {
    background: #ffffff;
    box-shadow: 0px 0px 24px -4px rgba(0, 0, 0, 0.12);
}
.cardActive {
    background: #0d2675;
    box-shadow: 0px 0px 30px rgba(0, 102, 238, 0.45);
}
.topCard {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.cardIconRelampago {
    width: 19px;
    height: 32px;
    margin: 0 15px;
}
.relampagoActive {
    background-image: url("./assets/relampagoIconActive.svg");
    background-size: 100%;
    background-repeat: no-repeat;
}
.relampagoInActive {
    background-image: url("./assets/relampagoIcon.svg");
    background-size: 100%;
    background-repeat: no-repeat;
}
.cardIconExpress {
    width: 23px;
    height: 32px;
    margin: 0 15px;
}
.expressActive {
    background-image: url("./assets/expressIconActive.svg");
    background-size: 100%;
    background-repeat: no-repeat;
}
.expressInActive {
    background-image: url("./assets/expressIcon.svg");
    background-size: 100%;
    background-repeat: no-repeat;
}
.hidden {
    opacity: 1;
    position: absolute;
    z-index: -1;
}
.cardTitle {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 100%;
    letter-spacing: 0.02em;
    width: 250px;
    margin: 0;
    text-align: left;
}
.titleBlackText {
    color: #282828;
}
.whiteText {
    color: #fff;
}
.circleIcon {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 26px;
    top: 26px;
}
.circleIconActive {
    background-image: url("./assets/circleActive.svg");
    background-size: contain;
}
.circleIconDisable {
    background-image: url("./assets/circle.svg");
    background-size: contain;
}

.description {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 125%;
    text-align: left;
}
.descriptionBlackText {
    color: #52606c;
}
.logoRelampago .logoExpress {
    display: none;
}
.separator {
    height: 20px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: #919eab;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    column-gap: 10px;
}
.line {
    display: inline-block;
    border: 1px solid #dce0e4;
    height: 1px;
    width: 100%;
}
@media (min-width: 768px) {
    .cardsContainer {
        flex-direction: row;
        column-gap: 32px;
    }
    .relampagoCard,
    .expressCard {
        padding: 32px 24px;
        max-width: 446px;
        width: 100%;
        max-height: 374.99px;
    }
    .cardIconRelampago,
    .cardIconExpress {
        display: none;
    }
    .cardTitle {
        font-weight: 600;
        line-height: 125%;
    }
    .description {
        max-width: 334px;
        align-self: center;
    }
    .separator {
        display: none;
    }
    .logoRelampago,
    .logoExpress,
    .logoRelampagoInActive {
        width: 196.5px;
        min-height: 161.99px;
        margin: 17.37px auto 32px;
    }
    .logoRelampago {
        background-image: url("./assets/relampagoLogo.svg");
        background-size: 100% 100%;
    }
    .logoRelampagoInActive {
        height: 171.52px;
        background-image: url("./assets/relampagoLogoInActive.svg");
        background-size: 100% 100%;
    }
    .logoExpActive {
        background-image: url("./assets/expressLogoActive.svg");
        background-repeat: no-repeat;
        background-size: 100%;
    }
    .logoExpInActive {
        background-image: url("./assets/expressLogo.svg");
        background-repeat: no-repeat;
        background-size: 100%;
    }
}
