.header {
    padding: 43px 45px 0 31px;
    display: flex;
    justify-content: space-evenly;
    margin-block-end: 20px;
    background: linear-gradient(180deg, #ecf1f9 0%, rgba(245, 247, 250, 0) 100%);
}
.headerIlustration {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 38%, rgb(255, 255, 255)),
        url("../../assets/Peronsaje-en-arte.svg");
    background-repeat: no-repeat;
    background-position: center;
    width: 170.84px;
    height: 177.08px;
    margin-block-start: 10px;
}
.headerTitle {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    line-height: 1;
    font-size: 45px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #282828;
    margin-block-end: 15px;
}
.headerSubtitle {
    width: 245.26px;
    height: 48.25px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #282828;
}
.body {
    padding: 0 24px;
}
.bodyTitle {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    margin-block-end: 24px;
    color: #282828;
}
.bodyP {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #52606c;
    margin-block-end: 16px;
}
.bodyBenefits {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.bodyBenefits li {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    list-style: url("../../assets/Ellipse-1.svg");
    margin-inline-start: 21px;
    color: #52606c;
}
.bodyBenefits li span {
    font-weight: 600;
}
.buttonContainer {
    padding: 0 24px 24px 24px;
}

@media screen and (max-width: 488px) {
    .header {
        padding: 42px 38px 0 38px;
    }
    .headerTitle {
        margin-block-start: 5px;
        font-size: 38px;
    }
    .headerSubtitle {
        width: 159.43px;
        height: 31.36px;
        font-size: 14px;
    }
    .headerIlustration {
        width: 162.53px;
        height: 138.88px;
        background-position: top;
        background-image: linear-gradient(
                to bottom,
                rgba(255, 255, 255, 0) 59%,
                rgba(255, 255, 255, 0.854)
            ),
            url("../../assets/Peronsaje-en-arte.svg");
    }
    /* .button {
        width: 327px;
        height: 48px;
        color: #919eab;
    } */
    .bodyTitle,
    .bodyP,
    .bodyBenefits li {
        font-size: 13px;
    }
}
@media screen and (max-width: 398px) {
    .header {
        padding: 42px 25px 0 25px;
    }
    .bodyBenefits li {
        margin-inline-end: 21px;
    }
}

@media (min-width: 500px) {
    .headerTitle {
        white-space: nowrap;
        width: 200px;
    }
}
