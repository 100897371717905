.beneficiosMainContainer{
  display: flex;
  flex-direction: column;
  gap:16px;
}
.title{
  margin: 0;
}
.cardsContainer{
  max-width: 924px;
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  justify-content: center;
}
@media screen and (max-width:820px) {
  .beneficiosMainContainer{
    padding-block-start: 24px;
  }
  .title{
  text-align: center;
  }
  .cardsContainer{
    gap: 0;
  }
}
@media screen and (max-width:710px) {
  .title{
    font-size: 25px;
  }
}