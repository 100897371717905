.mainContainer{
  max-width:924px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-block-end: 53px;
}
.mainContainer h2{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  margin: 0;
}
.accordionContainer{
  border-radius: 8px;
  overflow: hidden;
}
@media screen and (max-width:820px) {
  .mainContainer{
    gap: 24px;
  }
  .mainContainer h2{
  text-align: center;
  font-size: 25px;
  margin: 0;
  }
}