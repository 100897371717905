.logo{
  grid-area: logo;
  width: calc(100% - 16px);
  height: auto;
  border-radius: 8px;
  align-self: center;
}
.logoDesactivado{
  opacity: 0.3;
}
.nombreEmpresa{
  grid-area: nombreEmpresa;
  min-width: max-content;
  color:#282828;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
}
.categoriasContainer{
  display: grid;
  align-items: end;
  min-width: max-content;
  align-self: baseline;
}
.categoriaLabel{
  display: inline-block;
  overflow: hidden;
  text-align: center;
  max-width: 100%;
  font-size: 12px;
  font-weight: 500;
  background-color: #E5E8EB;
  color:#212B36;
  border-radius: 32px;
  grid-area: categorias;
}
.catgoriaP{
  margin-inline: 8px;
  margin-block: 2px;
  max-width: 100%;
  overflow: hidden;
}

.description{
  color: #52606C;
  letter-spacing: 0.048px;
  font-size: 12px;
  line-height: normal;
  grid-area: description;
}
.container{
  overflow: hidden;
  position: relative;
  border-radius: 8px;
  padding: 16px;
  display: grid;
  row-gap: 8px;
  background-color: #F4F6F8;
  grid-template-columns:  [first] 96px [second] min-content [third] auto ;
  grid-template-rows: repeat(3, auto);
  grid-template-areas:
    "logo  categorias categorias "
    "logo nombreEmpresa nombreEmpresa "
    "logo description description ";
}
.container:hover{
  background-color: #d9dddf;
  cursor: pointer;
}
.DiscoundBadge{
  position: absolute;
  top: 0;
  left: 0;
  background-color: #0D2675;
  padding: 4px;
  border-radius: 0 0 8px 0;
}
.discountLabel{
  color: #FFFFFF;
  font-size: 12px;
  font-weight: 700;
  line-height: 18.75px;
  border-radius: 8px;
  top: 0;
  right: 0;
}
.giftIcon{
  background-image: url('../../../../assets/giftIcon.svg');
  background-repeat: no-repeat;
  background-size: contain;
  width: 16px;
  height: 16px;
  margin-inline: 4px;
}

@media (max-width: 610px) {
  .nombreEmpresa, .description {
    display: none;
  }
  .logo, .categoriasContainer{
    justify-self: center;
  }
  .categoriaLabel{
    margin-left: 0;
    max-width: none;
    justify-self: stretch;
  }
  .categoriasContainer{
    border-radius: 0 0 8px 8px ;
    background-color: #E5E8EB;
    display: grid;
    justify-content: center;
    justify-self: stretch;
    align-self: self-end;
  }
  .container{
    gap: 0;
    grid-template-columns: auto;
    grid-template-rows: repeat(2, auto);
    grid-template-areas: 
    "logo"
    "categorias";
    padding: 0;
    min-height: 100px;
  }
  .logo{
    border-radius: 8px 8px 0 0;
    max-width: none;
    max-height: none;  
    width: 100%;
    max-height: 100px;
    object-fit: cover;
  }
}
@media (max-width: 610px) {
  .categoriaLabel{
    max-width: 130px;
    max-height: 22px;
  }
}