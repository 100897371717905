.container {
    opacity: 0;
    position: fixed;
    display: flex;
    left: 0;
    top: 0;
    align-content: center;
    justify-content: center;
    align-items: center;
    background-color: #006ef2;
    height: 100vh;
    width: 100vw;
    z-index: 999999999999999;
    overflow: hidden;
}
.enabled {
    opacity: 1;
    pointer-events: all;
}
.disabled {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.5s ease-in-out;
}
.content {
}

.logo {
    width: 350px;
    height: 75px;
    background-image: url("./assets/logo_new.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 59px;
}
.loader {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Loader */
.ldsEllipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 13px;
}
.ldsEllipsis div {
    position: absolute;
    top: 0px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.ldsEllipsis div:nth-child(1) {
    left: 8px;
    animation: ldsEllipsis1 0.6s infinite;
}
.ldsEllipsis div:nth-child(2) {
    left: 8px;
    animation: ldsEllipsis2 0.6s infinite;
}
.ldsEllipsis div:nth-child(3) {
    left: 32px;
    animation: ldsEllipsis2 0.6s infinite;
}
.ldsEllipsis div:nth-child(4) {
    left: 56px;
    animation: ldsEllipsis3 0.6s infinite;
}
@keyframes ldsEllipsis1 {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
@keyframes ldsEllipsis3 {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(0);
        opacity: 0;
    }
}
@keyframes ldsEllipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}

@media screen and (min-width: 1023px) {
    .logo {
        width: 449px;
        height: 79px;
        margin-bottom: 100px;
    }
}
