.container {
  font-family: roboto, arial, helvetica;
  margin-top: 15px;
  color: #4d4d4d;
}

.title {
  font-size: 26px;
}

.subTitle {
  font-weight: normal;
  font-size: 13px;
}

.body {
  margin-top: 25px;
}

.body section {
  text-align: left;
  padding: 0 0 24px 0;
}

.body section div {
  position: relative;
}

.body section div img {
  position: absolute;
  right: 16px;
  top: 10px;
  cursor: pointer;
  /* transform: translate(-50%, -50%); */
}

.body section label {
  margin-bottom: 16px;
  font-weight: normal;
  font-size: 13px;
  line-height: 12px;
}

.body section input {
  outline: none;
  padding: 9px 17px 9px 15px;
  background-color: #f5f7fa;
  border-radius: 8px;
  border: none;
  width: 100%;
}

.body section input:focus {
  border: 1px solid #006ef2;
}

.btn {
  border: none;
  background-color: var(--blueRNB);
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  line-height: 12px;
  padding: 15px;
  color: white;
  width: 100%;
}
.btn_desactivado {
  border: none;
  background-color: var(--blueRNB);
  border-radius: 5px;
  text-align: center;
  opacity: 0.3;
  font-size: 16px;
  line-height: 12px;
  padding: 15px;
  color: white;
  width: 100%;
}

.success_container {
  border: none;
  border-radius: 5px;
  text-align: center;
  font-size: 15px;
  line-height: 18px;
  padding: 10px;
  background-color: white;
  border: 2.5px solid var(--blueRNB);
  color: var(--blueRNB);
  width: 100%;
}
