.ripple {
    -webkit-border-radius: 8px 8px 8px 8px;
    border-radius: 8px 8px 8px 8px;
}

.fullWidth {
    width: 100%;
}

button.btn_submit {
    height: 40px;
    min-width: 89px;
    /* max-width: none; */
    width: 100%;
    background-color: #006ef2;
    font-weight: 500;
    border: none;
    color: #f9f9f9;
    padding: 24px;
    text-align: center;
    font-size: 16px;
    -webkit-border-radius: 8px ;
    border-radius:  8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* .arrowLeft {
    margin-right: 8px;
} */

/* .toRightArrow {
    padding-left: 15px !important;
} */

/* .toLeftArrow {
    padding-right: 15px !important;
} */

button.whiteButton {
    background-color: transparent !important;
    color: black;
    border: 1px solid rgba(212, 212, 212, 0.5);
}

button.btn_submit:hover {
    background-color: #035ece;
    transition: background-color 0.8s;
    cursor: pointer;
}

button.btn_submit:disabled {
    opacity: 0.56;
    pointer-events: none;
}

button.btn_submit.dark:disabled {
    opacity: 0.8;
}

button span {
    text-align: center;
}

.contenedor_btnEnviar {
    width: 100%;
}

.subcontenedor_btnEnviar {
    width: max-content;
    margin: auto;
}

.animacion_botonCargando {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    width: 97.067px;
    height: 19.5px;
    margin: 0 auto;
    position: relative;
    top: -8px;
    /* display: none; */
}

.animacion_botonCargando_small {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;

    height: 19.5px;
    margin: auto;
    position: relative;
    top: -9px;
    transform: scale(0.8);

    /* display: none; */
}

.loader:before,
.loader:after,
.loader {
    border-radius: 50%;
    width: 2.1em;
    height: 2.1em;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: load7 1.8s infinite ease-in-out;
    animation: load7 1.8s infinite ease-in-out;
}

.loader.dark:before,
.loader.dark:after,
.loader.dark {
    -webkit-animation: load7Dark 1.8s infinite ease-in-out;
    animation: load7Dark 1.8s infinite ease-in-out;
}

.loader {
    margin: 0 auto;
    font-size: 5px;
    position: relative;
    text-indent: -9999em;
    -webkit-animation-delay: 0.16s;
    animation-delay: 0.16s;
}

.loader:before {
    left: -3.5em;
}

.loader:after {
    left: 3.5em;
    -webkit-animation-delay: 0.32s;
    animation-delay: 0.32s;
}

.loader:before,
.loader:after {
    content: "";
    position: absolute;
    top: 0px;
}

@-webkit-keyframes load7 {
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em #ffffff;
    }

    40% {
        box-shadow: 0 2.5em 0 0 #ffffff;
    }
}

@keyframes load7 {
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em #ffffff;
    }

    40% {
        box-shadow: 0 2.5em 0 0 #ffffff;
    }
}

@-webkit-keyframes load7Dark {
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em #a7a7a7;
    }

    40% {
        box-shadow: 0 2.5em 0 0 #a7a7a7;
    }
}

@keyframes load7Dark {
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em #a7a7a7;
    }

    40% {
        box-shadow: 0 2.5em 0 0 #a7a7a7;
    }
}
