.upload {
  padding: 15px;
  padding-bottom: 30px;
}
h1.title {
  font-weight: 500;
  font-size: 25px;
  margin-bottom: 8px;
}
#dragndrop_zone {
  border: 3px dashed #4d4d4d43;
  /* margin: 15px; */
  padding: 4px;
  padding-top: 50px;
  padding-bottom: 20px;
  cursor: pointer;
  border-radius: 10px;
}

.ProfileUpload {
  display: block;
  border: none;
  margin: 0 auto;
}

.file-info span {
  color: var(--blueRNB) !important;
  font-size: 14px;
}

.modalUploadProfileP_Container .modal-content {
  /* border: 2px solid rgb(138, 138, 138) !important; */
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
  margin: 10px !important;
  justify-content: center !important;
}

.modalUploadProfileP_Container .modal-body {
  padding: 0 !important;
  overflow: hidden !important;
}

@media only screen and (min-width: 500px) {
  .modalUploadProfileP_Container .modal-sm {
    max-width: 375px !important;
  }
}

.btn-SelectImage {
  color: #fff;
  background-color: var(--blueRNB);
  border-radius: 10px;
  border: 2px solid transparent;
  transition: all 0.5s ease-in-out;
  padding: 13px 20px;
  display: block;
  margin: 0 auto;
}

.btn-SelectImage:hover {
  background-color: #f9f9f9;
  border: 2px solid var(--blueRNB);
  color: var(--blueRNB);
  transition: all 0.5s ease-in-out;
}

.btn-UploadPP {
  display: block;
  /* margin: 0 auto; */
  border: 1px solid transparent;
  color: #f3f3f3;
  border-radius: 4px;
  padding: 10px 10px;

  align-self: center;
  justify-self: center;
  width: 100%;
}

.btn-UploadPP-active {
  background: var(--blueRNB);
}

.btn-UploadPP-disabled {
  background-color: #006ef280;
}

.groupUploadViwers {
  margin-top: 25px;
}

.uploadedImage {
  border: 2px solid #d4d4d4;
  display: flex;
  align-items: center;
  color: #c1c1c1;
  text-align: center;
  border-radius: 4px;
}

.uploadedImage p {
  text-align: center;
  width: 100%;
}

#dragndrop_zone .file-svg {
  display: block;
  margin: 0 auto;
}

.group__imgInfoContainer {
  display: block;
  margin: 0 auto;
  /* border: 1px solid red; */
  min-height: 105px;
  max-width: 250px;
}

.group__imgRequirements {
  /* border: 1px solid red; */
}

.group__imgRequirements > .dragndrop_message {
  color: #4d4d4dd3;
  font-size: 16px;
}

.group__imgRequirements > .support_message {
  color: #4d4d4d8e;
  font-size: 13px;
}
.group__imgRequirements > .size_message {
  color: #4d4d4d73;
  font-size: 12px;
}

.uploadProgress {
  margin: 20px 0;
  padding: 10px 0;
}

.uploadProgress_top {
  display: flex;
  align-items: center;
}

.uploadProgress_label {
  display: flex;
  font-size: 13px;
  color: #9e9e9e;
  margin-right: 5px;
}
.uploadProgress_bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.uploadProgress_bar {
  width: 100%;
}
.uploadProgress_bar_bg {
  background: rgb(0 110 242 / 30%);
  height: 4px;
  width: 100%;
  border-radius: 4px;
}

.uploadProgress_bar_prg {
  background: #006ef2;
  border-radius: 4px;
  height: 4px;
  transition: width 0.7s ease-out;
}

.uploadProgress_percentage {
  color: #9e9e9e;
  font-size: 13px;
  width: 38px;
  text-align: center;
}

.uploadLoader,
.uploadLoader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.uploadLoader {
  margin: 10px auto;
  font-size: 1.5px;
  position: relative;
  text-indent: -9999em;
  border-top: 2.1em solid rgba(0, 110, 242, 0.3);
  border-right: 2.1em solid rgba(0, 110, 242, 0.3);
  border-bottom: 2.1em solid rgba(0, 110, 242, 0.3);
  border-left: 2.1em solid rgb(0 110 242);
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
}

@keyframes blink {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

.uploading span {
  animation-name: blink;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

.uploading span:nth-child(2) {
  animation-delay: 0.2s;
}

.uploading span:nth-child(3) {
  animation-delay: 0.4s;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
