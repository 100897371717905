.remtienda_wrapper {
  padding: 20px 16px;
  max-width: 920px;
  margin: 0 auto;
}

.remtienda_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.remtienda_title {
  font-weight: 400;
  font-size: 25px;
  color: #4d4d4d;
  margin-bottom: 0;
}

.remtienda_cart {
  position: relative;
  background-image: url(assets/cart_ico.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 31px;
  height: 30px;
  cursor: pointer;
}

.remcontador_cart {
  filter: grayscale(1) brightness(2);
}

.remcontador_cart:after {
  display: none;
}

.remtienda_cart:after {
  position: absolute;
  content: attr(data-items);
  background: #006ef2;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  font-size: 10px;
  width: 13px;
  height: 13px;
  line-height: 14px;
  right: -3px;
  top: -3px;
}

.remtienda_cart.remcart_loading:after {
  content: none;
}

.remtienda_subtitle {
  font-size: 13px;
  opacity: 0.5;
  color: #4d4d4d;
}

.remtienda_filters {
  padding: 15px 0;
}

.remtienda_filter_sort {
  padding: 0 0 15px 0;
}

.remtienda_sort_label {
  color: #4d4d4d;
  opacity: 0.8;
  font-size: 13px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 10px;
}

.remtienda_sort_select select {
  background-color: #f5f5f5;
  color: #4d4d4d;
  height: 38px;
  width: 100%;
  min-width: 200px;
  line-height: 20px;
  font-size: 13px;
  font-weight: 400;
  border: none;
  border-radius: 5px;
  padding: 8px 14px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(../assets/collapse_ico.svg);
  background-size: 15px 9px;
  background-repeat: no-repeat;
  background-position-x: calc(100% - 14px);
  background-position-y: center;
}

.remtienda_sort_select select:focus,
.remtienda_sort_select select:focus-visible,
.remtienda_sort_select select:focus-within {
  outline: none !important;
}

.remtiemda_search_container {
  display: flex;
  align-items: center;
}

.remtienda_filter_search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f5f5f5;
  border-radius: 7px;
  padding: 9px 16px;
  width: 100%;
  min-width: 237px;
  margin-right: 16px;
}

.remtienda_search_ico {
  background-image: url(assets/input_search.svg);
  background-size: contain;
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #016ef2;
  padding: 9px 16px;
  height: 35px;
  width: 35px;
  border-radius: 5px;
  margin-left: auto;
  cursor: pointer;
  flex-shrink: 0;
}

.remtienda_filter_search form {
  width: 100%;
}

.remtienda_search_input {
  background: none;
  border: none;
  width: 100%;
  font-size: 13px;
  line-height: 17px;
  font-weight: 400;
}

.remtienda_search_input:focus,
.remtienda_search_input:focus-visible,
.remtienda_search_input:focus-within {
  outline: none !important;
}

.remtienda_search_clear {
  background-image: url(assets/input_clear.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 13px;
  width: 13px;
  cursor: pointer;
  padding-left: 15px;
  opacity: 0;
  pointer-events: none;
}

.remsq_visible.remtienda_search_clear {
  opacity: 1;
  pointer-events: auto;
}

.remtienda_list {
  padding-bottom: 15px;
}

.remtienda_paginator {
  display: flex;
  justify-content: space-between;
}

.remtienda_btn {
  justify-content: center;
  display: flex;
  background: #f0f0f0;
  color: #9e9e9e;
  border-radius: 5px;
  padding: 10px 31px;
  font-size: 15px;
  font-weight: 500;
  line-height: 12px;
  cursor: pointer;
  width: 100%;
}

.remtienda_paginator_prev {
  background: none;
}

.remtienda_paginator_prev:before {
  display: block;
  content: " ";
  background-image: url(./assets/tienda_prev.svg);
  background-size: contain;
  width: 9px;
  height: 15px;
  margin-right: 10px;
}

.remtienda_paginator_next:after {
  display: block;
  content: " ";
  background-image: url(./assets/tienda_next.svg);
  background-size: contain;
  width: 9px;
  height: 15px;
  margin-left: 10px;
}

/* Sin resultados de busqueda */

.busqueda_empty {
  font-size: 13px;
  /* line-height: 14px; */
}

.busqueda_empty > .sinresultadosimg {
  width: 95%;
  height: 350px;
  background-image: url(assets/sinresultados.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 auto;
  margin-top: 20px;
}

.busqueda_empty > b {
  color: #4d4d4d;
  font-weight: 500;
  display: block;
  margin-top: 25px;
}

.busqueda_empty > p {
  color: #4d4d4d;
  margin-top: 10px;
  opacity: 0.5;
}

@media screen and (min-width: 650px) {
  .remtienda_wrapper {
    background: #fff;
    box-shadow: 0 0 7px rgb(0 0 0 / 10%);
    border-radius: 10px;
    margin: 5vh auto;
    padding: 52px 40px;
  }
  .remtienda_title {
    font-size: 40px;
  }
  .remtienda_subtitle {
    font-size: 15px;
  }
  .remtienda_filters {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
  .remtienda_filter_sort {
    padding: 0;
  }
  .remtienda_sort_label {
    display: none;
  }
  .remtienda_sort_select select {
    padding: 9px 16px;
  }
  .remtienda_list {
    padding: 30px 0;
  }
  .remtienda_paginator {
    justify-content: center;
  }
  .remtienda_paginator_prev {
    background: #f0f0f0;
    margin-right: 10px;
  }
  .remtienda_paginator_prev.remtienda_u {
    margin-right: auto;
  }
  .remtienda_paginator_next.remtienda_u {
    margin-left: auto;
  }
  .remtienda_btn {
    width: auto !important;
  }
  .busqueda_empty {
    font-size: 15px;
    /* line-height: 14px; */
  }
}
