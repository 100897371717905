.premiumMainContainer{
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 60px;
  padding: 80px 15.776% 0 15.776% ;
}
@media screen and (max-width:970px) {
  .premiumMainContainer{padding: 32px 4.27% 0 4.27% ;
    gap: 24px;
  }
}
@media screen and (max-width:1350px) {
  .premiumMainContainer{padding: 32px 10% 0 10% ;
  }
}
@media screen and (max-width:1162px) {
  .premiumMainContainer{padding: 32px 7% 0 7% ;
  }
}
@media screen and (max-width:1088px) {
  .premiumMainContainer{padding: 32px 5% 0 5% ;
  }
}