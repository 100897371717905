.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    background: #006ef2;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    z-index: 999;
}
.container.premium {
    background: #0d2675;
}
.left_content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}
.logo {
    background-image: url("./assets/logo_new.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 180px;
    height: 80px;
}
.navIconContainer {
    width: 82px;
    padding: 32px 18px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.navIcon {
    width: 26px;
    height: 18px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
}
.navIcon span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #ffffff;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
}

.navIcon span:nth-child(1) {
    top: 0px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

.navIcon span:nth-child(2) {
    top: 8px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

.navIcon span:nth-child(3) {
    top: 16px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

.navIcon.open span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: -2px;
}

.navIcon.open span:nth-child(2) {
    width: 0%;
    opacity: 0;
}

.navIcon.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 16px;
}

.right_content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 0 43px 0 0;
}

.iconNotif {
    width: 24px;
    height: 24px;
    cursor: pointer;
    position: relative;
    background-size: contain;
    background-position: center;
    background-image: url(./assets/package.svg);
    background-repeat: no-repeat;
}
.iconNotif.nuevaNotificacion:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #db586c;
}
.profilePicture {
}
.profilePicture img {
    display: flex;
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 50%;
    background: #d4d4d4;
    border: 1px solid #d4d4d4;
    margin-left: 24px;
    align-content: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1.5px solid white;
}

@media (max-width: 431px) {
    .right_content {
        padding: 0 32px 0 0;
    }
    .profilePicture {
        display: none;
    }
}

@media (max-width: 768px) {
    .logo {
        width: 133px;
        height: 40px;
    }
    .container {
        touch-action: none !important;
    }
}
