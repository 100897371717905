.mainContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
}
.container{
  display: flex;
  flex-direction: column;
  gap: 60px;
  max-width: 925px;
  margin-top: 80px;
  margin-inline: 16px;
}

@media (max-width: 610px) {
  .mainContainer{
    display: block;
    min-height: calc(100vh - 102px);
    height: 100%;
  }
  .container{
  margin-top: 24px;
  max-width: calc(100% - 32px);
  height: 100%;
  gap:24px;
  }
}