.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 56px;
}

.logo {
    width: 141px;
    height: 30px;
    background-image: url(./assets/logo_new.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    /* margin-left: 24px; */
    margin-bottom: 40px;
}

.hexagon {
    width: 114px;
    height: 123px;
    background-image: url(./assets/hexagon0.svg?05);
    background-repeat: no-repeat;
    background-position: bottom left;
    background-size: contain;
}

.titleAlter {
    width: 100%;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #282828;
    margin-right: 24px;
}
@media (min-width: 329px) {
    .titleAlter {
        font-size: 25px;
        line-height: 30px;
    }
}
@media screen and (min-width: 769px) {
    .container {
        padding-top: 0;
    }

    .logo {
        margin-left: 0;
        margin-bottom: 36px;
    }

    .hexagon {
        display: none;
    }
    .titleAlter {
        max-width: 500px;
        font-size: 34px;
        line-height: 36px;
        text-align: left;
        margin-top: -80px;
        margin-bottom: 40px;
    }
}
