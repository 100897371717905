.container {
    display: flex;
    flex-direction: column;
    padding: 24px;
    max-width: 420px;
    min-width: 355px;
    width: 100%;
    height: 553px;
    background: #fff;
    box-shadow: 0px 0px 24px -4px rgba(0, 0, 0, 0.12);

    border-radius: 10px;
}
.title {
    margin-bottom: 16px;
}
.infoContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;
}
.info {
    margin-left: 14px;
}
.infoTitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #282828;
    margin: 0;
}
.infoSubtitle,
.title {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #4d4d4d;
}
.infoSubtitle {
    margin: 0;
}
@media screen and (max-width: 1280px) {
    .container {
        width: 100%;
        max-width: 270px;
        min-width: 270px;
    }
}
@media screen and (max-width: 821px) {
    .container {
        height: 521px;
    }
    .infoTitle,
    .infoSubtitle {
        font-size: 13px;
        line-height: 15px;
    }
}
