.title_container {
  margin-bottom: 15px;
  margin-top: 15px;
}
.title_container h1 {
  font-weight: 500;
  font-size: 25px;
  margin-bottom: 8px;
}
.title_container p {
  margin: 0;
  font-size: 13px;
  font-weight: 400;
  line-height: 150%;
}
.input_imagen {
  padding: 23px 0 10px 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
}

.img_filled {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23006EF2FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  background-color: #006ef226;
  color: #006ef2;
}

.img_unfilled {
  background-color: #f5f7fa;
  color: #4d4d4d80;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23D4D4D4FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.input_imagen img {
  margin-bottom: 10px;
}
.input_imagen h2 {
  font-size: 13px;
  font-weight: 400;
}
.input_imagen p {
  font-size: 10px;
  font-weight: 400;
}

.form_container {
  margin-bottom: 25px;
}
.input_container {
  margin: 24px 0 0 0;
  display: block;
}
.input_container label {
  display: block;
  font-size: 13px;
  margin-bottom: 8px;
  color: #4d4d4d;
}
.input_container input.custom {
  font-size: 13px;
  color: #999999;
  width: 100%;
  height: 40px;
  padding: 4px 16px;
  border: none;
  outline: none;
  background-color: #f5f7fa;
  border-radius: 8px;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
}
.inputDate {
  font-size: 13px;
  color: #999999;
  width: 100%;
  height: 40px;
  padding: 4px 16px;
  border: none;
  outline: none;
  background-color: #f5f7fa;
  border-radius: 8px;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
}
.inputDate > div {
  border: none;
}
.inputDate input:invalid {
  background: transparent !important;
}
.inputDate input {
  outline: none !important;
  color: #999 !important;
}

.button_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
}

.omitir_btn {
  padding: 15px 0;
  font-size: 15px;
  text-align: center;
  color: #006ef2;
  background-color: white;
  border: none;
  border-radius: 5px;
  line-height: 12px;
}
.guardar_btn {
  padding: 15px 0;
  font-size: 15px;
  width: 100%;
  text-align: center;
  background-color: #006ef2;
  color: white;
  border: none;
  border-radius: 5px;
  line-height: 12px;
}

.success_container {
  border: 2px solid #006ef2;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  background-color: white;
  color: #006ef2;
  padding: 15px 10px;
}
