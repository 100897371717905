.benefitCardsContainer{
  overflow: hidden;
  gap: 32px;
  max-width: 295px;
}
.slidesContainer {
    display: flex;
    width: 100%;
    height: 100%;
}
.cardsContainerMobile{
  display: none;
}

@media (max-width: 610px) {
  .benefitCardsContainer{
    margin-top: 24px;
    max-width: 100%;
    overflow: scroll;
  }
  .slidesContainer{
    display: none;
  }
  .cardsContainerMobile{
    max-height: 300px;
    margin-bottom: 24px;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}