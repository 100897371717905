div.error_item_Modal_overlay {
  position: fixed;
  display: block;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  z-index: 999998;
  background: rgb(0, 0, 0) none repeat scroll 0% 0%;
  opacity: 0.5;
}

div.error_item_Modal {
  position: fixed;
  display: block;
  top: 50%;
  left: 50%;
  width: 95%;
  max-width: 400px;
  background: rgb(255, 255, 255) none repeat scroll 0% 0%;
  opacity: 1;
  transform: translate(-50%, -50%);
  z-index: 999999;
  padding: 20px;
  color: #4d4d4d;
  border-radius: 10px;
}

div.error_item_Modal_header {
  padding: 20px 0;
}
.error_item_Modal_top {
  display: flex;
}

.error_item_Modal_top.error_border {
  border-bottom: 1px solid #e1e3ea;
}

.error_item_Modal_close {
  width: 24px;
  height: 24px;
  margin-top: -12px;
  background-image: url(./assets/close_modal.svg);
  background-size: 12px 12px;
  background-position: center;
  background-repeat: no-repeat;
  margin-left: auto;
  cursor: pointer;
}
.error_item_Modal_warning {
  width: 24px;
  height: 24px;
  margin-top: 4px;
  margin-right: 10px;
  background-image: url(./assets/warning_logo.svg);
  background-size: 24px;
  background-position: center;
  background-repeat: no-repeat;
}
div.error_item_Modal_header h1 {
  font-weight: 500;
  font-size: 25px;
}

.error_item_Modal_header h1.error_margin {
  margin-bottom: 20px;
}

div.error_item_Modal_header p {
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: #4d4d4d80;
  margin: 0;
}
div.error_item_Modal_body p {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.02;
  color: #999999;
  margin: 0;
}
