.title{
  font-size: 24px;
  line-height: 28.13px;
  font-weight: 700;
}
.text{
  padding: 24px 0;
  font-size: 16px;
  line-height: 20px;
}
.text span{
  font-weight: 700;
}
.buttonsContainer{
  display: flex;
  flex-direction: column;
  gap:20px
}
.button{
  display: flex;
  align-items: center;
  gap: 8px;
}
.button span{
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
}
.iconSpecContainer{
  margin-top: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}
.iIcon{
  height: 15px;
  width: 15px;
  background-image: url('./assets/i.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.tarifaSpec{
  font-size: 12px;
  line-height: 24px;
  color: #52606C;
  margin-bottom: 0;
}
.buttonSubmit{
  margin-top: 24px;
}