.selectArrow {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("./arrow.svg");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 14px);
  background-position-y: 50%;
}
