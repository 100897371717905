.settingSection {
    margin: 48px 16px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.settingcontainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 924px;
}

.settingContent {
    display: flex;
    flex-direction: column;
    row-gap: 48px;
    width: 100%;
}
.buttonsContainer {
    width: 100%;
    display: flex;
    justify-content: space-around;
    column-gap: 16px;
    margin-top: 51px;
}
.cancelarSuscripcion {
    align-self: center;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    margin-top: 17px;
    color: #919eab;
    border: none;
    background-color: transparent;
}
.toast {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #4d4d4d;
}
@media (min-width: 540px) {
    .buttonsContainer {
        justify-content: flex-end;
        column-gap: 24px;
    }
}
@media (min-width: 768px) {
    .settingSection {
        margin: 120px 16px 50px;
    }
    .settingContent {
        row-gap: 92px;
    }
    .buttonsContainer {
        margin-top: 48px;
    }
}
