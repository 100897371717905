@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap);
.UpdateNotification_container__2-cxg {
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 9999999;
  bottom: -60px;
  right: 16px;
  background: #ffffff;
  border-radius: 4px;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 30%);
}

.UpdateNotification_iconContainer__1gcI3 {
  display: flex;

  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 18px;
  width: 60px;
  height: 60px;
  background-color: #fdc000;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.UpdateNotification_icon__1ywAW {
  width: 24px;
  height: 24px;
  background-image: url(/static/media/refresh.7cbc0e27.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
}

.UpdateNotification_text__1rdTz {
  padding: 8px;
  min-width: 255px;
  display: flex;
  flex-direction: column;
  color: #4d4d4d;
  font-size: 12px;
  line-height: 30px;
}

.UpdateNotification_title__11JUf {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 6px;
}

.UpdateNotification_subtitle__105mq {
  font-size: 12px;
  font-weight: normal;
  margin: 0 !important;
}

.UpdateNotification_close__2kHa2 {
  width: 12px;
  height: 12px;
  margin: 0 16px;
  background-image: url(/static/media/close.03001293.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
}

.UpdateNotification_container__2-cxg.UpdateNotification_show__1BBTK {
  opacity: 1;
  bottom: 16px;
}

.UpdateNotification_container__2-cxg.UpdateNotification_hide__1-f-q {
  opacity: 0;
  bottom: -60px;
}

@media screen and (max-width: 450px) {
  .UpdateNotification_container__2-cxg {
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    width: 90%;
  }
}

.InfoModal_overlay__plkj2 {
    position: fixed;
    opacity: 0.25;
    background: #000;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    cursor: pointer;
    touch-action: none;
}

.InfoModal_overlay__plkj2.InfoModal_overlayNoDismissible__2vFXi {
    display: none;
}

.InfoModal_modal__lxF0p {
    position: fixed;
    background: #fff;
    width: 100%;
    max-width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    box-shadow: 0 0 10px rgb(0 0 0 / 25%);
    border-radius: 20px 20px 0 0;
    z-index: 1001;
    bottom: 0;
    left: 50%;
    top: 150%;
    transform: translate(-50%, 150%);
    transition: 0.4s ease-in-out;
}

.InfoModal_modal__lxF0p.InfoModal_enabled__1AfUa {
    transform: translate(-50%, -100%);
    top: 100%;
}

.InfoModal_modal_body__HsJjy {
    padding-top: 35px;
    padding-bottom: 25px;
}

.InfoModal_modal_close__1jZw8,
.InfoModal_modal_close_white__1E04k {
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    top: 25px;
    right: 25px;
}

.InfoModal_modal_close__1jZw8 {
    background-image: url(/static/media/CloseModalC.3e2ffa8b.svg);
    background-color: #f6f6f6;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-size: 12px;
}

.InfoModal_modal_close_white__1E04k {
    background-image: url(/static/media/closeSvgWhite.bc2ff78f.svg);
    background-size: 100% 100%;
    width: 16px;
    height: 16px;
}

.InfoModal_modal_lottie__1bYM9 {
    z-index: 1;
    width: 88%;
    height: 100%;
    margin: 0 auto;
    padding-top: 35px;
}

.InfoModal_modal_image__34Lim {
    display: block;
    margin: 20px auto 0;
}

.InfoModal_modal_footer__U-AmY {
    padding: 25px 38px 0 38px;
}

.InfoModal_modal_des__Ld1I2 {
    color: #4d4d4d;
    opacity: 0.5;
    font-size: 13px;
    text-align: center;
    padding-bottom: 16px;
}

.InfoModal_modal_title__1dpMi {
    font-weight: 500;
    font-size: 25px;
    margin-bottom: 16px;
}

.InfoModal_modal_btn__2x__J {
    background: #006ef2;
    color: #fff;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 500;
    padding: 10px 0;
    width: 100%;
    text-align: center;
    cursor: pointer;
}

@media (min-width: 570px) {
    .InfoModal_modal__lxF0p {
        border-radius: 20px;
        width: calc(100% - 16px * 2);
        max-width: 425px;
        opacity: 0;
        transform: translate(-50%, -50%);
    }
    .InfoModal_modal_body__HsJjy {
        padding: 40px;
    }
    .InfoModal_modal_des__Ld1I2 {
        opacity: 1;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #4d4d4d;
        padding-bottom: 24px;
    }
    .InfoModal_modal_footer__U-AmY {
        padding: 25px 0 0 0;
    }
    .InfoModal_modal__lxF0p.InfoModal_enabled__1AfUa {
        top: 50%;
        opacity: 1;
        transform: translate(-50%, -50%);
        transition: 0.2s ease-in-out;
    }
    .InfoModal_overlay__plkj2 {
        background: #000;
    }
}
.UserModal_overlay__3CamW {
    position: fixed;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
}
.UserModal_noOverlay__2CU8_ {
    pointer-events: none;
    position: absolute;
}
.UserModal_userModal__2rahi.UserModal_header__2Gl3v {
    position: absolute;
    opacity: 0;
    top: calc(-35px - 82px);
    right: 24px;
    padding: 18px 16px;
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    z-index: 9999;
    transition: top 0.3s ease, opacity 0.7s ease;
    pointer-events: none;
}
.UserModal_userModal__2rahi.UserModal_header__2Gl3v.UserModal_visible__3pzDv {
    top: calc(35px + 82px);
    opacity: 1;
    pointer-events: visible;
}

.UserModal_header__2Gl3v {
    display: flex;
    align-items: center;
    padding: 0 0 calc(16px + 8px) 0;
    border-bottom: 1px solid #d4d4d4;
}
.UserModal_profilePicture__3D-jf {
}
.UserModal_profilePicture__3D-jf img {
    display: flex;
    width: 64px;
    height: 64px;
    object-fit: cover;
    border-radius: 50%;
    background: #d4d4d4;
    border: 2px solid #d4d4d4;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.UserModal_userInfo__BekhX {
    padding: 0 8px;
}

.UserModal_row__3sdxj {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.UserModal_client_verify__1SdWs {
    background: #f1f5f9;
    border: 1px solid #e2e8f0;
    border-radius: 20px;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0px 10px 0px 0px;
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    cursor: pointer;
}

.UserModal_client_verify_icon__2bZ9D {
    width: 20px;
    height: 21px;
    background-image: url(/static/media/IconNoVerificado2.7f282fe8.svg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    margin: -3px 5px -3px -3px;
}

.UserModal_client_verify__1SdWs.UserModal_client_verified__2NFFL .UserModal_client_verify_icon__2bZ9D {
    background-image: url(/static/media/IconVerificado2.a47442b6.svg);
}
.UserModal_client_verify__1SdWs.UserModal_client_verified_process__p2RjN .UserModal_client_verify_icon__2bZ9D {
    background-image: url(/static/media/IconEnVerificacion.64731a5b.svg);
}

.UserModal_client_verify_text__1fAIc {
    font-size: 12px;
    color: #939393;
}

.UserModal_client_verify__1SdWs.UserModal_client_verified__2NFFL .UserModal_client_verify_text__1fAIc {
    color: #4d4d4d;
}

.UserModal_fullName__nvg54 {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #4d4d4d;
    margin-bottom: 4px;
    max-width: 165px;
}
.UserModal_email__2JJf4 {
    font-size: 13px;
    line-height: 150%;
    letter-spacing: 0.02em;

    color: rgba(77, 77, 77, 0.8);
}
.UserModal_footer__1OEn5 {
}
.UserModal_menu__22LOZ {
    margin-top: 10px;
}
.UserModal_menuItem__1iHeB {
    cursor: pointer;
    display: flex;
    min-width: auto;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    justify-content: flex-start;
    white-space: nowrap;
    margin-top: 8px;
    padding: 4px 0px;
}
.UserModal_menuItemIcon__2oC0c {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 21px;
    height: 21px;
}
.UserModal_menuItemText__nAltf {
    font-size: 16px;
    line-height: 32px;
    color: #4d4d4d;
    margin-left: 8px;
}

.UserModal_settingsIcon__3X4T7 {
    cursor: pointer;
    display: none;
    justify-content: flex-end;
    width: 30px;
    height: 21px;
}

.UserModal_settings1__12I35 {
    background-image: url(/static/media/settings1.71cf5059.svg);
}
.UserModal_settings2__2JNK7 {
    background-image: url(/static/media/settings2.cf9e630c.svg);
}

.UserModal_logout__1uVRR {
    background-image: url(/static/media/logout.4fe39e48.svg);
}

@media (max-width: 431px) {
    .UserModal_settingsIcon__3X4T7 {
        display: flex;
    }
    .UserModal_userInfo__BekhX {
        padding: 0 0 0 8px;
    }
    .UserModal_userModal__2rahi.UserModal_header__2Gl3v {
        display: none;
    }
    .UserModal_profilePicture__3D-jf img {
        width: 52px;
        height: 52px;
    }
}

.Header_container__1wwsR {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    background: #006ef2;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    z-index: 999;
}
.Header_container__1wwsR.Header_premium__1bmnV {
    background: #0d2675;
}
.Header_left_content__3i1NE {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}
.Header_logo__3hqQe {
    background-image: url(/static/media/logo_new.4504908f.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 180px;
    height: 80px;
}
.Header_navIconContainer__2Z1Sf {
    width: 82px;
    padding: 32px 18px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Header_navIcon__2vuBY {
    width: 26px;
    height: 18px;
    position: relative;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;
}
.Header_navIcon__2vuBY span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #ffffff;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
}

.Header_navIcon__2vuBY span:nth-child(1) {
    top: 0px;
    transform-origin: left center;
}

.Header_navIcon__2vuBY span:nth-child(2) {
    top: 8px;
    transform-origin: left center;
}

.Header_navIcon__2vuBY span:nth-child(3) {
    top: 16px;
    transform-origin: left center;
}

.Header_navIcon__2vuBY.Header_open__2WkcE span:nth-child(1) {
    transform: rotate(45deg);
    top: -2px;
}

.Header_navIcon__2vuBY.Header_open__2WkcE span:nth-child(2) {
    width: 0%;
    opacity: 0;
}

.Header_navIcon__2vuBY.Header_open__2WkcE span:nth-child(3) {
    transform: rotate(-45deg);
    top: 16px;
}

.Header_right_content__3gYbH {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 0 43px 0 0;
}

.Header_iconNotif__Xgm2Z {
    width: 24px;
    height: 24px;
    cursor: pointer;
    position: relative;
    background-size: contain;
    background-position: center;
    background-image: url(/static/media/package.8cac22c7.svg);
    background-repeat: no-repeat;
}
.Header_iconNotif__Xgm2Z.Header_nuevaNotificacion__1kWU9:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #db586c;
}
.Header_profilePicture__3WU8n {
}
.Header_profilePicture__3WU8n img {
    display: flex;
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 50%;
    background: #d4d4d4;
    border: 1px solid #d4d4d4;
    margin-left: 24px;
    align-content: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1.5px solid white;
}

@media (max-width: 431px) {
    .Header_right_content__3gYbH {
        padding: 0 32px 0 0;
    }
    .Header_profilePicture__3WU8n {
        display: none;
    }
}

@media (max-width: 768px) {
    .Header_logo__3hqQe {
        width: 133px;
        height: 40px;
    }
    .Header_container__1wwsR {
        touch-action: none !important;
    }
}

.Sidebar_container__aewBP {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px;
    background: #ffffff;
    border: 1px solid #d4d4d4;
    box-sizing: border-box;
}
.Sidebar_menu__8CPjl {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    clear: both;
    transition: min-width 0.2s;
    min-width: 32px;
}
.Sidebar_menuItem__3k5es {
    display: flex;
    min-width: auto;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    justify-content: flex-start;
    white-space: nowrap;
    margin: 16px 0;
    padding: 5px 0px;
}

.Sidebar_expandido__2c0i_ .Sidebar_menu__8CPjl {
    min-width: 200px;
}

.Sidebar_activeItem__2LSUQ {
    color: #016ef2 !important;
}

.Sidebar_menuItemText__3iImj {
    visibility: visible;
    opacity: 1;
    font-size: 16px;
    line-height: 32px;
    color: #4d4d4d;
    margin-left: 8px;
    transition: opacity 0.2s linear;
    cursor: pointer;
}

.Sidebar_colapsado__u8fMJ .Sidebar_menuItemText__3iImj {
    width: 0;
    height: 32px;
    margin-left: 0px;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.2s, opacity 0.2s linear;
}

.Sidebar_menuItemIcon__6J3uH {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin-left: 4px;
}

.Sidebar_herramientasText__3Exto {
    display: flex;
    justify-content: space-between;
    min-width: 172px;
}

.Sidebar_menuTop__LYlOV {
}
.Sidebar_menuBottom__2wyhD {
}

.Sidebar_toolsDropdown__1wm8t {
    padding-left: 15px;
    border-left: 3px solid #016ef2;
    margin-left: 15px;
    height: 0;
    width: 0;
    overflow: hidden;
    transition: height 0.4s;
}
.Sidebar_toolsDropdown__1wm8t.Sidebar_expandido__2c0i_ {
    width: 190px;
}

.Sidebar_premium__1Qk1U .Sidebar_toolsDropdown__1wm8t {
    border-left: 3px solid #0d2675;
}

.Sidebar_tool__-1_U- {
    font-weight: normal;
    font-size: 16px;
    line-height: 32px;
    color: #4d4d4db2;
}

.Sidebar_menuItemIcon__6J3uH.Sidebar_herramientas__2FRom.Sidebar_active__38OPZ {
    background-image: url(/static/media/herramientas.3441fc0f.svg);
}
.Sidebar_menuItemIcon__6J3uH.Sidebar_herramientas__2FRom {
    background-image: url(/static/media/herramientas.48fbf4cf.svg);
}

.Sidebar_menuItemIcon__6J3uH.Sidebar_recompensas__3IssY.Sidebar_active__38OPZ {
    background-image: url(/static/media/ticket-star.2a0ff2cf.svg);
}
.Sidebar_menuItemIcon__6J3uH.Sidebar_recompensas__3IssY {
    background-image: url(/static/media/ticket-star.733f929f.svg);
}

.Sidebar_menuItemIcon__6J3uH.Sidebar_metodosDePago__3RilE.Sidebar_active__38OPZ {
    background-image: url(/static/media/metodos-de-pago.df7cafec.svg);
}
.Sidebar_menuItemIcon__6J3uH.Sidebar_metodosDePago__3RilE {
    background-image: url(/static/media/metodos-de-pago.3ebaec17.svg);
}

.Sidebar_menuItemIcon__6J3uH.Sidebar_accountStatus__sC728.Sidebar_active__38OPZ {
    background-image: url(/static/media/account-status.50c8383b.svg);
}
.Sidebar_menuItemIcon__6J3uH.Sidebar_accountStatus__sC728 {
    background-image: url(/static/media/account-status.ec74b8a9.svg);
}

.Sidebar_menuItemIcon__6J3uH.Sidebar_envioNacional__1Tgj2.Sidebar_active__38OPZ {
    background-image: url(/static/media/envio-nacional.1137e38d.svg);
}
.Sidebar_menuItemIcon__6J3uH.Sidebar_envioNacional__1Tgj2 {
    background-image: url(/static/media/envio-nacional.28d88a52.svg);
}

.Sidebar_menuItemIcon__6J3uH.Sidebar_entregaDomicilio__2EnRk.Sidebar_active__38OPZ {
    background-image: url(/static/media/entrega-domicilio.1d3f45a9.svg);
}
.Sidebar_menuItemIcon__6J3uH.Sidebar_entregaDomicilio__2EnRk {
    background-image: url(/static/media/entrega-domicilio.930f9cbe.svg);
}

.Sidebar_menuItemIcon__6J3uH.Sidebar_cupones__3JReW.Sidebar_active__38OPZ {
    background-image: url(/static/media/cupones.1dda624c.svg);
}
.Sidebar_menuItemIcon__6J3uH.Sidebar_cupones__3JReW {
    background-image: url(/static/media/cupones.3f5d0c5f.svg);
}

.Sidebar_menuItemIcon__6J3uH.Sidebar_tienda__35faE.Sidebar_active__38OPZ {
    background-image: url(/static/media/tienda.777f163d.svg);
}
.Sidebar_menuItemIcon__6J3uH.Sidebar_tienda__35faE {
    background-image: url(/static/media/tienda.0f617f23.svg);
}
.Sidebar_menuItemIcon__6J3uH.Sidebar_sugerencias__1vEzT.Sidebar_active__38OPZ {
    background-image: url(/static/media/sugerencias.65fa25c1.svg);
}
.Sidebar_menuItemIcon__6J3uH.Sidebar_sugerencias__1vEzT {
    background-image: url(/static/media/sugerencias.925d1579.svg);
    position: relative;
}
.Sidebar_menuItemIcon__6J3uH.Sidebar_premiumCard__1pbMy {
    background-image: url(/static/media/premiumCard.5ffec1a9.svg);
}
.Sidebar_menuItemIcon__6J3uH.Sidebar_premiumCard__1pbMy.Sidebar_active__38OPZ {
    background-image: url(/static/media/premiumCard.38d3c904.svg);
}

.Sidebar_menuItemIcon__6J3uH.Sidebar_sugerenciasNotif__1WcId::after {
    content: "";
    position: absolute;
    top: -1px;
    right: -1px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #db586c;
    z-index: 1000;
    animation-name: Sidebar_sugerenciasNotiff__YJab3;
    transform-origin: center bottom;
    animation-duration: 1.2s;
    animation-fill-mode: both;
    animation-iteration-count: 5;
}

@keyframes Sidebar_sugerenciasNotiff__YJab3 {
    0% {
        opacity: 1;
        transform: scale3d(0.3, 0.3, 0.3);
    }

    20% {
        transform: scale3d(1.1, 1.1, 1.1);
    }

    40% {
        transform: scale3d(0.9, 0.9, 0.9);
    }

    60% {
        opacity: 1;
        transform: scale3d(1.03, 1.03, 1.03);
    }

    80% {
        transform: scale3d(0.97, 0.97, 0.97);
    }

    100% {
        opacity: 1;
        transform: scale3d(1, 1, 1);
    }

    0% {
        transform: scale3d(1, 1, 1);
    }

    10%,
    20% {
        transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    }

    30%,
    50%,
    70%,
    90% {
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    }

    40%,
    60%,
    80% {
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    }

    100% {
        transform: scale3d(1, 1, 1);
    }
    0%,
    20%,
    40%,
    60%,
    80%,
    100% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
}

.Sidebar_menuItemIcon__6J3uH.Sidebar_logout__2t52a {
    background-image: url(/static/media/logout.9088fd65.svg);
}

@media (max-width: 431px) {
    .Sidebar_menuItem__3k5es {
        margin: 8px 0;
    }
    .Sidebar_menuItemIcon__6J3uH {
        display: none;
    }
    .Sidebar_container__aewBP {
        width: max-content;
        position: absolute;
        z-index: 9999;
        min-height: calc(100vh - 50px);
        left: 0;
        transition: all 0.4s;
    }
    .Sidebar_colapsado__u8fMJ {
        left: -420px;
    }
    .Sidebar_expandido__2c0i_ .Sidebar_toolMenu__juOrE {
        /* margin-top: 10px; */
    }
}

@media screen and (min-height: 725px) {
    .Sidebar_container__aewBP {
        touch-action: none;
    }
}

footer {
    background: linear-gradient(87.99deg, #006df2 3.65%, #01affa 121.61%);
    padding: 59px 64px 30px 64px;
    position: relative;
}

footer.footer_premium {
    background: #0d2675;
}

.footer-wrapper {
    max-width: 1300px;
    /* border: 1px solid #d4d4d4; */
    margin: 0 auto;
    position: relative;
    color: #ffffffb6;
}

.footer-arrow {
    position: absolute;
    right: 80px;
    /* top: 60px; */
    z-index: 1000;
}

.footerNav {
    padding: 10px;
    margin-bottom: 32px;

    width: 480px;
    /* height: 50px; */

    background: #ffffff25;
    border-radius: 8px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footerNav a {
    color: #fff;
    padding: 9px 20px;
    border-radius: 9px;
    border: 2px solid transparent;
}

.footerNav a:hover {
    border: 2px solid #fff;
    transition: all 0.2s ease-in-out;
    color: #f1f1f1;
}

.footerNav a:active {
    border: 2px solid #fff;
    transition: all 0.2s ease-in-out;
    color: #f1f1f1;
}

.footerContact-mobile {
    display: none;
}

.footerContact {
    padding: 35px 10px 15px 10px;

    width: 480px;

    background: #ffffff25;
    border-radius: 8px;
    margin-bottom: 54px;
}

.footerContact h2 {
    font-weight: 400;
    font-size: 25px;
    line-height: 29px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 31px;
}

.footerContact .btn-footerContact {
    border: 2px solid transparent;
    padding: 9px 10px;
    /* margin: 10px; */
    background: #ffffff25;
    color: #fff;
    margin-left: 10px;
    border-radius: 10px;
    cursor: pointer;
}

.footerContact .btn-footerContact:hover {
    border: 2px solid #fff;
    transition: all 0.2s ease-in-out;
}

.btn-footerContact_container {
    display: flex;
    justify-content: center;
    align-content: center;
    margin-bottom: 10px;
}

.footerContact .btn-footerContact span {
    font-weight: 300;
    margin-left: 10px;
}

.footerDivider {
    background: #f9f9f9;
    opacity: 0.25;
    height: 2px;
}

.copyright {
    margin: 20px 0;
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-around;
    align-items: center;
    align-content: center;
}

.copyright p {
    font-weight: 400;
    font-size: 15px;
    line-height: 15px;
    text-align: center;
    color: #ffffff80;
}

.svg-pattern {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0 !important;
    /* border: 1px solid red; */
    width: 575px;
}
.svg-pattern img {
    width: 100%;
    opacity: 0.2;
}

.footer-gridContainer {
    display: grid;
    grid-template-columns: 0.7fr 1fr;
    grid-template-rows: 1fr;
}

.footer-sugerencias {
    padding: 22px;
    width: 380px;
    background: #ffffff25;
    border-radius: 8px;
}

.footer-sugerencias p {
    margin-top: 18px;
    line-height: 30px;
    color: #fff;
}

.footer-sugerencias .btnsugerencia {
    display: block;
    width: max-content;
    margin-top: 22px;

    color: #d4d4d4;
    background: #f7f9fb;
    padding: 10px 25px;
    border-radius: 8px;
    border: 2px solid transparent;
    transition: all 0.3s ease-in-out;
}

.footer-sugerencias .btnsugerencia:hover {
    color: #4d4d4d;
    background: #f7f9fb;
    border: 2px solid #d4d4d4;
    transition: all 0.3s ease-in-out;
}

.footer-facebook-icon,
.footer-instagram-icon {
    margin-left: 16px;
    margin-left: 16px;
}

@media only screen and (min-width: 1580px) {
    .footer-nav-contact {
        justify-self: end;
    }
}

@media only screen and (max-width: 1064px) {
    .footer-gridContainer {
        display: flex;
        flex-direction: column-reverse;
    }

    .footer-nav-contact {
        grid-row: 1;
    }

    .footer-sugerencias_container,
    .footer-nav-contact {
        justify-self: center;
    }

    .footerContact {
        display: none;
    }

    .footerContact-mobile {
        display: block;
        margin-top: 35px;
        margin-bottom: 35px;
        width: 100%;
        height: max-content;
    }

    .btn-footerContact-mobile {
        padding: 15px 25px;
        margin: 0 8px 0 0;
        width: max-content;
        background: #ffffff25;
        border-radius: 8px;
        color: #ffffff;
        font-weight: 300;
        cursor: pointer;
        outline: none;
        border: none;
    }

    .btn-footerContact-mobile:hover {
        color: #ffffff;
    }

    .btn-footerContact-mobile svg {
        margin: 0 8px 0 0;
        width: 14px;
    }

    .btn-footerContact-mobile .footer-whatsapp-icon {
        width: 18px;
    }

    .svg-pattern {
        position: absolute;
        top: 120px;
        left: 30px;
    }

    .svg-pattern img {
        opacity: 0.1;
    }
}

@media only screen and (max-width: 800px) {
    footer {
        padding: 30px 20px 40px 20px;
        font-family: Roboto;
    }

    .footer-sugerencias,
    .footerContact-mobile {
        width: 80%;
    }

    .footer-sugerencias .btnsugerencia {
        display: block;
        width: 100%;
        margin-top: 22px;
        font-size: 15px;
        text-align: center;
        color: #4d4d4d;
        padding: 10px 5px;
    }

    .footerContact-mobile {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 15px;
        gap: 15px;
    }

    .btn-footerContact-mobile {
        padding: 15px 0px;
        width: 100%;
        margin: 0;
        display: flex;
        justify-content: center;
    }

    .footer-arrow {
        /* right:20px;
     top: 30%; */

        bottom: 30%;
        right: 21px;
    }

    .footerNav {
        width: 100%;
    }
    .footerNav a {
        padding: 5px;
    }

    .footer-socialnetworks {
        position: absolute;
        /* bottom: 23%;
        right:0; */
        right: 0;
        top: 30%;

        display: flex;
        flex-direction: column;
    }

    .footer-facebook-icon,
    .footer-instagram-icon {
        padding: 0px;
        margin: 8px 0;
        width: 55px;
        height: 55px;
        background: #ffffff25;
        border-radius: 8px;

        display: flex;
        justify-content: center !important;
        align-content: center;
        align-items: center;
    }

    .footer-facebook-icon svg,
    .footer-instagram-icon svg {
        transform: scale(1.3);
        /* margin: auto !important; */
    }
}

@media only screen and (max-width: 630px) {
    .copyright > p {
        font-size: 13px;
    }
}

.bottomSheet_overlay__2gocW {
    position: fixed !important;
    opacity: 0;
    background: #000;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    cursor: pointer;
    touch-action: none !important;
    transition: all 0.4s ease-in-out;
}

.bottomSheet_overlay__2gocW.bottomSheet_animate__2uNx5 {
    opacity: 0.3;
}

.bottomSheet_modal__3_40B,
.bottomSheet_modal2__RznZw,
.bottomSheet_aniversarioStyle__1Nf_p {
    position: fixed;
    padding: 0px;
    width: calc(100% + 2px);
    max-height: 95%;
    height: -moz-fit-content;
    height: fit-content;
    overflow-y: auto;
    border-radius: 20px 20px 0 0;
    box-shadow: 0 0 10px rgb(0 0 0 / 25%);
    z-index: 1002;
    bottom: 0;
    left: calc(50% + 1px);
    top: 150%;
    transform: translate(-50%, 150%);
    transition: 0.4s ease-in-out;
}

.bottomSheet_modal__3_40B,
.bottomSheet_modal2__RznZw {
    background: #fff;
}
.bottomSheet_aniversarioStyle__1Nf_p {
    background: #006ef2;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
}

.bottomSheet_modalContent__JNDdX {
    padding: 20px;
}
.bottomSheet_modalContentNewPadding__1ClkE {
    padding: 24px;
}

.bottomSheet_modalContentWallet__rw96Y {
    padding: 16px;
}
.bottomSheet_modalContent2__2cRnu {
    padding: 20px 16px;
}

.bottomSheet_noTouch__UdRj7 {
    touch-action: none;
}

.bottomSheet_modal__3_40B.bottomSheet_animate__2uNx5,
.bottomSheet_modal2__RznZw.bottomSheet_animate__2uNx5 {
    transform: translate(calc(-50% - 1px), -100%);
    top: 100%;
}

.bottomSheet_modalHeader__3ewsU {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.bottomSheet_titleContainer__3mk0K {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}
.bottomSheet_modalTitle__3hwkT,
.bottomSheet_whiteTitle__1tiea {
    font-weight: 500;
    font-size: 25px;
    line-height: 29px;
    letter-spacing: 0.02em;
}
.bottomSheet_modalTitle__3hwkT {
    color: #4d4d4d;
}
.bottomSheet_whiteTitle__1tiea {
    color: #fff;
}

.bottomSheet_close__2Hn8p,
.bottomSheet_closeWhite__1piX5 {
    background-size: 100% 100%;
    width: 19px;
    height: 18px;
    right: 20px;
    top: 27px;
    z-index: 1002;
    cursor: pointer;
}

.bottomSheet_closeNew__3dJpu {
    width: 16px;
    background-size: 100% 100%;
    height: 16px;
    margin: 11px 8px 0 0;
}

.bottomSheet_closeBlack__19FlR {
    min-width: 32px;
    min-height: 32px;
    background-size: 100% 100%;
    position: relative;
    right: 0px;
    top: 0px;
    align-self: flex-start;
}
.bottomSheet_closeNew__3dJpu {
    background-image: url(/static/media/closeModalNew.7f1e4ef6.svg);
}
.bottomSheet_close__2Hn8p {
    background-image: url(/static/media/closeModal.47885b63.svg);
}

.bottomSheet_closeWhite__1piX5 {
    background-image: url(/static/media/closeModal.47885b63.svg);
}
.bottomSheet_closeBlack__19FlR {
    background-image: url(/static/media/closeBlack.ed14c2e2.svg);
}

.bottomSheet_modalHeader__3ewsU img {
    margin-left: auto;
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.bottomSheet_modalBody__2KNnh {
}

@media (min-width: 570px) {
    .bottomSheet_modal__3_40B,
    .bottomSheet_modal2__RznZw {
        width: calc(100% - 16px * 2);
        opacity: 0;
        transform: translate(-50%, -50%);
    }
    .bottomSheet_modal2__RznZw {
        max-width: 439px;
    }

    .bottomSheet_modal__3_40B.bottomSheet_animate__2uNx5,
    .bottomSheet_modal2__RznZw.bottomSheet_animate__2uNx5 {
        top: 50%;
        opacity: 1;
        transform: translate(-50%, -50%);
        transition: 0.2s ease-in-out;
    }
    .bottomSheet_modal__3_40B {
        border-radius: 20px;
        max-width: 425px;
    }
    .bottomSheet_modal2__RznZw {
        border-radius: 20px;
        max-width: 500px;
    }

    .bottomSheet_overlay__2gocW {
        background: #000;
    }

    .bottomSheet_modalContent__JNDdX {
        padding: 40px;
    }

    .bottomSheet_modalContentNewPadding__1ClkE {
        padding: 2;
    }
    .bottomSheet_modalContent2__2cRnu {
        padding: 20px;
    }

    .bottomSheet_closeWhite__1piX5 {
        background-image: url(/static/media/closeSvgWhite.bc2ff78f.svg);
    }
    .bottomSheet_closeNew__3dJpu {
        margin: 0 0;
    }
}

.style_container__wN2fq {
  font-family: roboto, arial, helvetica;
  margin-top: 15px;
  color: #4d4d4d;
}

.style_title__3STVQ {
  font-size: 26px;
}

.style_subTitle__15d9p {
  font-weight: normal;
  font-size: 13px;
}

.style_body__2XlTE {
  margin-top: 25px;
}

.style_body__2XlTE section {
  text-align: left;
  padding: 0 0 24px 0;
}

.style_body__2XlTE section div {
  position: relative;
}

.style_body__2XlTE section div img {
  position: absolute;
  right: 16px;
  top: 10px;
  cursor: pointer;
  /* transform: translate(-50%, -50%); */
}

.style_body__2XlTE section label {
  margin-bottom: 16px;
  font-weight: normal;
  font-size: 13px;
  line-height: 12px;
}

.style_body__2XlTE section input {
  outline: none;
  padding: 9px 17px 9px 15px;
  background-color: #f5f7fa;
  border-radius: 8px;
  border: none;
  width: 100%;
}

.style_body__2XlTE section input:focus {
  border: 1px solid #006ef2;
}

.style_btn__3Piul {
  border: none;
  background-color: var(--blueRNB);
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  line-height: 12px;
  padding: 15px;
  color: white;
  width: 100%;
}
.style_btn_desactivado__BDegZ {
  border: none;
  background-color: var(--blueRNB);
  border-radius: 5px;
  text-align: center;
  opacity: 0.3;
  font-size: 16px;
  line-height: 12px;
  padding: 15px;
  color: white;
  width: 100%;
}

.style_success_container__u5rI6 {
  border: none;
  border-radius: 5px;
  text-align: center;
  font-size: 15px;
  line-height: 18px;
  padding: 10px;
  background-color: white;
  border: 2.5px solid var(--blueRNB);
  color: var(--blueRNB);
  width: 100%;
}

.verificarCedula_title_container__2nn70 {
  margin-bottom: 15px;
  margin-top: 15px;
}
.verificarCedula_title_container__2nn70 h1 {
  font-weight: 500;
  font-size: 25px;
  margin-bottom: 8px;
}
.verificarCedula_title_container__2nn70 p {
  margin: 0;
  font-size: 13px;
  font-weight: 400;
  line-height: 150%;
}
.verificarCedula_input_imagen__2YBZt {
  padding: 23px 0 10px 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
}

.verificarCedula_img_filled__2LyEW {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23006EF2FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  background-color: #006ef226;
  color: #006ef2;
}

.verificarCedula_img_unfilled__19ZZc {
  background-color: #f5f7fa;
  color: #4d4d4d80;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23D4D4D4FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.verificarCedula_input_imagen__2YBZt img {
  margin-bottom: 10px;
}
.verificarCedula_input_imagen__2YBZt h2 {
  font-size: 13px;
  font-weight: 400;
}
.verificarCedula_input_imagen__2YBZt p {
  font-size: 10px;
  font-weight: 400;
}

.verificarCedula_form_container__qyjKN {
  margin-bottom: 25px;
}
.verificarCedula_input_container__2OnU8 {
  margin: 24px 0 0 0;
  display: block;
}
.verificarCedula_input_container__2OnU8 label {
  display: block;
  font-size: 13px;
  margin-bottom: 8px;
  color: #4d4d4d;
}
.verificarCedula_input_container__2OnU8 input.verificarCedula_custom__3rIPm {
  font-size: 13px;
  color: #999999;
  width: 100%;
  height: 40px;
  padding: 4px 16px;
  border: none;
  outline: none;
  background-color: #f5f7fa;
  border-radius: 8px;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
}
.verificarCedula_inputDate__2hRfD {
  font-size: 13px;
  color: #999999;
  width: 100%;
  height: 40px;
  padding: 4px 16px;
  border: none;
  outline: none;
  background-color: #f5f7fa;
  border-radius: 8px;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
}
.verificarCedula_inputDate__2hRfD > div {
  border: none;
}
.verificarCedula_inputDate__2hRfD input:invalid {
  background: transparent !important;
}
.verificarCedula_inputDate__2hRfD input {
  outline: none !important;
  color: #999 !important;
}

.verificarCedula_button_container__19X55 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
  gap: 40px;
}

.verificarCedula_omitir_btn__u-ywd {
  padding: 15px 0;
  font-size: 15px;
  text-align: center;
  color: #006ef2;
  background-color: white;
  border: none;
  border-radius: 5px;
  line-height: 12px;
}
.verificarCedula_guardar_btn__17of_ {
  padding: 15px 0;
  font-size: 15px;
  width: 100%;
  text-align: center;
  background-color: #006ef2;
  color: white;
  border: none;
  border-radius: 5px;
  line-height: 12px;
}

.verificarCedula_success_container__1QpcM {
  border: 2px solid #006ef2;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  background-color: white;
  color: #006ef2;
  padding: 15px 10px;
}

.react-calendar {
  border-radius: 4px !important;
}

.react-calendar__month-view__days__day--weekend abbr {
  color: var(--blueRNB) !important;
}

.react-calendar__tile--now {
  background: #80b7f9;
  color: #fff;
  border-radius: 4px;
}

.react-calendar__tile--active {
  background: var(--blueRNB) !important;
  border-radius: 3px !important;
  color: white !important;
}

.react-calendar__tile--active abbr {
  color: white !important;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6 !important;
  border-radius: 4px !important;
}
.react-calendar__tile--hasActive {
  background: var(--blueRNB) !important;
  color: #fff !important;
  border-radius: 4px !important;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  border-radius: 4px !important;
}

.react-calendar__tile--hasActive abbr {
  color: #fff !important;
}

.react-calendar button abbr {
  color: rgb(117, 117, 117);
}

.Layout_layout__3p1B4 {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
    position: relative;
}
.Layout_container__eCqlm {
    display: flex;
    flex-direction: row;
    width: 100%;
}
.Layout_main__3PQjb {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: scroll;
    height: calc(100vh - 48px);
    position: relative;
}

.Layout_content__26Fm5 {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 87px);
}

@media (min-width: 430px) {
    .Layout_sidebarExpandido__3puFt .Layout_cuponesWrapper__2BnZy {
        grid-template-columns: 1fr 1fr !important;
    }
}

@media (max-width: 431px) {
    .Layout_overlay__5HOQD {
        top: 82px;
        position: absolute;
        overflow: hidden;
        overflow-y: scroll;
        background-color: rgb(0, 0, 0);
        opacity: 0.5;
        width: 100%;
        z-index: 999;
        touch-action: none;
    }
    .Layout_layout__3p1B4.Layout_ios__30lXB {
        height: auto !important;
    }
    .Layout_ios__30lXB .Layout_main__3PQjb {
        height: auto !important;
    }
}

.ConfettiAniversario_confetti__Odfpd {
    position: absolute;
    bottom: 200px;
    transform: translate(0, 50%);
    scale: 1.1;
}
@media (min-width: 570px) {
    .ConfettiAniversario_confetti__Odfpd {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
    }
}

.upload {
  padding: 15px;
  padding-bottom: 30px;
}
h1.title {
  font-weight: 500;
  font-size: 25px;
  margin-bottom: 8px;
}
#dragndrop_zone {
  border: 3px dashed #4d4d4d43;
  /* margin: 15px; */
  padding: 4px;
  padding-top: 50px;
  padding-bottom: 20px;
  cursor: pointer;
  border-radius: 10px;
}

.ProfileUpload {
  display: block;
  border: none;
  margin: 0 auto;
}

.file-info span {
  color: var(--blueRNB) !important;
  font-size: 14px;
}

.modalUploadProfileP_Container .modal-content {
  /* border: 2px solid rgb(138, 138, 138) !important; */
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
  margin: 10px !important;
  justify-content: center !important;
}

.modalUploadProfileP_Container .modal-body {
  padding: 0 !important;
  overflow: hidden !important;
}

@media only screen and (min-width: 500px) {
  .modalUploadProfileP_Container .modal-sm {
    max-width: 375px !important;
  }
}

.btn-SelectImage {
  color: #fff;
  background-color: var(--blueRNB);
  border-radius: 10px;
  border: 2px solid transparent;
  transition: all 0.5s ease-in-out;
  padding: 13px 20px;
  display: block;
  margin: 0 auto;
}

.btn-SelectImage:hover {
  background-color: #f9f9f9;
  border: 2px solid var(--blueRNB);
  color: var(--blueRNB);
  transition: all 0.5s ease-in-out;
}

.btn-UploadPP {
  display: block;
  /* margin: 0 auto; */
  border: 1px solid transparent;
  color: #f3f3f3;
  border-radius: 4px;
  padding: 10px 10px;

  align-self: center;
  justify-self: center;
  width: 100%;
}

.btn-UploadPP-active {
  background: var(--blueRNB);
}

.btn-UploadPP-disabled {
  background-color: #006ef280;
}

.groupUploadViwers {
  margin-top: 25px;
}

.uploadedImage {
  border: 2px solid #d4d4d4;
  display: flex;
  align-items: center;
  color: #c1c1c1;
  text-align: center;
  border-radius: 4px;
}

.uploadedImage p {
  text-align: center;
  width: 100%;
}

#dragndrop_zone .file-svg {
  display: block;
  margin: 0 auto;
}

.group__imgInfoContainer {
  display: block;
  margin: 0 auto;
  /* border: 1px solid red; */
  min-height: 105px;
  max-width: 250px;
}

.group__imgRequirements {
  /* border: 1px solid red; */
}

.group__imgRequirements > .dragndrop_message {
  color: #4d4d4dd3;
  font-size: 16px;
}

.group__imgRequirements > .support_message {
  color: #4d4d4d8e;
  font-size: 13px;
}
.group__imgRequirements > .size_message {
  color: #4d4d4d73;
  font-size: 12px;
}

.uploadProgress {
  margin: 20px 0;
  padding: 10px 0;
}

.uploadProgress_top {
  display: flex;
  align-items: center;
}

.uploadProgress_label {
  display: flex;
  font-size: 13px;
  color: #9e9e9e;
  margin-right: 5px;
}
.uploadProgress_bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.uploadProgress_bar {
  width: 100%;
}
.uploadProgress_bar_bg {
  background: rgb(0 110 242 / 30%);
  height: 4px;
  width: 100%;
  border-radius: 4px;
}

.uploadProgress_bar_prg {
  background: #006ef2;
  border-radius: 4px;
  height: 4px;
  transition: width 0.7s ease-out;
}

.uploadProgress_percentage {
  color: #9e9e9e;
  font-size: 13px;
  width: 38px;
  text-align: center;
}

.uploadLoader,
.uploadLoader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.uploadLoader {
  margin: 10px auto;
  font-size: 1.5px;
  position: relative;
  text-indent: -9999em;
  border-top: 2.1em solid rgba(0, 110, 242, 0.3);
  border-right: 2.1em solid rgba(0, 110, 242, 0.3);
  border-bottom: 2.1em solid rgba(0, 110, 242, 0.3);
  border-left: 2.1em solid rgb(0 110 242);
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
}

@keyframes blink {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

.uploading span {
  animation-name: blink;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

.uploading span:nth-child(2) {
  animation-delay: 0.2s;
}

.uploading span:nth-child(3) {
  animation-delay: 0.4s;
}
@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.copyclip {
    width: 36px;
    height: 25px;
    background-image: url(/static/media/copyclip.c02f0cbd.svg);
    background-size: 12px 18px;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
}


.new {
    width: 24px;
    height: 24px;
    background-image: url(/static/media/copyclip-new.b03821c0.svg);
    background-size: contain;
}

.new:hover,
.new_active {
    cursor: pointer;
    background-image: url(/static/media/copy_icon_hover.08f635c7.svg);
}

/* Base styles for the element that has a tooltip */
.copyclip_tooltip {
    position: relative;
    cursor: pointer;
}

/* Base styles for the entire tooltip */
.copyclip_tooltip:before,
.copyclip_tooltip:after {
    position: absolute;
    visibility: hidden;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
        transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
    transform: translate3d(0, 0, 0);
    pointer-events: none;
}

.copyclip_tooltip.tooltipAc:before,
.copyclip_tooltip.tooltipAc:after,
.copyclip_tooltip.tooltipAc:before,
.copyclip_tooltip.tooltipAc:after {
    visibility: visible;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
}

/* Base styles for the tooltip's directional arrow */
.copyclip_tooltip:before {
    z-index: 1001;
    border: 6px solid transparent;
    background: transparent;
    content: "";
}


/* Base styles for the tooltip's content area */
.copyclip_tooltip:after {
    z-index: 1000;
    padding: 4px 8px;
    background-color: #006ef4;
    color: #fff;
    content: attr(data-tooltip);
    text-align: center;
    font-size: 13px;
    line-height: 100%;
    border-radius: 4px;
}

.copyclip_tooltip:before,
.copyclip_tooltip:after {
    bottom: 50%;
    left: 100%;
}

.copyclip_tooltip:before {
    margin-bottom: -6px;
    margin-left: -11px;
    border-top-color: transparent;
    border-right-color: #006ef4;
}

.copyclip_tooltip.tooltipAc:before,
.copyclip_tooltip.tooltipAc:after {
    transform: translateX(12px);
}

.copyclip_tooltipright:before {
    top: 3px;
}

.copyclip_tooltip:after {
    margin-left: 0;
    margin-bottom: -14px;
}

.copyclip_tooltipB:after {
    margin-left: 0;
    margin-bottom: -16px;
}


.copyclip_tooltip.left:after {
    transform: translate(-60px, -35px);
}

.copyclip_tooltip.left:before {
    transform: translate(13px, -7px);
    rotate: 270deg;
}
.infoach_overlay {
  background: #000;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 99999;
  opacity: 0.6;
}

.infoach_modal {
  position: fixed;
  z-index: 99999;
  left: 50%;
  top: 50%;
  background: #fff;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  padding: 20px;
}

.infoach_header {
  display: flex;
  margin-bottom: 15px;
}

.infoach_logo {
  width: 22px;
  height: 25px;
  background-image: url(/static/media/logo_ach.62d69249.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.infoach_title {
  font-size: 17px;
  font-weight: 500;
  color: #4d4d4d;
  margin-left: 10px;
}

.infoach_close {
  width: 24px;
  height: 24px;
  background-image: url(/static/media/close_modal.487913f7.svg);
  background-size: 12px 12px;
  background-position: center;
  background-repeat: no-repeat;
  margin-left: auto;
  cursor: pointer;
}

.infoach_body {
  border-top: 1px solid #e1e3ea;
  border-bottom: 1px solid #e1e3ea;
  padding: 15px 10px;
}

.infoach_dato {
  color: #4d4d4d;
  font-size: 15px;
  line-height: 20px;
  padding-bottom: 15px;
}

.infoach_dato:last-of-type {
  padding-bottom: 0;
}

.infoach_dato_label {
  opacity: 0.5;
}

.infoach_dato_value {
  display: flex;
  margin-right: 12px;
}

.infoach_disclaimer {
  font-size: 13px;
  color: #a6a6a6;
  margin-top: 15px;
  width: 288px;
}

.infoach_b {
  color: #006ef2;
}

div.error_item_Modal_overlay {
  position: fixed;
  display: block;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  z-index: 999998;
  background: rgb(0, 0, 0) none repeat scroll 0% 0%;
  opacity: 0.5;
}

div.error_item_Modal {
  position: fixed;
  display: block;
  top: 50%;
  left: 50%;
  width: 95%;
  max-width: 400px;
  background: rgb(255, 255, 255) none repeat scroll 0% 0%;
  opacity: 1;
  transform: translate(-50%, -50%);
  z-index: 999999;
  padding: 20px;
  color: #4d4d4d;
  border-radius: 10px;
}

div.error_item_Modal_header {
  padding: 20px 0;
}
.error_item_Modal_top {
  display: flex;
}

.error_item_Modal_top.error_border {
  border-bottom: 1px solid #e1e3ea;
}

.error_item_Modal_close {
  width: 24px;
  height: 24px;
  margin-top: -12px;
  background-image: url(/static/media/close_modal.487913f7.svg);
  background-size: 12px 12px;
  background-position: center;
  background-repeat: no-repeat;
  margin-left: auto;
  cursor: pointer;
}
.error_item_Modal_warning {
  width: 24px;
  height: 24px;
  margin-top: 4px;
  margin-right: 10px;
  background-image: url(/static/media/warning_logo.0d7ddc0e.svg);
  background-size: 24px;
  background-position: center;
  background-repeat: no-repeat;
}
div.error_item_Modal_header h1 {
  font-weight: 500;
  font-size: 25px;
}

.error_item_Modal_header h1.error_margin {
  margin-bottom: 20px;
}

div.error_item_Modal_header p {
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: #4d4d4d80;
  margin: 0;
}
div.error_item_Modal_body p {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.02;
  color: #999999;
  margin: 0;
}

button.boton_ACH {
    width: 100%;
    background-color: #48c296;
    height: auto;
    min-height: 48px;
    padding: 5px 10px;
    border-radius: 5px;
    border: medium none;
    outline: currentcolor none medium;
    color: white;
    font-weight: 500;
    font-size: 16px;
    line-height: 14px;
}
button.boton_ACH span img {
    margin-right: 15px;
}

div.boton_ACH_Modal_overlay {
    position: fixed;
    display: block;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100vw;
    z-index: 9998;
    background: rgb(0, 0, 0) none repeat scroll 0% 0%;
    opacity: 0.5;
}

div.boton_ACH_Modal {
    position: fixed;
    display: block;
    top: 50%;
    left: 50%;
    width: 95%;
    max-width: 498px;
    background: rgb(255, 255, 255) none repeat scroll 0% 0%;
    opacity: 1;
    transform: translate(-50%, -50%);
    z-index: 9999;
    padding: 20px;
    color: #4d4d4d;
    border-radius: 10px;
}

div.boton_ACH_Modal_header {
    padding: 20px 15px;
}

.boton_ACH_Modal_top {
    display: flex;
}

.boton_ACH_Modal_close {
    width: 24px;
    height: 24px;
    margin-top: -12px;
    background-image: url(/static/media/close_modal.487913f7.svg);
    background-size: 12px 12px;
    background-position: center;
    background-repeat: no-repeat;
    margin-left: auto;
    cursor: pointer;
}

div.boton_ACH_Modal_header h1 {
    font-weight: 500;
    font-size: 25px;
    margin-bottom: 20px;
}

div.boton_ACH_Modal_header p.boton_ACH_dsc {
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    color: #4d4d4d80;
    margin: 0;
}

section.boton_ACH_Modal_body_saldo {
    padding: 10px 15px;
    width: calc(100% - 30px);
    background-color: #f6f6f6;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    color: #4d4d4dcc;
    margin-bottom: 20px;
    margin-left: 15px;
    margin-right: 15px;
}

button.boton_ACH_Modal_body_infoBanco {
    display: block;
    left: 50%;
    padding: 10px;
    background-color: var(--blueRNB);
    border: none;
    color: white;
    border-radius: 5px;
    width: calc(100% - 30px);
    max-width: 431px;
    margin: auto;
}

button.boton_ACH_Modal_body_infoBanco svg {
    margin-right: 5px;
    margin-top: -3px;
}

.boton_ACH_transfe {
    font-size: 11px;
    font-weight: normal;
    color: #4d4d4d80;
    margin-top: 10px;
    margin-bottom: 0;
}

/* section.boton_ACH_Modal_body_saldo span{
    padding:  10px;
    width: 100%; 
    background-color: #f6f6f6;
    border-radius: 10px
} */

@media screen and (min-width: 650px) {
    div.boton_ACH_Modal_header p.boton_ACH_dsc {
        font-size: 15px;
    }
    section.boton_ACH_Modal_body_saldo {
        font-size: 15px;
    }
}

.botonSubmit_ripple__2KuXR {
    border-radius: 8px 8px 8px 8px;
}

.botonSubmit_fullWidth__3e82w {
    width: 100%;
}

button.botonSubmit_btn_submit__TaGrG {
    height: 40px;
    min-width: 89px;
    /* max-width: none; */
    width: 100%;
    background-color: #006ef2;
    font-weight: 500;
    border: none;
    color: #f9f9f9;
    padding: 24px;
    text-align: center;
    font-size: 16px;
    border-radius:  8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* .arrowLeft {
    margin-right: 8px;
} */

/* .toRightArrow {
    padding-left: 15px !important;
} */

/* .toLeftArrow {
    padding-right: 15px !important;
} */

button.botonSubmit_whiteButton__11m0X {
    background-color: transparent !important;
    color: black;
    border: 1px solid rgba(212, 212, 212, 0.5);
}

button.botonSubmit_btn_submit__TaGrG:hover {
    background-color: #035ece;
    transition: background-color 0.8s;
    cursor: pointer;
}

button.botonSubmit_btn_submit__TaGrG:disabled {
    opacity: 0.56;
    pointer-events: none;
}

button.botonSubmit_btn_submit__TaGrG.botonSubmit_dark__29ahS:disabled {
    opacity: 0.8;
}

button span {
    text-align: center;
}

.botonSubmit_contenedor_btnEnviar__liN_A {
    width: 100%;
}

.botonSubmit_subcontenedor_btnEnviar__YKHwY {
    width: max-content;
    margin: auto;
}

.botonSubmit_animacion_botonCargando__2XCAa {
    transition: all 0.3s ease;
    width: 97.067px;
    height: 19.5px;
    margin: 0 auto;
    position: relative;
    top: -8px;
    /* display: none; */
}

.botonSubmit_animacion_botonCargando_small__WY0BP {
    transition: all 0.3s ease;

    height: 19.5px;
    margin: auto;
    position: relative;
    top: -9px;
    transform: scale(0.8);

    /* display: none; */
}

.botonSubmit_loader__1zSGO:before,
.botonSubmit_loader__1zSGO:after,
.botonSubmit_loader__1zSGO {
    border-radius: 50%;
    width: 2.1em;
    height: 2.1em;
    animation-fill-mode: both;
    animation: botonSubmit_load7__20njl 1.8s infinite ease-in-out;
}

.botonSubmit_loader__1zSGO.botonSubmit_dark__29ahS:before,
.botonSubmit_loader__1zSGO.botonSubmit_dark__29ahS:after,
.botonSubmit_loader__1zSGO.botonSubmit_dark__29ahS {
    animation: botonSubmit_load7Dark__qoB3j 1.8s infinite ease-in-out;
}

.botonSubmit_loader__1zSGO {
    margin: 0 auto;
    font-size: 5px;
    position: relative;
    text-indent: -9999em;
    animation-delay: 0.16s;
}

.botonSubmit_loader__1zSGO:before {
    left: -3.5em;
}

.botonSubmit_loader__1zSGO:after {
    left: 3.5em;
    animation-delay: 0.32s;
}

.botonSubmit_loader__1zSGO:before,
.botonSubmit_loader__1zSGO:after {
    content: "";
    position: absolute;
    top: 0px;
}

@keyframes botonSubmit_load7__20njl {
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em #ffffff;
    }

    40% {
        box-shadow: 0 2.5em 0 0 #ffffff;
    }
}

@keyframes botonSubmit_load7Dark__qoB3j {
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em #a7a7a7;
    }

    40% {
        box-shadow: 0 2.5em 0 0 #a7a7a7;
    }
}

.NoCards_image__31_lu {
  display: block;
  margin: 20px auto 0;
}

.NoCards_footer__38kyG {
  padding: 12px 24px 0;
}

.NoCards_des__2UOBh {
  color: #4d4d4d;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  padding-bottom: 16px;
}

.NoCards_title__2397R {
  font-weight: 500;
  font-size: 25px;
  margin-bottom: 24px;
}

.NoCards_btn__1SZqV {
  color: #006ef2;
  background: #f5f7fa;
  border-radius: 5px;
  border: 1px solid rgba(212, 212, 212, 0.5);
  font-size: 15px;
  font-weight: 500;
  padding: 10px 0;
  width: 100%;
  text-align: center;
  cursor: pointer;
  background-image: url(/static/media/card-add.beb98992.svg);
  background-repeat: no-repeat;
  background-position: 5% 50%;
}

.ConfirmCard_formGroup__3pqSb {
  margin-bottom: 24px;
}

.ConfirmCard_nombreFlex__3WuGP {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
  gap: 8px;
}
.ConfirmCard_formGroup__3pqSb label {
  opacity: 0.5;
  font-weight: 400;
  font-size: 16px;
  line-height: 12px;
  letter-spacing: 0.02em;
}

.ConfirmCard_formcc_input__3Vwxq {
  opacity: 0.5;
  height: 40px;
  line-height: 1;
  width: 100%;
  background: #f5f7fa;
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  padding: 16px 9.5px;
  color: #4d4d4d;
}
input.ConfirmCard_confirmCVV__2yW4Q {
  outline: rgb(0, 0, 0) none 0px;
  outline-style: none;
  outline-offset: 0px;
  width: 100%;
  font-weight: 300;
  border: 1px solid transparent;
  border-style: solid solid solid solid;
  padding: 9.5px 16px;
  font-size: 16px;
  border-radius: 8px;
  background-color: #f5f7fa;
  color: #4d4d4d;
  -webkit-font-smoothing: antialiased;
  text-rendering: geometricPrecision;
}
.ConfirmCard_cvvContainer__3Q_1S label {
  opacity: 1;
}

.ConfirmCard_botonDisabled__57zCe {
  background-color: #006df285 !important;
}

.ConfirmCard_footer__eymUP {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.ConfirmCard_errorContainer__3bnIV {
  width: 100%;
  margin-top: 20px;
}

@media (min-width: 500px) {
  .ConfirmCard_footer__eymUP {
    flex-direction: row;
  }
  .ConfirmCard_buttonSubmitContainer__oiSid {
    display: flex;
    justify-content: flex-end;
    width: auto;
  }
  .ConfirmCard_buttonSubmitContainer__oiSid section.ConfirmCard_boton__3ugoP {
    width: 108px;
  }
}

.AddedCard_content__2yW7F {
  min-height: 420px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  animation-duration: 0.5s;
  animation-name: AddedCard_animate-fade__3HCJq;
  animation-delay: 0.1s;
  animation-fill-mode: backwards;
}
.AddedCard_animation__14O27 {
  display: block;
  margin: 20px auto 0;
}

.AddedCard_footer__2NZJg {
  padding: 12px 24px 0;
}

.AddedCard_title__197tD {
  font-weight: 500;
  font-size: 25px;
  margin-bottom: 16px;
  text-align: center;
}

.AddedCard_title__197tD p {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #4d4d4d;
}

@keyframes AddedCard_animate-fade__3HCJq {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.AddCard_step1Container__1np_m{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.AddCard_titleContainer__2d-yO{
  display: flex;
  align-items: center;
  margin-right: auto;
}

.AddCard_step1TextContainer__2YSfD{
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.AddCard_step1TextContainer__2YSfD p{
  margin-bottom: 24px;
  font-size: 16px;
  color: #52606C;
}

.AddCard_nextStepButtonContainer__1Mu4S{
  display: flex;
  justify-content: end;
}

.AddCard_step1Icon__2GxNn{
  margin-right: 20px;
  width: 27.1px;
  height: 24.42px;
  background-image: url(/static/media/warning.b2ec543e.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.AddCard_titleContainer__2d-yO span{
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;
  color: #282828;
}

.AddCard_addCardContent__3c0_C {
  min-height: 420px;
}
.AddCard_header__BG8xU {
  margin-bottom: 25px;
}
.AddCard_header__BG8xU h1 {
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 25px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #4d4d4d;
}
.AddCard_header__BG8xU p {
  margin-bottom: 0;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: rgba(77, 77, 77, 0.8);
}

.AddCard_formGroup__34d1k {
  margin-bottom: 24px;
}

.AddCard_nombreFlex__2UB_U {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
  gap: 8px;
}

.AddCard_inputCustom__2skFM{
  background-color:#F5F7FA !important;
  border: none !important;
  border-radius: 8px !important;
}

.AddCard_formcc_input__1ojfB {
  height: 40px;
  background-color: #F5F7FA;
  /* background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee); */
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 8px;
  display: inline-block;
  line-height: 1;
  width: 100%;
  animation: AddCard_animation-bzdot9__On0rT 1.2s ease-in-out infinite;
}

.AddCard_botonDisabled__2ObXu {
  background-color: #006df285 !important;
}

.AddCard_footer__yN0So {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.AddCard_cryptContainer__3KLQ_ {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
}
.AddCard_cryptIcon__xEQZ- {
  display: flex;
  width: 17px;
  height: 17px;
  background-image: url(/static/media/cryptIcon.be76ff84.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.AddCard_cryptText__A8ZXU {
  font-weight: 400;
  font-size: 13px;
  line-height: 16.25px;
  color: #4d4d4d;
  margin-left: 8px;
}
.AddCard_errorContainer__2YN_Y {
  width: 100%;
}
.AddCard_buttonSubmitContainer__NVRO5 {
  width: 100%;
}

@media (min-width: 500px) {
  .AddCard_footer__yN0So {
    flex-direction: row;
  }
  .AddCard_cryptContainer__3KLQ_ {
    margin-bottom: 0px;
  }
  .AddCard_buttonSubmitContainer__NVRO5 {
    display: flex;
    justify-content: flex-end;
    width: auto;
  }
  .AddCard_buttonSubmitContainer__NVRO5 section.AddCard_boton__uH0fr {
    width: 108px;
  }
}

.PaymentCreditCard_header__3YF0u {
    margin-bottom: 25px;
}

.PaymentCreditCard_header__3YF0u h1 {
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 25px;
    line-height: 100%;
    letter-spacing: 0.02em;
    color: #4d4d4d;
}

.PaymentCreditCard_body__2TzEy {
    max-height: 253px;
    overflow-y: auto;
}

.PaymentCreditCard_card__2xUaV {
    border: 1px solid rgba(212, 212, 212, 0.5);
    box-sizing: border-box;
    border-radius: 10px;
    padding: 16px;
    margin: 20px 0 0 0;
    cursor: pointer;
}

.PaymentCreditCard_card__2xUaV:first-of-type {
    margin-top: 0;
}

.PaymentCreditCard_card__2xUaV.PaymentCreditCard_active__zU006 {
    border: 1px solid rgba(0, 110, 242, 0.5);
}

.PaymentCreditCard_cardContainer__1vx9W {
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
}

.PaymentCreditCard_logo__3I8l- {
    width: 56px;
    height: 48px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.PaymentCreditCard_type_visa__3F-P8 {
    background-image: url(/static/media/visa.a5fccb42.svg);
}

.PaymentCreditCard_type_mastercard__SUpG6 {
    background-image: url(/static/media/mastercard.8114ddbd.svg);
}

.PaymentCreditCard_cardData__1BXBN {
    display: flex;
    flex-direction: column;
    justify-content: left;
    width: 50%;
    align-self: center;
    padding-left: 8px;
}

.PaymentCreditCard_cardAlias__3TFWF {
    font-size: 16px;
    line-height: 24px;
    color: #4d4d4d;
    font-weight: 500;
    width: 100%;
    margin: 0;
}

.PaymentCreditCard_cardNumberSM__22VMz {
    font-size: 14px;
    line-height: 21px;
    color: #4d4d4d;
    font-weight: 400;
    width: 100%;
    margin: 0;
}

.PaymentCreditCard_cardNumber__2m_55 {
    font-size: 16px;
    line-height: 24px;
    color: #4d4d4d;
    font-weight: 500;
    width: 100%;
    margin: 0;
}

.PaymentCreditCard_cardExpires__Q5HDP {
    color: rgba(77, 77, 77, 0.5);
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    margin: 0;
}

.PaymentCreditCard_expireSoon__qF-9O {
    color: #dca700;
}

.PaymentCreditCard_expired__yK6rw {
    color: #e00021;
}

.PaymentCreditCard_container__PXApt {
    height: 370px;
    display: flex;
    flex-direction: column;
}

.PaymentCreditCard_radioIcon__1ipKh {
    width: 24px;
    height: 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(/static/media/radioIconOff.71473d31.svg);
    margin-right: 4px;
    cursor: pointer;
}

.PaymentCreditCard_radioIcon__1ipKh.PaymentCreditCard_active__zU006 {
    background-image: url(/static/media/radioIconOn.4daf1d1a.svg);
}

.PaymentCreditCard_favoriteChip__37rWu {
    margin-left: auto;
    padding: 4px 8px;
    height: 23px;

    background: rgba(0, 110, 242, 0.1);
    border: 1px solid #006ef2;
    box-sizing: border-box;
    border-radius: 50px;

    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: #006ef2;
}

.PaymentCreditCard_errorContainer__2w-Nk {
    width: 100%;
    margin-top: 20px;
}

.PaymentCreditCard_footer__18YNh {
    padding: 9px 0 16px 0;
    margin-top: auto;
}

.PaymentCreditCard_footerButtons__1shHc {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
}

.PaymentCreditCard_button__2Zyi3 {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #006ef2;
    background: #f5f7fa;
    border-radius: 5px;
    border: 1px solid rgba(212, 212, 212, 0.5);
    font-size: 15px;
    font-weight: 500;
    padding: 10px 0;
    width: 152px !important;
    text-align: center;
    cursor: pointer;
}

.PaymentCreditCard_addIcon__3lJdq {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(/static/media/card-add.beb98992.svg);
    width: 24px;
    height: 24px;
    margin-right: 8px;
}

.PaymentCreditCard_payButton__2l0UI span {
    /* background: #006ef2; */
    /* color: #ffffff;
  font-weight: 500;
  font-size: 15px;
  line-height: 12px; */
}

@media (min-width: 500px) {
}

button.boton_CC {
    width: 100%;
    background-color: #f4f4f4;
    border: 1px solid rgba(212, 212, 212, 0.5);
    height: auto;
    min-height: 48px;
    padding: 5px 10px;
    border-radius: 5px;
    outline: currentcolor none medium;
    color: #4d4d4d !important;
    letter-spacing: 0.02em;
    font-size: 15px;
}
button.boton_CC span {
    color: #4d4d4d !important;
}
.boton_CC span img {
    margin-right: 15px;
}

div.boton_CC_Modal_overlay {
    position: fixed;
    display: block;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100vw;
    z-index: 9998;
    background: rgb(0, 0, 0) none repeat scroll 0% 0%;
    opacity: 0.5;
}

div.boton_CC_Modal {
    position: fixed;
    display: block;
    top: 50%;
    left: 50%;
    width: 95%;
    max-width: 498px;
    background: rgb(255, 255, 255) none repeat scroll 0% 0%;
    opacity: 1;
    transform: translate(-50%, -50%);
    z-index: 9999;
    padding: 20px;
    color: #4d4d4d;
    border-radius: 10px;
}

div.boton_CC_Modal_header {
    padding: 20px 0px;
}

.boton_CC_Modal_top {
    display: flex;
}

.boton_CC_Modal_close {
    width: 24px;
    height: 24px;
    margin-top: -12px;
    background-image: url(/static/media/close_modal.487913f7.svg);
    background-size: 12px 12px;
    background-position: center;
    background-repeat: no-repeat;
    margin-left: auto;
    cursor: pointer;
}

div.boton_CC_Modal_header h1 {
    font-weight: 500;
    font-size: 25px;
    margin-bottom: 20px;
}

div.boton_CC_Modal_header p.boton_CC_dsc {
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    color: #4d4d4d80;
    margin: 0;
}

section.boton_CC_Modal_body_saldo {
    padding: 10px 15px;
    width: calc(100% - 30px);
    background-color: #f6f6f6;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    color: #4d4d4dcc;
    margin-bottom: 20px;
    margin-left: 15px;
    margin-right: 15px;
}

button.boton_CC_Modal_body_infoBanco {
    display: block;
    left: 50%;
    padding: 10px;
    background-color: var(--blueRNB);
    border: none;
    color: white;
    border-radius: 5px;
    width: calc(100% - 30px);
    max-width: 431px;
    margin: auto;
}

button.boton_CC_Modal_body_infoBanco svg {
    margin-right: 5px;
    margin-top: -3px;
}

.boton_CC_transfe {
    font-size: 11px;
    font-weight: normal;
    color: #4d4d4d80;
    margin-top: 10px;
    margin-bottom: 0;
}

@media screen and (min-width: 650px) {
    div.boton_CC_Modal_header p.boton_CC_dsc {
        font-size: 15px;
    }
    section.boton_CC_Modal_body_saldo {
        font-size: 15px;
    }
}

.CheckResumen_resumen__3HJ3M {
  background: #f8f8f8;
  border-radius: 10px;
  padding: 20px 15px;
  margin-top: 20px;
}

.CheckResumen_title__2cvpi {
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  color: #4d4d4d;
  margin-bottom: 21px;
}

.CheckResumen_info__1ZadQ {
  display: flex;
  color: #4d4d4d;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: center;
}

.CheckResumen_info_label__3tvtM {
  font-size: 15px;
}

.CheckResumen_info_price__11Mqf {
  font-size: 15px;
}

.CheckResumen_total__2q8iP {
  display: flex;
  color: #4d4d4d;
  padding-top: 21px;
  margin-top: 21px;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
}

@media (min-width: 768px) {
  .CheckResumen_remresumen__6597R {
    padding: 30px 37px;
  }
  .CheckResumen_container__L4Rvn {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: center;
  }
  .CheckResumen_title__2cvpi {
    font-size: 25px;
    line-height: 25px;
  }
  .CheckResumen_info_label__3tvtM,
  .CheckResumen_info_price__11Mqf {
    font-size: 15px;
  }
  .CheckResumen_info_price__11Mqf {
    margin-left: auto;
  }
  .CheckResumen_total_label__-t5OM,
  .CheckResumen_total_price__3nG80 {
    font-size: 20px;
  }
  .CheckResumen_total_price__3nG80 {
    margin-left: 10px;
  }
  .CheckResumen_info__1ZadQ {
    justify-content: flex-start;
  }
  .CheckResumen_total__2q8iP {
    justify-content: flex-end;
    border-top: 1px solid #e1e3ea;
  }
  .CheckResumen_right__2P6QQ,
  .CheckResumen_left__2F7EN {
    width: 50%;
  }
  .CheckResumen_right__2P6QQ {
    border-right: 1.5px solid #e1e3ea;
  }
  .CheckResumen_left__2F7EN {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .CheckResumen_inform__3ysUq {
    width: 200px;
  }
  .CheckResumen_logo__1udjO {
    width: 222px;
    height: 183px;
    margin: 0 auto;
    background-image: url(/static/media/resumen_vector.87ad5994.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.PageHeader_container__3G8tC {
    width: 100%;
}
.PageHeader_header__9xudr {
    padding-bottom: 16px;
    border-bottom: 1px solid #d4d4d4;
}
.PageHeader_title__2KyPv {
    font-weight: 500;
    font-size: 25px;
    letter-spacing: 0.02em;
    color: #4d4d4d;
    margin: 0;
    margin-right: 10px;
}
.PageHeader_titleContainer__1dSw- {
    display: flex;
    align-items: center;
}
.PageHeader_topHead__2QSYc {
    display: flex;
    align-items: center;
    padding-bottom: 16px;
}
.PageHeader_containerHeaderComponent__2K9k- {
    display: none;
}
.PageHeader_description__1HK40 {
    font-weight: normal;
    font-size: 13px;
    letter-spacing: 0.02em;
    color: rgba(77, 77, 77, 0.8);
    margin: 0;
}
.PageHeader_infoIcon__gNFNA {
    cursor: pointer;
    background-image: url(/static/media/info.680b1248.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 22px;
    width: 22px;
    height: 22px;
}
.PageHeader_footer__lqWEQ {
    display: flex;
    width: 100%;
    margin-top: 0px;
}
@media screen and (min-width: 850px) {
    .PageHeader_container__3G8tC {
        padding-bottom: 41px;
    }
    .PageHeader_header__9xudr {
        padding-bottom: 24px;
    }
    .PageHeader_containerHeaderComponent__2K9k- {
        display: block;
    }
    .PageHeader_topHead__2QSYc {
        justify-content: space-between;
    }
    .PageHeader_title__2KyPv {
        font-size: 40px;
    }
    .PageHeader_topHead__2QSYc {
        padding-bottom: 24px;
    }
    .PageHeader_description__1HK40 {
        font-size: 16px;
    }
    .PageHeader_footer__lqWEQ {
        display: none;
        margin-top: 0;
    }
}

.Alerta_container__2NKvy {
  background: #cce2fc;
  padding: 16px 24px;
  border-radius: 8px;
  width: 100%;
  margin: 0 auto;
  color: #006ef2;
}

.Alerta_container__2NKvy div {
  display: grid;
  grid-template-columns: max-content 1fr;
}

.Alerta_container__2NKvy div div {
  display: flex;
  justify-content: center;
}

div.Alerta_img__18vH3 {
  margin-right: 20px;
}

div.Alerta_content__RzqvG {
  flex-direction: column;
}

div.Alerta_content__RzqvG h1 {
  font-weight: 500;
  font-size: 16px;
  margin: 0 0 5px 0;
}

div.Alerta_content__RzqvG p {
  font-size: 14px;
  margin: 0;
}

.Alerta_noSubtitle__1rMuL h1 {
  margin: 0 !important;
  text-align: left;
}

.ContinueNext_continueNext__2FrjY {
  display: flex;
  cursor: pointer;
  width: 182px;
  height: 48px;
  background-color: rgb(0, 110, 242);
  padding: 5px 10px;
  border-radius: 5px;
  border: medium none;
  outline: currentcolor none medium;
  color: white;
  letter-spacing: 0.02em;
  font-size: 15px;
  margin-left: auto;
  margin-top: 15px;
  align-items: center;
}
.ContinueNext_text_container__3XWZJ {
  display: flex;
  width: 100%;

  align-items: center;
  justify-content: center;
}
.ContinueNext_text__ij0h5 {
}
.ContinueNext_flecha__30o22 {
  width: 9px;
  height: 15px;
  margin-left: 10px;
  background-image: url(/static/media/flecha.01d3fd6b.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

/* *reset */
.botonBilletera_total__22CYo,
.botonBilletera_servicio__2Lx_y,
.botonBilletera_secondText__2Nmw2 {
    margin: 0;
    padding: 0;
}

.botonBilletera_container__2VyI3 {
    margin-bottom: 24px;
}

.botonBilletera_boton_Billetera__2pM41,
.botonBilletera_boton_BilleteraDisabled__XcVOb {
    width: 100%;
    height: auto;
    min-height: 48px;
    padding: 5px 10px;
    border-radius: 5px;
    border: medium none;
    outline: currentcolor none medium;
    font-weight: 500;
    font-size: 16px;
    line-height: 14px;
}

.botonBilletera_boton_Billetera__2pM41 {
    background-color: #006ef2;
    color: white;
    cursor: pointer;
}

.botonBilletera_boton_BilleteraDisabled__XcVOb {
    background-color: #f5f7fa;
    color: #a1a2a3;
}

.botonBilletera_img__3HuYr {
    margin-right: 15px;
    margin-top: -3px;
    /* background-color: yellowgreen; */
}

.botonBilletera_boton_Billetera_Modal__jGm77 {
    display: block;
    margin: 0;
    padding: 0;
    opacity: 1;
    color: #4d4d4d;
    border-radius: 10px;
}

.botonBilletera_boton_Billetera_Modal_top__33NW5 {
    display: flex;
}

.botonBilletera_boton_Billetera_Modal_close__3KBRZ {
    width: 24px;
    height: 24px;
    margin-top: -12px;
    background-image: url(/static/media/close_modal.487913f7.svg);
    background-size: 12px 12px;
    background-position: center;
    background-repeat: no-repeat;
    margin-left: auto;
    cursor: pointer;
}

/* div.boton_Billetera_Modal_header h1 {
    font-weight: 500;
    font-size: 25px;
    margin-bottom: 20px;
} */

.botonBilletera_boton_Billetera_dsc__1rVwY {
    font-size: 13px;
    line-height: 15px;
    color: #4d4d4d80;
    margin: 0;
    padding: 0;
}

.botonBilletera_boton_Billetera_Modal_body_saldo__3ucCp {
    padding: 10px 15px;
    width: calc(100%);
    height: 51px;
    background-color: #f6f6f6;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* font-size: 13px;
    color: #4d4d4dcc; */
    margin-bottom: 20px;
}

.botonBilletera_total__22CYo {
    font-weight: 400;
    font-size: 13px;
    line-height: 21px;
    margin: 10px 0;
    height: 20px;
    text-align: center;
}

.botonBilletera_footer_buttons_container__1-td1 {
    display: flex;
    justify-content: space-between;
}

.botonBilletera_footer_text_container__wzXfn {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0 14px;
    width: calc(100%);
}

.botonBilletera_footer_text__3dH8O {
    font-weight: 500;
    font-size: 16px;
    color: #4d4d4d;
}

.botonBilletera_boton_Billetera_info_container__3GJ_c {
    margin: 20px 0;
    display: flex;
    align-items: center;
}

.botonBilletera_boton_Billetera_info_circle__353O6 {
    width: 24px;
    height: 24px;
    background-image: url(/static/media/info_circle.386451fe.svg);
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 8px;
    cursor: pointer;
}

.botonBilletera_cancelar_btn__2qR_u {
    border: 1px solid #d4d4d4;
    border-radius: 8px;
    color: #4d4d4d80;
    background-color: #fff;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    padding: 11px 42px;
    min-width: 155.5px;
}

.botonBilletera_pagar_btn__1MjpG {
    background: #006ef2;
    border-radius: 8px;
    border: none;
    font-weight: 500;
    font-size: 16px;
    color: #fff;
    padding: 11px 52px;
    min-width: 155.5px !important;
}

.botonBilletera_boton_Billetera_transfe__20E-2 {
    font-size: 11px;
    font-weight: normal;
    color: #4d4d4d80;
    margin-top: 10px;
    margin-bottom: 0;
}

.botonBilletera_serviceRow__2Uvih {
    display: flex;
    align-items: center;
}

.botonBilletera_serviceCol__2IQWt {
    margin-right: 14px;
}

.botonBilletera_secondText__2Nmw2 {
    margin: 0;
    font-weight: normal;
    font-size: 13px;
    line-height: 21px;
    color: #4d4d4d;
}

.botonBilletera_servicio__2Lx_y {
    margin: 0 !important;
    font-weight: normal;
    font-size: 10px;
    line-height: 100%;
    color: rgba(77, 77, 77, 0.5);
}

.botonBilletera_animation__3mvBY {
    display: flex;
    align-items: center;
    justify-content: center;
}

.botonBilletera_animation__3mvBY {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}

.botonBilletera_animation__3mvBY.botonBilletera_showError__1Wckc {
    opacity: 1;
}

.botonBilletera_errorContainer__aWSSq {
    margin-top: 8px;
    min-height: 16px;
}

.botonBilletera_errorIcon__uPjjj {
    width: 16px;
    height: 16px;
    margin-right: 5px;
    background-image: url(/static/media/error-icon.e4ddedbf.svg);
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
}

.botonBilletera_errorText__V1gZC {
    font-weight: 700;
    font-size: 13px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #e00021;
}

@media screen and (max-width: 650px) {
    .botonBilletera_boton_Billetera_dsc__1rVwY {
        font-size: 15px;
    }

    .botonBilletera_boton_Billetera_Modal_body_saldo__3ucCp {
        font-size: 15px;
    }

    .botonBilletera_boton_Billetera_Modal_body__hPnUU {
        margin-left: 15px;
        margin-right: 15px;
        /* background-color: wheat; */
    }
}

.PaymentButtons_container__tQbNV {
  padding: 24px 0 0 0;

  height: max-content;
  opacity: 0.5;
  pointer-events: none;
}

.PaymentButtons_container__tQbNV.PaymentButtons_row__18J2f {
  grid-gap: 15px;
  gap: 15px;
  display: grid;
  grid-template-columns: auto auto auto;
}

.PaymentButtons_container__tQbNV.PaymentButtons_column__1s9bT {
  margin-bottom: 15px;
}

.PaymentButtons_button__2ywKG {
  margin-bottom: 15px;
}

.PaymentButtons_pay_enabled__cxJyb {
  opacity: 1;
  pointer-events: auto;
}

.PaymentButtons_disabledReason__2Ghyo {
  margin-top: 24px;
  text-align: center;
}

@media only screen and (max-width: 650px) {
  .PaymentButtons_container__tQbNV {
    grid-template-columns: 1fr;
  }
}

.input_containerMyInput__1eBcD {
    padding: 9.5px 16px;
    color: #4d4d4d;
    font-weight: normal;
    width: 100%;
    background-color: #f5f7fa;
    border-radius: 8px;
    outline: 0;
    border: 0;
    font-size: 16px;
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 15px;
    gap: 15px;
}
.input_ContainerMyInput__2R4xb div {
    display: flex;
    justify-items: center;
    width: 100%;
    align-items: center;
}
.input_ContainerMyInput__2R4xb img {
    width: 24px;
    height: 24px;
}

.input_containerMyInput__1eBcD .input_myInput__2wljz {
    padding: 0;
    background-color: transparent;
    width: 100%;
}
.input_myInput__2wljz {
    padding: 9.5px 16px;
    color: #4d4d4d;
    font-weight: normal;
    width: 100%;
    background-color: #fff;
    border-radius: 8px;
    outline: 0;
    border: 1px solid #ccc;
    font-size: 16px;
    transition: all 0.3s ease-in-out;
}

.input_myInput__2wljz:disabled {
    background-color: #f5f7fa;
    color: #999999a1;
}
/* input error  */
.input_inputError__2N5y- {
    border: 1px solid #c92432;
    transition: all 0.3s ease-in-out;
}

.Checkbox_container__1_B__ {
    display: block;
    position: relative;
    padding-left: calc(20px + 10px);
    cursor: pointer;
    font-size: 13px;
    -webkit-user-select: none;
    user-select: none;
    font-weight: 400;
    font-size: 13px;
    color: #4d4d4d;
    margin-bottom: 0;
}

/* Hide the browser's default checkbox */
.Checkbox_container__1_B__ .Checkbox_checkbox__HuUIy {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.Checkbox_checkmark__3pQRb {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    box-sizing: border-box;
    border-radius: 5px;
}

/* On mouse-over, add a grey background color */
.Checkbox_container__1_B__:hover .Checkbox_checkbox__HuUIy ~ .Checkbox_checkmark__3pQRb {
    /* background-color: #ffffff; */
}

/* When the checkbox is checked, add a blue background */
.Checkbox_container__1_B__ .Checkbox_checkbox__HuUIy:checked ~ .Checkbox_checkmark__3pQRb {
    /* background-color: #006ef2; */
    background-position: center;
    transition: background 0.8s;
}

.Checkbox_container__1_B__ .Checkbox_checkbox__HuUIy:checked:hover ~ .Checkbox_checkmark__3pQRb {
    background: #035ece radial-gradient(circle, transparent 1%, #035ece 1%) center/15000%;
}

.Checkbox_container__1_B__ .Checkbox_checkbox__HuUIy:not(:checked):hover ~ .Checkbox_checkmark__3pQRb {
    background: rgba(0, 0, 0, 0.04) radial-gradient(circle, transparent 1%, rgba(0, 0, 0, 0.04) 1%)
        center/15000%;
    /* border: solid 1px #006ef2; */
}

.Checkbox_container__1_B__ .Checkbox_checkbox__HuUIy:checked ~ .Checkbox_checkmark__3pQRb {
    background-color: #006ef2;
    background-size: 100%;
    transition: background 0s;
}
.Checkbox_container__1_B__ .Checkbox_checkbox__HuUIy:not(:checked) ~ .Checkbox_checkmark__3pQRb {
    background-color: #ffffff;
    background-size: 100%;
    transition: background 0s;
    border: solid 1px #006ef2;
}

/* Create the checkmark/indicator (hidden when not checked) */
.Checkbox_checkmark__3pQRb:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.Checkbox_container__1_B__ .Checkbox_checkbox__HuUIy:checked ~ .Checkbox_checkmark__3pQRb:after {
    display: block;
}

/* Style the checkmark/indicator */
.Checkbox_container__1_B__ .Checkbox_checkmark__3pQRb:after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.Checkbox_error__2dKrn .Checkbox_container__1_B__ .Checkbox_checkbox__HuUIy:not(:checked) ~ .Checkbox_checkmark__3pQRb {
    border: 1px solid red;
    animation: Checkbox_shake__B68L- 0.62s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
}

.Checkbox_error__2dKrn .Checkbox_helperText__3bji8 {
    color: #d32f2f;
}

.Checkbox_helperText__3bji8 {
    padding: 0px 14px 0px;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    text-align: left;
    margin-top: 3px;
    width: 100%;
}

@keyframes Checkbox_shake__B68L- {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}

@media screen and (min-width: 768px) {
    .Checkbox_container__1_B__ {
        font-size: 16px;
        line-height: 20px;
    }
}

.select_selectArrow__1B9rK {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url(/static/media/arrow.e08d69e8.svg);
  background-repeat: no-repeat;
  background-position-x: calc(100% - 14px);
  background-position-y: 50%;
}

.modal_overlay__3PlRq {
  position: fixed;
  opacity: 0;
  background: #000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  cursor: pointer;
  touch-action: none;
  transition: all 0.4s ease-in-out;
}

.modal_overlay__3PlRq.modal_animate__S7sCP {
  opacity: 0.3;
}

.modal_modal__2kPaE {
  opacity: 0;
  position: fixed;
  background: #fff;
  padding: 20px;
  width: 92%;
  max-width: 425px;
  height: -moz-fit-content;
  height: fit-content;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  z-index: 1001;
  bottom: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.4s ease-in-out;
}

.modal_modal__2kPaE.modal_noTouch__1EW3V {
  touch-action: none;
}

.modal_modal__2kPaE.modal_animate__S7sCP {
  opacity: 1;
}

.modal_modalHeader__3kKGE {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal_close__Kfxe- {
  right: 20px;
  top: 27px;
  z-index: 1002;
}
.modal_modalHeader__3kKGE img {
  margin-left: auto;
  width: 16px;
  height: 16px;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .modal_modal__2kPaE {
    padding: 40px;
  }
}

.bottomSheetBG_overlay__3CVOO {
    position: fixed !important;
    opacity: 0;
    background: #000;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    cursor: pointer;
    touch-action: none !important;
    transition: all 0.4s ease-in-out;
}

.bottomSheetBG_overlay__3CVOO.bottomSheetBG_animate__R8KuA {
    opacity: 0.3;
}

.bottomSheetBG_modal__sUYPZ,
.bottomSheetBG_modal2__LA8IQ {
    position: fixed;
    background: #fff;
    padding: 0px;
    width: calc(100% + 2px);
    max-height: 90%;
    height: -moz-fit-content;
    height: fit-content;
    overflow-y: auto;
    border-radius: 20px 20px 0 0;
    box-shadow: 0 0 10px rgb(0 0 0 / 25%);
    z-index: 1001;
    bottom: 0;
    left: calc(50% + 1px);
    top: 150%;
    transform: translate(-50%, 150%);
    transition: 0.4s ease-in-out;
}

.bottomSheetBG_modalContent__Kvc06 {
    /* padding: 20px; */
}

.bottomSheetBG_noTouch__1uNkl {
    touch-action: none;
}

.bottomSheetBG_modal__sUYPZ.bottomSheetBG_animate__R8KuA,
.bottomSheetBG_modal2__LA8IQ.bottomSheetBG_animate__R8KuA {
    transform: translate(calc(-50% - 1px), -100%);
    top: 100%;
}

.bottomSheetBG_modalHeader__2OMlV {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(87.99deg, #006ef2 3.65%, #01aefa 121.61%);
    padding-right: 20px;
}
.bottomSheetBG_modalTitle__3a6N6 {
    font-weight: 500;
    font-size: 25px;
    line-height: 29px;
    letter-spacing: 0.02em;
    color: #4d4d4d;
    /* margin-bottom: 8px; */
}
.bottomSheetBG_close__2CiNz,
.bottomSheetBG_closeWhite__2-G9T {
    height: 16px;
    width: 16px;
    z-index: 1002;
    position: absolute;
    cursor: pointer;
}
.bottomSheetBG_close__2CiNz {
    right: 0px;
    top: 0px;
    margin: 24px;
}
.bottomSheetBG_closeWhite__2-G9T {
    top: 31.75px;
    right: 32.5px;
}
.bottomSheetBG_modalHeader__2OMlV img {
    margin-left: auto;
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.bottomSheetBG_modalBody__1MPob {
    /* padding: 20px; */
}

@media (min-width: 570px) {
    .bottomSheetBG_modal__sUYPZ {
        border-radius: 10px;
    }
    .bottomSheetBG_modal2__LA8IQ {
        border-radius: 20px;
    }
    .bottomSheetBG_modal__sUYPZ,
    .bottomSheetBG_modal2__LA8IQ {
        width: calc(100% - 16px * 2);
        max-width: 500px;
        opacity: 0;
        transform: translate(-50%, -50%);
    }
    .bottomSheetBG_modal__sUYPZ.bottomSheetBG_animate__R8KuA,
    .bottomSheetBG_modal2__LA8IQ.bottomSheetBG_animate__R8KuA {
        top: 50%;
        opacity: 1;
        transform: translate(-50%, -50%);
        transition: 0.2s ease-in-out;
    }
    .bottomSheetBG_closeWhite__2-G9T {
        top: 31.64px;
        right: 32px;
        cursor: pointer;
    }
    .bottomSheetBG_overlay__3CVOO {
        background: #000;
    }
    .bottomSheetBG_modalContent__Kvc06 {
        padding: 0;
    }
}

.Link_link__2M4Rb {
    color: rgb(0, 110, 242);
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
}
.Link_link2__y2Bb_ {
    font-weight: 400;
    font-size: 11px;
    line-height: 125%;
    color: #006ef2;
}

.Link_container__aNE57 {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
}

.Link_arrow__2yJwC {
    background-image: url(/static/media/blue_arrow.f654a4d1.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 10px;
    height: 7px;
    margin-right: 4px;
    /* margin-top: 4px; */
}
@media (min-width: 570px) {
    .Link_link2__y2Bb_ {
        font-size: 13px;
    }
}

@media (min-width: 769px) {
    .Link_link__2M4Rb {
        font-size: 16px;
        line-height: 22px;
    }
}

.AniversarioBS_confetti__6xJ5v {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    font-style: normal;
}
.AniversarioBS_img__3hWCF {
    width: 275px;
    height: 217px;
    background-image: url(/static/media/aniversarioLogo.464ae141.svg);
    background-size: 100% 100%;
    position: relative;
    bottom: 4px;
}
.AniversarioBS_description__3ct4b {
    line-height: 20px;
    font-size: 16px;
    color: #fff;
    font-weight: 400;
    margin: 8;
}

.remtienda_wrapper {
  padding: 20px 16px;
  max-width: 920px;
  margin: 0 auto;
}

.remtienda_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.remtienda_title {
  font-weight: 400;
  font-size: 25px;
  color: #4d4d4d;
  margin-bottom: 0;
}

.remtienda_cart {
  position: relative;
  background-image: url(/static/media/cart_ico.9fb129e8.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 31px;
  height: 30px;
  cursor: pointer;
}

.remcontador_cart {
  filter: grayscale(1) brightness(2);
}

.remcontador_cart:after {
  display: none;
}

.remtienda_cart:after {
  position: absolute;
  content: attr(data-items);
  background: #006ef2;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  font-size: 10px;
  width: 13px;
  height: 13px;
  line-height: 14px;
  right: -3px;
  top: -3px;
}

.remtienda_cart.remcart_loading:after {
  content: none;
}

.remtienda_subtitle {
  font-size: 13px;
  opacity: 0.5;
  color: #4d4d4d;
}

.remtienda_filters {
  padding: 15px 0;
}

.remtienda_filter_sort {
  padding: 0 0 15px 0;
}

.remtienda_sort_label {
  color: #4d4d4d;
  opacity: 0.8;
  font-size: 13px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 10px;
}

.remtienda_sort_select select {
  background-color: #f5f5f5;
  color: #4d4d4d;
  height: 38px;
  width: 100%;
  min-width: 200px;
  line-height: 20px;
  font-size: 13px;
  font-weight: 400;
  border: none;
  border-radius: 5px;
  padding: 8px 14px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(/static/media/collapse_ico.27dc965d.svg);
  background-size: 15px 9px;
  background-repeat: no-repeat;
  background-position-x: calc(100% - 14px);
  background-position-y: center;
}

.remtienda_sort_select select:focus,
.remtienda_sort_select select:focus-visible,
.remtienda_sort_select select:focus-within {
  outline: none !important;
}

.remtiemda_search_container {
  display: flex;
  align-items: center;
}

.remtienda_filter_search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f5f5f5;
  border-radius: 7px;
  padding: 9px 16px;
  width: 100%;
  min-width: 237px;
  margin-right: 16px;
}

.remtienda_search_ico {
  background-image: url(/static/media/input_search.446dd2fe.svg);
  background-size: contain;
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #016ef2;
  padding: 9px 16px;
  height: 35px;
  width: 35px;
  border-radius: 5px;
  margin-left: auto;
  cursor: pointer;
  flex-shrink: 0;
}

.remtienda_filter_search form {
  width: 100%;
}

.remtienda_search_input {
  background: none;
  border: none;
  width: 100%;
  font-size: 13px;
  line-height: 17px;
  font-weight: 400;
}

.remtienda_search_input:focus,
.remtienda_search_input:focus-visible,
.remtienda_search_input:focus-within {
  outline: none !important;
}

.remtienda_search_clear {
  background-image: url(/static/media/input_clear.0440ffcf.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 13px;
  width: 13px;
  cursor: pointer;
  padding-left: 15px;
  opacity: 0;
  pointer-events: none;
}

.remsq_visible.remtienda_search_clear {
  opacity: 1;
  pointer-events: auto;
}

.remtienda_list {
  padding-bottom: 15px;
}

.remtienda_paginator {
  display: flex;
  justify-content: space-between;
}

.remtienda_btn {
  justify-content: center;
  display: flex;
  background: #f0f0f0;
  color: #9e9e9e;
  border-radius: 5px;
  padding: 10px 31px;
  font-size: 15px;
  font-weight: 500;
  line-height: 12px;
  cursor: pointer;
  width: 100%;
}

.remtienda_paginator_prev {
  background: none;
}

.remtienda_paginator_prev:before {
  display: block;
  content: " ";
  background-image: url(/static/media/tienda_prev.5556463a.svg);
  background-size: contain;
  width: 9px;
  height: 15px;
  margin-right: 10px;
}

.remtienda_paginator_next:after {
  display: block;
  content: " ";
  background-image: url(/static/media/tienda_next.0387e449.svg);
  background-size: contain;
  width: 9px;
  height: 15px;
  margin-left: 10px;
}

/* Sin resultados de busqueda */

.busqueda_empty {
  font-size: 13px;
  /* line-height: 14px; */
}

.busqueda_empty > .sinresultadosimg {
  width: 95%;
  height: 350px;
  background-image: url(/static/media/sinresultados.a3265c0b.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 auto;
  margin-top: 20px;
}

.busqueda_empty > b {
  color: #4d4d4d;
  font-weight: 500;
  display: block;
  margin-top: 25px;
}

.busqueda_empty > p {
  color: #4d4d4d;
  margin-top: 10px;
  opacity: 0.5;
}

@media screen and (min-width: 650px) {
  .remtienda_wrapper {
    background: #fff;
    box-shadow: 0 0 7px rgb(0 0 0 / 10%);
    border-radius: 10px;
    margin: 5vh auto;
    padding: 52px 40px;
  }
  .remtienda_title {
    font-size: 40px;
  }
  .remtienda_subtitle {
    font-size: 15px;
  }
  .remtienda_filters {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
  .remtienda_filter_sort {
    padding: 0;
  }
  .remtienda_sort_label {
    display: none;
  }
  .remtienda_sort_select select {
    padding: 9px 16px;
  }
  .remtienda_list {
    padding: 30px 0;
  }
  .remtienda_paginator {
    justify-content: center;
  }
  .remtienda_paginator_prev {
    background: #f0f0f0;
    margin-right: 10px;
  }
  .remtienda_paginator_prev.remtienda_u {
    margin-right: auto;
  }
  .remtienda_paginator_next.remtienda_u {
    margin-left: auto;
  }
  .remtienda_btn {
    width: auto !important;
  }
  .busqueda_empty {
    font-size: 15px;
    /* line-height: 14px; */
  }
}

@font-face {
  font-family: "Noway";
  font-weight: bold;
  font-style: normal;
  src: url("//db.onlinewebfonts.com/t/7e0f75fa1eb5ee523ae2961d4e7a11a6.eot");
  src: url("//db.onlinewebfonts.com/t/7e0f75fa1eb5ee523ae2961d4e7a11a6.eot?#iefix")
      format("embedded-opentype"),
    url("//db.onlinewebfonts.com/t/7e0f75fa1eb5ee523ae2961d4e7a11a6.woff2") format("woff2"),
    url("//db.onlinewebfonts.com/t/7e0f75fa1eb5ee523ae2961d4e7a11a6.woff") format("woff"),
    url("//db.onlinewebfonts.com/t/7e0f75fa1eb5ee523ae2961d4e7a11a6.ttf") format("truetype"),
    url("//db.onlinewebfonts.com/t/7e0f75fa1eb5ee523ae2961d4e7a11a6.svg#Noway") format("svg");
}
@font-face {
  font-family: "Noway";
  font-weight: normal;
  font-style: normal;
  src: url("//db.onlinewebfonts.com/t/1b186240287df767976b05cd51ceb761.eot");
  src: url("//db.onlinewebfonts.com/t/1b186240287df767976b05cd51ceb761.eot?#iefix")
      format("embedded-opentype"),
    url("//db.onlinewebfonts.com/t/1b186240287df767976b05cd51ceb761.woff2") format("woff2"),
    url("//db.onlinewebfonts.com/t/1b186240287df767976b05cd51ceb761.woff") format("woff"),
    url("//db.onlinewebfonts.com/t/1b186240287df767976b05cd51ceb761.ttf") format("truetype"),
    url("//db.onlinewebfonts.com/t/1b186240287df767976b05cd51ceb761.svg#Noway") format("svg");
}
.remcontador_contador {
  display: flex;
  justify-content: center;
}
.remcontador_group {
  margin-right: 27px;
}
.remcontador_group:last-of-type {
  margin-right: 0;
}
.remcontador_value {
  font-size: 84px;
  font-family: "Noway", sans-serif;
  font-style: normal;
  font-weight: bold;
  letter-spacing: -0.0859731px;
  text-align: center;
  color: #282828;
}
.remcontador_label {
  font-family: "Noway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  letter-spacing: -0.0859731px;
  text-align: center;
  color: rgba(77, 77, 77, 0.5);
}

.remcontador_finished .remcontador_label,
.remcontador_finished .remcontador_value {
  color: #000000;
  opacity: 0.2;
}

.remcontador_ilustracion {
  max-width: 90vw;
  margin: 0 auto;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.remcontador_ilu_maintenance {
  width: 541px;
  height: 268px;
  background-image: url(/static/media/mante.8c0f7769.png);
  margin: 59px auto 24px;
}
.remcontador_ilu_toopen {
  width: 575px;
  height: 425px;
  background-image: url(/static/media/toopen.9727bc79.svg);
}
.remcontador_ilu_finished {
  width: 475px;
  height: 541px;
  background-image: url(/static/media/finished.c5a1f065.svg);
  margin: 59px auto 24px;
}

@media screen and (max-width: 651px) {
  .remcontador_ilu_toopen {
    width: 346px;
    height: 327px;
  }
  .remcontador_ilu_finished {
    width: 285px;
    height: 325px;
  }
  .remcontador_value {
    font-size: 50px;
  }
  .remcontador_label {
    font-size: 15px;
  }
}

.SplashScreen_container__C783m {
    opacity: 0;
    position: fixed;
    display: flex;
    left: 0;
    top: 0;
    align-content: center;
    justify-content: center;
    align-items: center;
    background-color: #006ef2;
    height: 100vh;
    width: 100vw;
    z-index: 999999999999999;
    overflow: hidden;
}
.SplashScreen_enabled__1RsFz {
    opacity: 1;
    pointer-events: all;
}
.SplashScreen_disabled__2mzLL {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.5s ease-in-out;
}
.SplashScreen_content__ScuaJ {
}

.SplashScreen_logo__3Ne5_ {
    width: 350px;
    height: 75px;
    background-image: url(/static/media/logo_new.4504908f.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 59px;
}
.SplashScreen_loader__3A0JC {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Loader */
.SplashScreen_ldsEllipsis__2fqcE {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 13px;
}
.SplashScreen_ldsEllipsis__2fqcE div {
    position: absolute;
    top: 0px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.SplashScreen_ldsEllipsis__2fqcE div:nth-child(1) {
    left: 8px;
    animation: SplashScreen_ldsEllipsis1__3wufM 0.6s infinite;
}
.SplashScreen_ldsEllipsis__2fqcE div:nth-child(2) {
    left: 8px;
    animation: SplashScreen_ldsEllipsis2__2xMpk 0.6s infinite;
}
.SplashScreen_ldsEllipsis__2fqcE div:nth-child(3) {
    left: 32px;
    animation: SplashScreen_ldsEllipsis2__2xMpk 0.6s infinite;
}
.SplashScreen_ldsEllipsis__2fqcE div:nth-child(4) {
    left: 56px;
    animation: SplashScreen_ldsEllipsis3__c80Pj 0.6s infinite;
}
@keyframes SplashScreen_ldsEllipsis1__3wufM {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
@keyframes SplashScreen_ldsEllipsis3__c80Pj {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(0);
        opacity: 0;
    }
}
@keyframes SplashScreen_ldsEllipsis2__2xMpk {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}

@media screen and (min-width: 1023px) {
    .SplashScreen_logo__3Ne5_ {
        width: 449px;
        height: 79px;
        margin-bottom: 100px;
    }
}

.Header_container__HWOZM {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 56px;
}

.Header_logo__3MDRt {
    width: 141px;
    height: 30px;
    background-image: url(/static/media/logo_new.42e002ac.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    /* margin-left: 24px; */
    margin-bottom: 40px;
}

.Header_hexagon__24zn2 {
    width: 114px;
    height: 123px;
    background-image: url(/static/media/hexagon0.81fe9bf1.svg);
    background-repeat: no-repeat;
    background-position: bottom left;
    background-size: contain;
}

.Header_titleAlter__p6nc- {
    width: 100%;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #282828;
    margin-right: 24px;
}
@media (min-width: 329px) {
    .Header_titleAlter__p6nc- {
        font-size: 25px;
        line-height: 30px;
    }
}
@media screen and (min-width: 769px) {
    .Header_container__HWOZM {
        padding-top: 0;
    }

    .Header_logo__3MDRt {
        margin-left: 0;
        margin-bottom: 36px;
    }

    .Header_hexagon__24zn2 {
        display: none;
    }
    .Header_titleAlter__p6nc- {
        max-width: 500px;
        font-size: 34px;
        line-height: 36px;
        text-align: left;
        margin-top: -80px;
        margin-bottom: 40px;
    }
}

.Title_container__2DH49 {
    margin-bottom: 24px;
    width: 100%;
    max-width: 500px;
}
.Title_title__26oXD {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #282828;
    margin-bottom: 8px;
}
.Title_description__3aUNZ {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #637381;
}

@media screen and (min-width: 769px) {
    .Title_container__2DH49 {
        margin-bottom: 36px;
    }
    .Title_title__26oXD {
        font-size: 24px;
        line-height: 36px;
    }
    .Title_description__3aUNZ {
        font-size: 16px;
        line-height: 24px;
    }
    .Title_titleAlter__1IcmK {
        font-size: 34px;
        line-height: 36px;
    }
}

.SocialButtons_container__GTLF1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.SocialButtons_button__kjnvq {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 98px;
    height: 40px;
    border: 1px solid rgba(145, 158, 171, 0.32);
    background-color: #fff;
    border-radius: 8px;
    margin-right: 16px;
    cursor: pointer;
    transition: filter 0.2s ease-in-out;
}
.SocialButtons_button__kjnvq:last-child {
    margin-right: 0;
}
.SocialButtons_button__kjnvq:hover {
    filter: brightness(0.96);
}
.SocialButtons_icon__2M4uj {
    width: 24px;
    height: 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.SocialButtons_google__2IGKL {
    background-image: url(/static/media/google.12e6503a.svg);
}
.SocialButtons_facebook__2IWZI {
    background-image: url(/static/media/facebook.27e85299.svg);
}
.SocialButtons_apple__176HW {
    background-image: url(/static/media/apple.0546ebe3.svg);
}

@media screen and (min-width: 769px) {
    .SocialButtons_button__kjnvq {
        width: 156px;
        height: 48px;
    }
}

.Layout_loginContainer__3g8Wh {
    width: 100%;
}

.Layout_illustrationContainer__2nlQ0 {
    display: none;
}
.Layout_illustrationTextContainer__3OPPh {
    display: none;
}
.Layout_imgHeader__XJ2lE {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 209px;
    border-radius: 0 0 32px 32px;
    margin-bottom: 40px;
    object-fit: cover;
}

@media screen and (min-width: 769px) {
    .Layout_main__MP1qU {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .Layout_loginContainer__3g8Wh {
        width: 65%;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .Layout_loginSection__WzUtX {
        max-width: 500px;
        width: 100%;
    }
    .Layout_illustrationContainer__2nlQ0 {
        display: block;
        position: relative;
        /*  background-color: #006ef2; */
        height: 100vh;
        width: 41%;
        max-width: 650px;
        overflow: hidden;
        top: 0;
        right: 0;
    }
    .Layout_illustration___mAaX {
        position: fixed;
        height: 100vh;
        width: 41%;
        max-width: 650px;
        object-fit: cover;
        border-top-left-radius: 100px;
        top: 0;
        right: -27px;
    }
    .Layout_illustrationTextContainer__3OPPh {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        max-width: 650px;
        height: 173px;
        width: 41%;
        padding-right: 27px;
        position: fixed;
        z-index: 2;
        bottom: 0px;
        right: -27px;

        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(15, 15, 15, 0.6) 40.46%);
    }
    .Layout_illustrationTextTitle__1EtxX {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 126.19%;
        letter-spacing: 0;
        color: #ffffff;
        margin-bottom: 21px;
    }
    .Layout_illustrationTextDescription__CAjz- {
        max-width: 400px;
        width: 100%;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 154.69%;
        text-align: center;
        letter-spacing: 0;
        color: #ffffff;
        text-align: center;
    }
    .Layout_imgHeader__XJ2lE {
        display: none;
    }
}

.OutlinedInput_container__2W9n2 {
    margin-bottom: 14px;
    width: 100%;
}

.OutlinedInput_root__1yNz7 {
    position: relative;
    padding-top: 0px;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.4px;
}

.OutlinedInput_root__1yNz7 .OutlinedInput_input__s5D_J,
.OutlinedInput_root__1yNz7 textarea {
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 10px 14px 10px;
    font-size: 16px;
    line-height: inherit;
    color: #52606c;
    border-style: solid;
    border-width: 1px;
    border-color: transparent #dce0e4 #dce0e4;
    border-radius: 8px;
    -webkit-text-fill-color: currentColor;
    background-color: transparent;
    caret-color: #006ef2;
    transition: border 0.2s, box-shadow 0.2s;
}

.OutlinedInput_root__1yNz7.OutlinedInput_fieldIcon__3g1hV .OutlinedInput_input__s5D_J,
.OutlinedInput_root__1yNz7 textarea {
    padding-right: 50px;
}

.OutlinedInput_root__1yNz7 .OutlinedInput_input__s5D_J:not(:focus):placeholder-shown,
.OutlinedInput_root__1yNz7 textarea:not(:focus):placeholder-shown {
    border-top-color: #dce0e4;
}

.OutlinedInput_root__1yNz7 .OutlinedInput_input__s5D_J + .OutlinedInput_label__3c_T3,
.OutlinedInput_root__1yNz7 textarea + .OutlinedInput_label__3c_T3 {
    display: flex;
    width: 100%;
    max-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 13px;
    line-height: 3px;
    color: #919eab;
    cursor: text;
    transition: color 0.2s, font-size 0.2s, line-height 0.2s;
}

.OutlinedInput_root__1yNz7 .OutlinedInput_label__3c_T3 {
    margin-bottom: 0;
}

.OutlinedInput_root__1yNz7 .OutlinedInput_input__s5D_J:not(:focus):placeholder-shown + .OutlinedInput_label__3c_T3,
.OutlinedInput_root__1yNz7 .OutlinedInput_textarea__326Xp:not(:focus):placeholder-shown + .OutlinedInput_label__3c_T3,
.OutlinedInput_root__1yNz7 .OutlinedInput_input__s5D_J:not(:focus):placeholder-shown:not(:-webkit-autofill) + .OutlinedInput_label__3c_T3 {
    font-size: 16px;
    line-height: 46px;
}

.OutlinedInput_root__1yNz7 .OutlinedInput_input__s5D_J + .OutlinedInput_label__3c_T3::before,
.OutlinedInput_root__1yNz7 .OutlinedInput_input__s5D_J + .OutlinedInput_label__3c_T3::after,
.OutlinedInput_root__1yNz7 textarea + .OutlinedInput_label__3c_T3::before,
.OutlinedInput_root__1yNz7 textarea + .OutlinedInput_label__3c_T3::after {
    content: "";
    display: block;
    box-sizing: border-box;
    height: 8px;
    min-width: 10px;
    margin-top: 0px;
    border-top: solid 1px #dce0e4;
    pointer-events: none;
    box-shadow: inset 0 1px transparent;
    transition: border 0.2s;
}

.OutlinedInput_root__1yNz7 .OutlinedInput_input__s5D_J + .OutlinedInput_label__3c_T3::before,
.OutlinedInput_root__1yNz7 textarea + .OutlinedInput_label__3c_T3::before {
    margin-right: 4px;
    border-left: solid 1px transparent;
    border-radius: 8px 0;
}

.OutlinedInput_root__1yNz7 .OutlinedInput_input__s5D_J + .OutlinedInput_label__3c_T3::after,
.OutlinedInput_root__1yNz7 textarea + .OutlinedInput_label__3c_T3::after {
    flex-grow: 1;
    margin-left: 4px;
    border-right: solid 1px transparent;
    border-radius: 0 8px;
}

.OutlinedInput_root__1yNz7 .OutlinedInput_input__s5D_J:not(:focus):placeholder-shown + .OutlinedInput_label__3c_T3::before,
.OutlinedInput_root__1yNz7 .OutlinedInput_input__s5D_J:not(:focus):placeholder-shown + .OutlinedInput_label__3c_T3::after,
.OutlinedInput_root__1yNz7 textarea:not(:focus):placeholder-shown + .OutlinedInput_label__3c_T3::before,
.OutlinedInput_root__1yNz7 textarea:not(:focus):placeholder-shown + .OutlinedInput_label__3c_T3::after {
    border-top-color: transparent;
}

.OutlinedInput_root__1yNz7 .OutlinedInput_input__s5D_J:focus,
.OutlinedInput_root__1yNz7 textarea:focus {
    border-color: transparent #006ef2 #006ef2;
    outline: none;
}

.OutlinedInput_root__1yNz7 .OutlinedInput_input__s5D_J:focus + .OutlinedInput_label__3c_T3,
.OutlinedInput_root__1yNz7 textarea:focus + .OutlinedInput_label__3c_T3 {
    color: #006ef2;
}

.OutlinedInput_root__1yNz7 .OutlinedInput_input__s5D_J:focus + .OutlinedInput_label__3c_T3::before,
.OutlinedInput_root__1yNz7 .OutlinedInput_input__s5D_J:focus + .OutlinedInput_label__3c_T3::after,
.OutlinedInput_root__1yNz7 textarea:focus + .OutlinedInput_label__3c_T3::before,
.OutlinedInput_root__1yNz7 textarea:focus + .OutlinedInput_label__3c_T3::after {
    border-top-color: #006ef2;
}

.OutlinedInput_root__1yNz7 .OutlinedInput_input__s5D_J:disabled,
.OutlinedInput_root__1yNz7 .OutlinedInput_input__s5D_J:disabled + .OutlinedInput_label__3c_T3,
.OutlinedInput_root__1yNz7 textarea:disabled,
.OutlinedInput_root__1yNz7 textarea:disabled + .OutlinedInput_label__3c_T3 {
    color: rgba(189, 192, 197, 0.5);
    border-color: transparent rgba(189, 192, 197, 0.5) rgba(189, 192, 197, 0.5);
    cursor: not-allowed;
}

.OutlinedInput_root__1yNz7 .OutlinedInput_input__s5D_J:disabled + .OutlinedInput_label__3c_T3::before,
.OutlinedInput_root__1yNz7 .OutlinedInput_input__s5D_J:disabled + .OutlinedInput_label__3c_T3::after,
.OutlinedInput_root__1yNz7 textarea:disabled + .OutlinedInput_label__3c_T3::before,
.OutlinedInput_root__1yNz7 textarea:disabled + .OutlinedInput_label__3c_T3::after {
    border-top-color: rgba(189, 192, 197, 0.5);
}

.OutlinedInput_root__1yNz7 .OutlinedInput_input__s5D_J:disabled:placeholder-shown,
.OutlinedInput_root__1yNz7 .OutlinedInput_input__s5D_J:disabled:placeholder-shown + .OutlinedInput_label__3c_T3,
.OutlinedInput_root__1yNz7 textarea:disabled:placeholder-shown,
.OutlinedInput_root__1yNz7 textarea:disabled:placeholder-shown + .OutlinedInput_label__3c_T3 {
    border-top-color: rgba(189, 192, 197, 0.5);
}

.OutlinedInput_root__1yNz7 .OutlinedInput_input__s5D_J:disabled:placeholder-shown + .OutlinedInput_label__3c_T3::before,
.OutlinedInput_root__1yNz7 .OutlinedInput_input__s5D_J:disabled:placeholder-shown + .OutlinedInput_label__3c_T3::after,
.OutlinedInput_root__1yNz7 textarea:disabled:placeholder-shown + .OutlinedInput_label__3c_T3::before,
.OutlinedInput_root__1yNz7 textarea:disabled:placeholder-shown + .OutlinedInput_label__3c_T3::after {
    border-top-color: transparent;
}

.OutlinedInput_error__2ZFax .OutlinedInput_root__1yNz7 .OutlinedInput_input__s5D_J,
.OutlinedInput_error__2ZFax .OutlinedInput_root__1yNz7 textarea {
    border-color: transparent #d32f2f #d32f2f;
}

.OutlinedInput_error__2ZFax .OutlinedInput_root__1yNz7 .OutlinedInput_input__s5D_J + .OutlinedInput_label__3c_T3,
.OutlinedInput_error__2ZFax .OutlinedInput_root__1yNz7 textarea + .OutlinedInput_label__3c_T3 {
    color: #d32f2f;
}

.OutlinedInput_error__2ZFax .OutlinedInput_root__1yNz7 .OutlinedInput_input__s5D_J + .OutlinedInput_label__3c_T3::before,
.OutlinedInput_error__2ZFax .OutlinedInput_root__1yNz7 .OutlinedInput_input__s5D_J + .OutlinedInput_label__3c_T3::after,
.OutlinedInput_error__2ZFax .OutlinedInput_root__1yNz7 textarea + .OutlinedInput_label__3c_T3::before,
.OutlinedInput_error__2ZFax .OutlinedInput_root__1yNz7 textarea + .OutlinedInput_label__3c_T3::after {
    border-top-color: #d32f2f;
}

.OutlinedInput_error__2ZFax .OutlinedInput_root__1yNz7 .OutlinedInput_input__s5D_J:not(:focus):placeholder-shown,
.OutlinedInput_error__2ZFax .OutlinedInput_root__1yNz7 .OutlinedInput_input__s5D_J:not(:focus):placeholder-shown + .OutlinedInput_label__3c_T3,
.OutlinedInput_error__2ZFax .OutlinedInput_root__1yNz7 textarea:not(:focus):placeholder-shown,
.OutlinedInput_error__2ZFax .OutlinedInput_root__1yNz7 textarea:not(:focus):placeholder-shown + .OutlinedInput_label__3c_T3 {
    border-top-color: #d32f2f;
    color: #d32f2f;
}

.OutlinedInput_error__2ZFax .OutlinedInput_root__1yNz7 .OutlinedInput_input__s5D_J:not(:focus):placeholder-shown + .OutlinedInput_label__3c_T3::before,
.OutlinedInput_error__2ZFax .OutlinedInput_root__1yNz7 .OutlinedInput_input__s5D_J:not(:focus):placeholder-shown + .OutlinedInput_label__3c_T3::after,
.OutlinedInput_error__2ZFax .OutlinedInput_root__1yNz7 textarea:not(:focus):placeholder-shown + .OutlinedInput_label__3c_T3::before,
.OutlinedInput_error__2ZFax .OutlinedInput_root__1yNz7 textarea:not(:focus):placeholder-shown + .OutlinedInput_label__3c_T3::after {
    border-top-color: transparent;
}

.OutlinedInput_error__2ZFax .OutlinedInput_root__1yNz7 .OutlinedInput_input__s5D_J:focus,
.OutlinedInput_error__2ZFax .OutlinedInput_root__1yNz7 textarea:focus {
    border-color: transparent #d32f2f #d32f2f;
}

.OutlinedInput_error__2ZFax .OutlinedInput_root__1yNz7 .OutlinedInput_input__s5D_J:focus + .OutlinedInput_label__3c_T3,
.OutlinedInput_error__2ZFax .OutlinedInput_root__1yNz7 textarea:focus + .OutlinedInput_label__3c_T3 {
    color: #d32f2f;
}

.OutlinedInput_error__2ZFax .OutlinedInput_root__1yNz7 .OutlinedInput_input__s5D_J:focus + .OutlinedInput_label__3c_T3::before,
.OutlinedInput_error__2ZFax .OutlinedInput_root__1yNz7 .OutlinedInput_input__s5D_J:focus + .OutlinedInput_label__3c_T3::after,
.OutlinedInput_error__2ZFax .OutlinedInput_root__1yNz7 textarea:focus + .OutlinedInput_label__3c_T3::before,
.OutlinedInput_error__2ZFax .OutlinedInput_root__1yNz7 textarea:focus + .OutlinedInput_label__3c_T3::after {
    border-top-color: #d32f2f;
}

.OutlinedInput_iconContainer__2XYBB {
    display: none;
    position: absolute;
    right: 4px;
    bottom: 4px;
    width: 42px;
    height: 42px;
    padding: 8px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50%;
    background-position: center;
    transition: background 0.8s;
}
.OutlinedInput_root__1yNz7.OutlinedInput_fieldIcon__3g1hV .OutlinedInput_iconContainer__2XYBB {
    display: flex;
}
.OutlinedInput_root__1yNz7.OutlinedInput_fieldIcon__3g1hV .OutlinedInput_icon__2JLp7 {
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 24px;
}

.OutlinedInput_iconContainer__2XYBB:hover {
    background: rgba(0, 0, 0, 0.04) radial-gradient(circle, transparent 1%, rgba(0, 0, 0, 0.04) 1%)
        center/15000%;
}

.OutlinedInput_iconContainer__2XYBB:active {
    background-color: rgba(0, 0, 0, 0.08);
    background-size: 100%;
    transition: background 0s;
}

.OutlinedInput_icon__2JLp7.OutlinedInput_password__Fk2Nw {
    background-image: url(/static/media/eye.e0b43b5e.svg);
}

.OutlinedInput_icon__2JLp7.OutlinedInput_passwordOff__V5RJV {
    background-image: url(/static/media/eye-off.2cfebb77.svg);
}

.OutlinedInput_input__s5D_J {
    -webkit-text-fill-color: #52606c !important;
    -webkit-background-clip: text !important;
    background-clip: text !important;
}

.OutlinedInput_input__s5D_J:-webkit-autofill,
.OutlinedInput_input__s5D_J:-webkit-autofill::first-line {
    font-size: 16px !important;
}

.OutlinedInput_helperText__FV3pz {
    padding: 0px 14px 0px;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    text-align: left;
    margin-top: 3px;
}

.OutlinedInput_error__2ZFax .OutlinedInput_helperText__FV3pz {
    color: #d32f2f;
}

@media (max-width: 425px) {
    .OutlinedInput_container__2W9n2 {
        margin-bottom: 16px;
    }
    .OutlinedInput_root__1yNz7 .OutlinedInput_input__s5D_J,
    .OutlinedInput_root__1yNz7 textarea {
        padding: 8px 14px;
        font-size: 13px;
    }
    .OutlinedInput_root__1yNz7 .OutlinedInput_input__s5D_J:not(:focus):placeholder-shown + .OutlinedInput_label__3c_T3,
    .OutlinedInput_root__1yNz7 .OutlinedInput_textarea__326Xp:not(:focus):placeholder-shown + .OutlinedInput_label__3c_T3,
    .OutlinedInput_root__1yNz7 .OutlinedInput_input__s5D_J:not(:focus):placeholder-shown:not(:-webkit-autofill) + .OutlinedInput_label__3c_T3 {
        line-height: 40px;
        font-size: 13px;
    }
    .OutlinedInput_iconContainer__2XYBB {
        bottom: 0px;
        width: 42px;
        height: 42px;
        padding: 8px;
    }
}

@media not all and (min-resolution: 0.001dpcm) {
    .OutlinedInput_root__1yNz7 > .OutlinedInput_input__s5D_J,
    .OutlinedInput_root__1yNz7 > .OutlinedInput_input__s5D_J + .OutlinedInput_label__3c_T3,
    .OutlinedInput_root__1yNz7 > .OutlinedInput_input__s5D_J + .OutlinedInput_label__3c_T3::before,
    .OutlinedInput_root__1yNz7 > .OutlinedInput_input__s5D_J + .OutlinedInput_label__3c_T3::after,
    .OutlinedInput_root__1yNz7 > textarea,
    .OutlinedInput_root__1yNz7 > textarea + .OutlinedInput_label__3c_T3,
    .OutlinedInput_root__1yNz7 > textarea + .OutlinedInput_label__3c_T3::before,
    .OutlinedInput_root__1yNz7 > textarea + .OutlinedInput_label__3c_T3::after {
        transition-duration: 0.1s;
    }
}

.Login_container__hE81a {
    padding: 0 24px;
}

.Login_row__1Jw0q {
    padding: 9px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.Login_justifyCenter__1rBXI {
    justify-content: center;
}

.Login_submitContainer__3rkG9 {
    margin: 16px 0;
}

.Login_text__2EZbf {
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    color: #282828;
}

.Login_header__3vcgz {
    margin-left: 24px;
}

@media screen and (min-width: 769px) {
    .Login_container__hE81a {
        padding: 0;
    }

    .Login_submitContainer__3rkG9 {
        margin: 24px 0;
    }

    .Login_submitBtn__2GeZK {
        height: 46px !important;
        font-weight: 500 !important;
        font-size: 16px !important;
        line-height: 26px !important;
    }

    .Login_text__2EZbf {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #282828;
    }

    .Login_header__3vcgz {
        margin-bottom: 77px;
        margin-left: 0;
    }
}

.Registrar_container__3jc_r {
    padding: 0px 24px 24px;
}
.Registrar_headerRegistrar__5sMmf {
    margin: 0px 0 0 24px;
}
.Registrar_reCatpcha__2c_SG {
    position: absolute;
    z-index: 1011;
}
.Registrar_formGroup__3dqZc {
    margin-bottom: 20px; /* 36 - 16 (margin input) */
}

.Registrar_onRow__2Ro-n {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.Registrar_telefonoContainer__1Iftf {
    width: 100%;
}

.Registrar_error__EJGwP {
    border: 1px solid #d32f2f !important;
    margin-bottom: 0 !important;
}

.Registrar_errorText__3oj_9 {
    padding: 0px 14px 0px;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    text-align: left;
    margin-top: 3px;
    width: 100%;
}

.Registrar_errorText__3oj_9 {
    color: #d32f2f;
}
@media (min-width: 370px) {
    .Registrar_onRow__2Ro-n {
        flex-direction: row;
        justify-content: space-between;
        grid-gap: 16px;
        gap: 16px;
    }
}
@media (min-width: 425px) {
}
.Registrar_OutlinedInput_label__1SeZB {
    font-size: 10px;
}

.Registrar_preferredLanguage__UVm9J {
    display: none;
    width: 100%;
}

.Registrar_termsCondition__2ep_6 {
    width: 100%;
    margin-left: 11px;
    color: #52606c;
}

.Registrar_termsCondition__2ep_6 a {
    color: #006ef2;
    text-decoration: underline;
}

.Registrar_termsConditionText__18Z-8 {
    display: flex;
    flex-direction: row;
}

.Registrar_submitContainer__2boCr {
    margin: 16px 0;
}

.Registrar_radioBtn__287Wv {
    position: absolute;
    left: 6px;
    width: 24px;
    height: 24px;
}

.Registrar_radioBtn__287Wv {
    background-image: url(/static/media/IconBase.911d8fa0.svg);
}

.Registrar_radioBtn__287Wv:checked {
    background-image: url(/static/media/ic_radio_on.f4a8c3c6.svg);
    background-repeat: no-repeat;
}

.Registrar_radioGroup__AAw4d {
    display: flex;
    grid-column-gap: 16px;
    column-gap: 16px;
}

.Registrar_radioContainer__-gr9z {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    height: 38px;
    border-radius: 8px;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #919eab;
    margin-bottom: 0;
}

.Registrar_radioDisabled__eaKz2 {
    display: none;
}

input {
    border-style: none;
}

/* The container */
.Registrar_radioContainer__-gr9z {
    position: relative;
    margin-bottom: 8px;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    border: 1px solid #dce0e4;
}

.Registrar_radioContainer__-gr9z input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.Registrar_checkmark__1lDYf {
    position: absolute;
    top: 50%;
    left: 10px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-image: url(/static/media/IconBase.911d8fa0.svg);
    background-size: contain;
    background-position: center;
    transform: translateY(-50%);
    transition: filter 0.8s;
}

.Registrar_radioContainer__-gr9z:hover input ~ .Registrar_checkmark__1lDYf {
    filter: brightness(1.1);
}

.Registrar_radioContainer__-gr9z input:checked ~ .Registrar_checkmark__1lDYf {
    background-image: url(/static/media/ic_radio_on.f4a8c3c6.svg);
}

.Registrar_radioContainer__-gr9z:has(input:checked) {
    border: 1px solid #006ef2;
    color: #006ef2;
}

.Registrar_tarifaInfo__P9dOK {
    display: flex;
    align-items: center;
    grid-column-gap: 8px;
    column-gap: 8px;
    margin-bottom: 24px;
    margin-left: 8px;
}
.Registrar_tarifaInfoIcon__3tM2J {
    width: 16px;
    height: 16px;
    background-image: url(/static/media/infoIcon.fcbdc5bf.svg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.Registrar_tarifaInfoText__2s-aF {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    text-align: center;
    color: #637381;
    margin: 0;
}

@media (min-width: 426px) {
    .Registrar_radioContainer__-gr9z {
        height: 46px;
        font-size: 16px;
    }
}

@media (min-width: 678px) {
    .Registrar_submitContainer__2boCr {
        margin: 24px 0;
    }
    .Registrar_onRow__2Ro-n:nth-child(3) {
        grid-column-gap: 24px;
        column-gap: 24px;
    }
    .Registrar_preferredLanguage__UVm9J {
        display: block;
    }
    .Registrar_tarifaInfoText__2s-aF {
        font-size: 16px;
    }
}

@media (min-width: 769px) {
    .Registrar_tarifaInfo__P9dOK {
        margin-bottom: 36px;
    }
    .Registrar_headerRegistrar__5sMmf {
        margin-top: 80px;
    }
    .Registrar_onRow__2Ro-n:nth-child(3) {
        flex-direction: row;
    }
    .Registrar_headerRegistrar__5sMmf {
        margin: 80px 0 0 0;
    }
    .Registrar_container__3jc_r {
        padding: 0 0 24px;
        margin-top: 0;
    }
    .Registrar_onRow__2Ro-n {
        flex-direction: row;
        grid-column-gap: 24px;
        column-gap: 24px;
    }
    .Registrar_radioGroup__AAw4d {
        grid-column-gap: 24px;
        column-gap: 24px;
    }
}

.SelectNew_selectArrow__3I_dh {
    position: absolute;
    width: 100%;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url(/static/media/drop_down_arrow.f63300bf.svg);
    background-repeat: no-repeat;
    background-position-x: calc(100% - 14px);
    background-position-y: 50%;
}
.SelectNew_container__1mFfi {
    position: relative;
    width: 100%;
}

.SelectNew_selectContainer__15tbR {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 37.5px;
    border: 1px solid #dce0e4;
    border-radius: 8px;
}
.SelectNew_noMarginBottom__1k0HD {
    margin-bottom: 0;
}
.SelectNew_marginBottom__1Z8qL {
    margin-bottom: 14px;
}
.SelectNew_error__2hDaI .SelectNew_selectContainer__15tbR {
    border: 1px solid red;
}
.SelectNew_helperText__yuB1u {
    padding: 0px 14px 0px;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    text-align: left;
    margin-top: 3px;
    width: 100%;
}

.SelectNew_error__2hDaI .SelectNew_helperText__yuB1u {
    color: #d32f2f;
}
@media (min-width: 426px) {
    .SelectNew_selectContainer__15tbR {
        height: 46px;
    }
}

.PhoneInput {
    width: 100%;
    border: 1px solid #dce0e4;
    border-radius: 8px;
    height: 38px;
    margin-top: 0px;
    padding: 0px;
    display: flex;
    align-items: center;
    margin-bottom: 14px;
}

.PhoneInputInput {
    width: 100%;
    height: 100%;
    color: #52606c;
    background-color: #fff;
    border-radius: 0 8px 8px 0;
    outline: none;
    border: none;
    font-size: 13px;
    border-left: 1px solid #dce0e4;
    padding-left: 15px;
}
.PhoneInputInput::placeholder {
    color: #919eab;
}
.PhoneInputCountry {
    width: 50px;

    justify-content: center;
    margin: 0;
}

.PhoneInputCountrySelec {
    display: flex;
    align-items: center;
}
.PhoneInputCountrySelectArrow {
    display: none;
}
@media (min-width: 426px) {
    .PhoneInput {
        height: 46px;
    }
    .PhoneInputInput {
        font-size: 16px;
    }
}
@media (min-width: 678px) {
    .PhoneInput {
        width: 97.2%;
    }
}

.RegistroPasoTres_container__258MZ {
    max-width: 100%;
    padding: 24px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
}

.RegistroPasoTres_buttonContainer__1hQYs {
    max-width: 500px;
    width: 100%;
    margin-top: 8px;
}

.RegistroPasoTres_submitBtn__2Qt2F:hover {
    cursor: pointer;
}

@media (min-width: 769px) {
    .RegistroPasoTres_container__258MZ {
        max-width: 500px;
        padding: 0;
        margin: 0;
    }

    .RegistroPasoTres_buttonContainer__1hQYs {
        margin-top: 10px;
    }
}


.RegistroPasoDos_container__1t4my {
    padding: 0px 24px 24px;
}
.RegistroPasoDos_headerRegistrar__2kZef {
    margin: 0px 0 0 24px;
}
.RegistroPasoDos_reCatpcha__JxDxb {
    position: absolute;
    z-index: 1011;
}

.RegistroPasoDos_onRow__2-89C {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.RegistroPasoDos_telefonoContainer__2BQ28 {
    width: 100%;
}

.RegistroPasoDos_error__3fJnb {
    border: 1px solid #d32f2f !important;
    margin-bottom: 0 !important;
}

.RegistroPasoDos_errorText__imIyP {
    padding: 0px 14px 0px;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    text-align: left;
    margin-top: 3px;
    width: 100%;
}

.RegistroPasoDos_errorText__imIyP {
    color: #d32f2f;
}
@media (min-width: 370px) {
    .RegistroPasoDos_onRow__2-89C {
        flex-direction: row;
        justify-content: space-between;
        grid-gap: 16px;
        gap: 16px;
    }
}
@media (min-width: 425px) {
    .RegistroPasoDos_onRow__2-89C:nth-child(3) {
        margin: -6px 0;
    }
    .RegistroPasoDos_onRow__2-89C:nth-child(6) {
        margin: 0 0 -6px;
    }
}
.RegistroPasoDos_OutlinedInput_label__17rUw {
    font-size: 10px;
}

.RegistroPasoDos_preferredLanguage__1aOYl {
    display: none;
    width: 100%;
}

.RegistroPasoDos_termsCondition__qWQVw {
    width: 100%;
    margin-left: 11px;
}

.RegistroPasoDos_termsConditionText__3uBeK {
    display: flex;
    flex-direction: row;
}

.RegistroPasoDos_submitContainer__1ju7w {
    margin: 16px 0;
}

.RegistroPasoDos_radioBtn__2mXda {
    position: absolute;
    left: 6px;
    width: 24px;
    height: 24px;
}

.RegistroPasoDos_radioBtn__2mXda {
    background-image: url(/static/media/IconBase.911d8fa0.svg);
}

.RegistroPasoDos_radioBtn__2mXda:checked {
    background-image: url(/static/media/ic_radio_on.f4a8c3c6.svg);
    background-repeat: no-repeat;
}

.RegistroPasoDos_radioGroup__2Gr1o {
    display: flex;
    grid-column-gap: 16px;
    column-gap: 16px;
}

.RegistroPasoDos_radioContainer__2ityB {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    height: 38px;
    border-radius: 8px;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #919eab;
}

.RegistroPasoDos_radioDisabled__2ZrrZ {
    display: none;
}

input {
    border-style: none;
}

/* The container */
.RegistroPasoDos_radioContainer__2ityB {
    position: relative;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    border: 1px solid #dce0e4;
}

.RegistroPasoDos_radioContainer__2ityB input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.RegistroPasoDos_checkmark__2mCc7 {
    position: absolute;
    top: 50%;
    left: 10px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-image: url(/static/media/IconBase.911d8fa0.svg);
    background-size: contain;
    background-position: center;
    transform: translateY(-50%);
    transition: filter 0.8s;
}

.RegistroPasoDos_radioContainer__2ityB:hover input ~ .RegistroPasoDos_checkmark__2mCc7 {
    filter: brightness(1.1);
}

.RegistroPasoDos_radioContainer__2ityB input:checked ~ .RegistroPasoDos_checkmark__2mCc7 {
    background-image: url(/static/media/ic_radio_on.f4a8c3c6.svg);
}

.RegistroPasoDos_radioContainer__2ityB:has(input:checked) {
    border: 1px solid #006ef2;
    color: #006ef2;
}

.RegistroPasoDos_tarifaInfo__1CB8i {
    display: flex;
    align-items: center;
    grid-column-gap: 8px;
    column-gap: 8px;
    margin-bottom: 24px;
    margin-left: 8px;
}
.RegistroPasoDos_tarifaInfoIcon__3s4ZF {
    width: 16px;
    height: 16px;
    background-image: url(/static/media/infoIcon.fcbdc5bf.svg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.RegistroPasoDos_tarifaInfoText__2A7RS {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    text-align: center;
    color: #637381;
    margin: 0;
}

@media (min-width: 424px) {
    .RegistroPasoDos_radioContainer__2ityB {
        height: 46px;
        font-size: 16px;
    }
}

@media (min-width: 678px) {
    .RegistroPasoDos_submitContainer__1ju7w {
        margin: 24px 0;
    }
    .RegistroPasoDos_onRow__2-89C:nth-child(3) {
        grid-column-gap: 24px;
        column-gap: 24px;
    }
    .RegistroPasoDos_preferredLanguage__1aOYl {
        display: block;
    }
    .RegistroPasoDos_tarifaInfoText__2A7RS {
        font-size: 16px;
    }
}

@media (min-width: 769px) {
    .RegistroPasoDos_tarifaInfo__1CB8i {
        margin-bottom: 36px;
    }
    .RegistroPasoDos_headerRegistrar__2kZef {
        margin-top: 80px;
    }
    .RegistroPasoDos_onRow__2-89C:nth-child(3) {
        flex-direction: row;
    }
    .RegistroPasoDos_headerRegistrar__2kZef {
        margin: 80px 0 0 0;
    }
    .RegistroPasoDos_container__1t4my {
        padding: 0 0 24px;
        margin-top: 0;
    }
    .RegistroPasoDos_onRow__2-89C {
        flex-direction: row;
        grid-column-gap: 24px;
        column-gap: 24px;
    }
    .RegistroPasoDos_radioGroup__2Gr1o {
        grid-column-gap: 24px;
        column-gap: 24px;
    }
}

.RegistroPasos_container__3YYc6 {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.RegistroPasos_headerRegistrar__1ozrt {
    margin: 0px 0 0 24px;
}
.RegistroPasos_stepsContent__3RDGO,
.RegistroPasos_stepsContent2__36GVx {
    display: flex;
    height: 88px;
    max-width: 500px;
    width: 100%;
    padding: 44px;
}
.RegistroPasos_stepsContent__3RDGO {
    margin: 0 0 24px 16px;
}
.RegistroPasos_stepsContent2__36GVx {
    margin: 0px 0 0;
}
ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.RegistroPasos_stepNumber__3tfDJ,
.RegistroPasos_stepNumberFill__1QSBH,
.RegistroPasos_stepNumberVoid__2z2Rb {
    min-width: 40px;
    min-height: 40px;
    position: relative;
    background-size: 100% 100%;
    margin: -0.5px;
    display: flex;
    align-self: center;
}
.RegistroPasos_stepNumber__3tfDJ:nth-child(1) {
    background-image: url(/static/media/step1.73b9be33.svg);
    background-repeat: no-repeat;
}
.RegistroPasos_stepNumber__3tfDJ:nth-child(3) {
    background-image: url(/static/media/step2.36ae6709.svg);
    background-repeat: no-repeat;
}
.RegistroPasos_stepNumberVoid__2z2Rb {
    background-image: url(/static/media/step3Void.53baa864.svg);
    background-repeat: no-repeat;
}
.RegistroPasos_stepNumberFill__1QSBH {
    background-image: url(/static/media/step3Fill.0031f44e.svg);
    background-repeat: no-repeat;
}
.RegistroPasos_stepText__3uAkR,
.RegistroPasos_stepTextVoid__1xnUG,
.RegistroPasos_stepTextFill__fIZ7f {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 24px;
    text-align: center;
    position: absolute;
    width: 105px;
    left: 50%;
    transform: translateX(-50%);
    bottom: -30px;
}
.RegistroPasos_stepText__3uAkR,
.RegistroPasos_stepTextFill__fIZ7f {
    color: #282828;
}
.RegistroPasos_stepTextVoid__1xnUG {
    color: #637381;
}
.RegistroPasos_barSeparatorFill__2nor8,
.RegistroPasos_barSeparatorVoid__1GALy,
.RegistroPasos_barSeparator__1H1D6 {
    width: 100%;
    height: 1.5px;
    display: flex;
    align-self: center;
}
.RegistroPasos_barSeparator__1H1D6 {
    border-top: 1.5px solid #006ef2;
}
.RegistroPasos_barSeparatorFill__2nor8 {
    border-top: 1.5px solid #006ef2;
}
.RegistroPasos_barSeparatorVoid__1GALy {
    border-top: 1.5px solid rgba(0, 110, 242, 0.5);
}

@media (min-width: 345px) {
    .RegistroPasos_stepText__3uAkR,
    .RegistroPasos_stepTextFill__fIZ7f,
    .RegistroPasos_stepTextVoid__1xnUG {
        font-size: 13px;
    }
    .RegistroPasos_stepsContent__3RDGO,
    .RegistroPasos_stepsContent2__36GVx {
        padding: 0 54px;
    }
    .RegistroPasos_stepsContent__3RDGO {
        margin: 0 0 16px;
    }
}
@media (min-width: 769px) {
    .RegistroPasos_headerRegistrar__1ozrt {
        margin: 0px 0 0 0;
    }
    .RegistroPasos_stepsContent__3RDGO {
        padding: 0 30px;
        margin: 0px 0 36px;
    }
    .RegistroPasos_stepsContent2__36GVx {
        padding: 0 30px;
        margin: 0 0 40px;
    }
}

.CasilleroCard_container__2BwEd {
    display: flex;
    flex-direction: column;
    padding: 24px;
    max-width: 420px;
    min-width: 355px;
    width: 100%;
    height: 553px;
    background: #fff;
    box-shadow: 0px 0px 24px -4px rgba(0, 0, 0, 0.12);

    border-radius: 10px;
}
.CasilleroCard_title__3BvD9 {
    margin-bottom: 16px;
}
.CasilleroCard_infoContainer__38O8O {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;
}
.CasilleroCard_info__1SE4h {
    margin-left: 14px;
}
.CasilleroCard_infoTitle__2qAFx {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #282828;
    margin: 0;
}
.CasilleroCard_infoSubtitle__2dPyh,
.CasilleroCard_title__3BvD9 {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #4d4d4d;
}
.CasilleroCard_infoSubtitle__2dPyh {
    margin: 0;
}
@media screen and (max-width: 1280px) {
    .CasilleroCard_container__2BwEd {
        width: 100%;
        max-width: 270px;
        min-width: 270px;
    }
}
@media screen and (max-width: 821px) {
    .CasilleroCard_container__2BwEd {
        height: 521px;
    }
    .CasilleroCard_infoTitle__2qAFx,
    .CasilleroCard_infoSubtitle__2dPyh {
        font-size: 13px;
        line-height: 15px;
    }
}

.MiDireccion_container__tyXfi {
    margin-top: 60px;

    padding: 0 20px;
}
.MiDireccion_title__1E0pm {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #4d4d4d;
    margin-bottom: 8px;
}
.MiDireccion_subtitle__1wwH7 {
    font-weight: 400;
    font-size: 16px;
    line-height: 29px;
    color: #282828;
}
.MiDireccion_cardContainer__2pEai {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px;
    margin: -16px;
}
@media screen and (max-width: 1286px) {
    .MiDireccion_cardContainer__2pEai {
        overflow-x: scroll;
    }
}
/* @media screen and (max-width: 1025px) {
    .cardContainer {
        overflow-x: scroll;
    }
} */

@media screen and (max-width: 821px) {
    .MiDireccion_container__tyXfi {
        width: 100%;
        /* padding: 0; */
    }
    .MiDireccion_title__1E0pm {
        font-size: 20px;
        line-height: 23px;
        margin-bottom: 16px;
    }

    .MiDireccion_subtitle__1wwH7 {
        display: none;
    }
}

@media screen and (max-width: 415px) {
    .MiDireccion_container__tyXfi {
        margin-top: 24.5px;
    }
}

.RelampagoCard_container__2OFrl {
    display: flex;
    flex-direction: column;
    width: calc(100% - 1px);
    max-height: 336px;
    min-height: 221px;
    height: 100%;
    /* background-color: rgb(102, 18, 18); */
    border-radius: 10px;
    padding: 48px 56px 72px 60px;
    /* padding-bottom: 24px; */
    box-sizing: border-box;
}

.RelampagoCard_container__2OFrl.RelampagoCard_premium__bOoZd {
    background: #0e2574;
    padding-bottom: 42px;
}

/* .headerContainer {
    padding-left: 60px;
    background-color: rgb(73, 36, 36);
} */

.RelampagoCard_left__1ri1h .RelampagoCard_headerContainer__36Dbn {
    padding-left: 0;
    max-width: 350px;
}

.RelampagoCard_newChip__3WX6m {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    width: 62.34px;
    height: 25.17px;
    border: 1.4px solid #006ef2;
    border-radius: 70px;
    margin-top: 13px;
    margin-bottom: 24px;
}

.RelampagoCard_premium__bOoZd .RelampagoCard_newChip__3WX6m {
    background: #ffffff;
}

.RelampagoCard_newChipText__1NlqS {
    font-weight: 400;
    font-size: 14px;
    color: #006ef2;
    text-align: center;
}

.RelampagoCard_premium__bOoZd .RelampagoCard_newChipText__1NlqS {
    color: #0e2574;
    font-weight: 500;
    margin: 0;
}

.RelampagoCard_rayo__3PvyS {
    height: 106px;
    margin: 23.64px auto 14px auto;
    max-width: 137px;
    width: 50%;
}
.RelampagoCard_left__1ri1h .RelampagoCard_rayo__3PvyS {
    margin-left: 0;
}

.RelampagoCard_premium__bOoZd .RelampagoCard_rayo__3PvyS {
    width: 139px;
    height: 139px;
    position: absolute;
    right: 0;
    top: 0;
    margin: 0;
}

.RelampagoCard_left__1ri1h .RelampagoCard_rayo__3PvyS {
    min-width: 137px;
    height: 167px;
    /* max-width: 137px; */
}
.RelampagoCard_left__1ri1h .RelampagoCard_rayo__3PvyS img {
    width: 100%;
    height: 100%;
}

.RelampagoCard_relampagoTitle__cKJKa,
.RelampagoCard_relampagoTitle2__27F0s {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    /* text-align: center; */
    color: #282828;
}

.RelampagoCard_titleRow__-9u7J {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
    margin-bottom: 20px;
}

.RelampagoCard_premiumLogo__1ufwq {
    background-image: url(/static/media/premium_logo.d43a1a12.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center -3px;
    width: 101px;
    height: 23px;
    margin-left: 6px;
}

.RelampagoCard_relampagoTitle2__27F0s {
    margin-top: 52px;
}

.RelampagoCard_left__1ri1h .RelampagoCard_relampagoTitle2__27F0s {
    margin-top: 10px;
}

.RelampagoCard_premium__bOoZd .RelampagoCard_relampagoTitle__cKJKa,
.RelampagoCard_premium__bOoZd .RelampagoCard_relampagoTitle2__27F0s {
    color: #fff;
}

.RelampagoCard_relampagoSubtitle__dov86 {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* margin-top: 20px; */
    /* margin-left: 60px;
    margin-right: 60px; */
    text-align: left;
    margin-bottom: 25px;
    color: rgba(40, 40, 40, 0.75);
}
.RelampagoCard_relampagoSubtitle2__247Vf {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* margin-top: 20px; */
    /* margin-left: 60px;
    margin-right: 60px; */
    text-align: left;
    margin-bottom: 16px;
    margin-top: -8px;
    color: rgba(40, 40, 40, 0.75);
}

.RelampagoCard_premium__bOoZd .RelampagoCard_relampagoSubtitle__dov86 {
    color: rgba(255, 255, 255, 0.85);
}

.RelampagoCard_left__1ri1h .RelampagoCard_relampagoSubtitle__dov86 {
    margin-right: 0;
}

.RelampagoCard_sinNombre__1Hj2P {
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 0;
    height: -moz-fit-content;
    height: fit-content;
    /* background-color: #006ef2; */
}

.RelampagoCard_left__1ri1h .RelampagoCard_sinNombre__1Hj2P {
    flex-direction: row-reverse;
    align-items: center;
    max-height: 165px;
    margin-bottom: 15px;
}
.RelampagoCard_left__1ri1h .RelampagoCard_sinNombreButtonIn__3LmMO {
    max-height: 100%;
}
.RelampagoCard_left__1ri1h .RelampagoCard_relampagoSubtitle__dov86 {
    margin-left: 0;
}

.RelampagoCard_relampagoTitle__cKJKa {
    margin-bottom: 0;
}

.RelampagoCard_button__J2TmP {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 271px;
    height: 40px;
    background: #006ef2;
    border-radius: 8px;
    margin-bottom: 40px;
    margin-left: auto;
    /* margin-right: 60px;
    margin-top: 23px; */
    cursor: pointer;
}
.RelampagoCard_buttonIn__3jMeD {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    background: #006ef2;
    border-radius: 8px;
    margin-bottom: 0px;
    margin-left: auto;
    cursor: pointer;
}

.RelampagoCard_premium__bOoZd .RelampagoCard_button__J2TmP {
    background: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
}

.RelampagoCard_premium__bOoZd .RelampagoCard_button__J2TmP p {
    color: #0e2574;
    font-weight: 500;
}
.RelampagoCard_button__J2TmP p,
.RelampagoCard_buttonIn__3jMeD p {
    color: #fff;
    margin: 11px 16px;
}

.RelampagoCard_chevron__1WLP9 {
    background-image: url(/static/media/chevron.35c3a2f9.svg);
    background-repeat: no-repeat;
    background-size: contain;
    width: 16px;
    height: 16px;
    /* margin: 12px 16px; */
}

.RelampagoCard_premium__bOoZd .RelampagoCard_chevron__1WLP9 {
    width: 24px;
    height: 24px;
    background-image: url(/static/media/premium_chevron.05b97a95.svg);
}

/* ------------------------- */
/* Santita Styles */

.RelampagoCard_santitaIcon__2MO6x {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 0.9px;
}

.RelampagoCard_bgImage__2JD1f {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
}

@media screen and (max-width: 450px) {
    .RelampagoCard_santitaIcon__2MO6x {
        width: 250px;
        height: 118px;
        margin-right: 0;
    }
    .RelampagoCard_bgImage__2JD1f {
        width: 142px;
        height: auto;
        margin-right: 0;
        z-index: -1;
    }
}

.RelampagoCard_santitaContainerBackground__tF0rj {
    background-color: #005249;
    border-radius: 10px;
    padding: 48px 56px 72px 60px;
    box-sizing: border-box;
    color: #fff;
}

.RelampagoCard_santitaTitle__1_70g {
    color: #fff;
    margin-bottom: 8px;
    margin-top: 75px;
}

.RelampagoCard_santitaDescriptionColor__r5zZf {
    color: #fff;
}

.RelampagoCard_santitaButton__2KtsO {
    background: #fff;
}

.RelampagoCard_santitaButton__2KtsO p {
    color: #005249;
    font-weight: 700;
    font-size: 14px;
}

/* --------------------------------- */

/* @media screen and (max-width: 1300px) {
    .container {
        min-width: unset;
        max-width: none;
        width: 520px;
        padding: 2px 0;
          background-color: #128c7e;
    }
} */

@media screen and (max-width: 1025px) {
    .RelampagoCard_rayo__3PvyS {
        margin: 23.64px 48px 13px auto;
    }
}

@media screen and (max-width: 768px) {
    .RelampagoCard_container__2OFrl {
        flex-direction: column;
        padding-inline: 16px;
    }
    .RelampagoCard_headerContainer__36Dbn {
        width: 100%;
    }
    .RelampagoCard_titleRow__-9u7J {
        margin: 0;
    }
    .RelampagoCard_relampagoTitle__cKJKa,
    .RelampagoCard_relampagoTitle2__27F0s {
        text-align: left;
    }

    .RelampagoCard_rayo__3PvyS {
        margin: 23.64px 43px 13px auto;
    }

    .RelampagoCard_button__J2TmP {
        margin-top: 20px;
        width: 100%;
    }
}

@media screen and (max-width: 450px) {
    .RelampagoCard_container__2OFrl {
        padding-bottom: 0px;
        width: 100%;
    }
    .RelampagoCard_premiumLogo__1ufwq {
        width: 69.28px;
        height: 23px;
    }

    .RelampagoCard_rayo__3PvyS {
        margin: 12px 10px 13px auto;
    }

    .RelampagoCard_relampagoTitle__cKJKa,
    .RelampagoCard_relampagoTitle2__27F0s {
        font-size: 16px;
        line-height: 19px;
        font-weight: 600;
    }

    .RelampagoCard_relampagoSubtitle__dov86 {
        font-weight: 400;
        font-size: 13px;
        line-height: 125%;
        margin: 0;
    }
    .RelampagoCard_relampagoSubtitle2__247Vf {
        font-weight: 400;
        font-size: 13px;
        line-height: 125%;
        margin-top: 16px;
        margin-bottom: 0px;
    }

    /* .relampagoSubtitle {
        margin-left: 32px;
    } */

    .RelampagoCard_left__1ri1h .RelampagoCard_sinNombre__1Hj2P {
        padding: 0 24px;
        margin-bottom: 0;
    }

    .RelampagoCard_left__1ri1h .RelampagoCard_relampagoTitle2__27F0s {
        margin-top: 24px;
    }

    .RelampagoCard_left__1ri1h .RelampagoCard_headerContainer__36Dbn {
        padding-left: 18px;
    }

    .RelampagoCard_premium__bOoZd .RelampagoCard_relampagoTitle__cKJKa,
    .RelampagoCard_premium__bOoZd .RelampagoCard_relampagoTitle2__27F0s {
        margin-bottom: 8px;
    }

    .RelampagoCard_referido__298Nz .RelampagoCard_rayo__3PvyS {
        position: absolute;
        margin: 0 0 0 auto;
        float: right;
        right: 32px;
        /* top: -11px; */
    }
    .RelampagoCard_premium__bOoZd .RelampagoCard_rayo__3PvyS {
        position: absolute;
        margin: 0 0 0 auto;
        width: 87.44px;
        height: 87.44px;
        right: 0;
    }

    .RelampagoCard_referido__298Nz .RelampagoCard_button__J2TmP {
        margin-top: 40px;
    }

    .RelampagoCard_left__1ri1h .RelampagoCard_rayo__3PvyS {
        width: 70px;
        height: 85px;
        min-width: 70px;
        margin: 0;
    }

    .RelampagoCard_button__J2TmP {
        margin-top: 12px;
        margin-bottom: 40px;
    }
    .RelampagoCard_left__1ri1h .RelampagoCard_button__J2TmP {
        margin-top: 40px;
    }
}

@media screen and (max-width: 353px) {
    .RelampagoCard_button__J2TmP {
        width: 252px;
    }
}

.TaxExemption_container__3gRHe {
    position: relative;
    width: 100%;
}

.TaxExemption_modalTitle__9etUX {
    position: absolute;
    top: -16px;
    left: 0;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.02em;
    color: #4d4d4d;
    margin: 0;
}
.TaxExemption_modalStepsTitle__1h2Eq {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.02em;
    color: #4d4d4d;
    margin: 0 0 16px;
}
.TaxExemption_logoContainer__2wieU {
    display: flex;
    justify-content: center;
}
.TaxExemption_logo__1dhu6 {
    display: inline-block;
    width: 203.27px;
    height: 208.01px;
    margin: 29px 0 16px;
}
.TaxExemption_listContainer__2Tpvc {
    display: flex;
    flex-direction: column;
    grid-row-gap: 16px;
    row-gap: 16px;
    margin-bottom: 24px;
}
.TaxExemption_bullet__vTBTu {
    min-width: 5px;
    min-height: 5px;
    background-image: url(/static/media/Punto.eccf4f3b.svg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 8px 8px 0;
    align-self: flex-start;
}
.TaxExemption_listItem__eszRU {
    font-weight: 400;
    font-size: 13px;
    line-height: 150%;
    color: #52606c;
}
.TaxExemption_listItem__eszRU {
    font-weight: 400;
    font-size: 13px;
    line-height: 150%;
    color: #52606c;
    display: flex;
    align-items: center;
}
.TaxExemption_listItemLink__3Ro3f {
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: #006ef2;
    margin-right: 4px;
}
.TaxExemption_btnsContainer__3PayM {
    display: flex;
}
.TaxExemption_btnDescargar__2mGpB {
    padding: 10px 20px;
    grid-gap: 8px;
    gap: 8px;
    width: 158.5px;
    height: 40px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 13px;
    line-height: 12px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #006ef2;
    background: transparent;
    border: none;
    width: 100%;
}
@media (min-width: 570px) {
    .TaxExemption_modalTitle__9etUX {
        top: -8px;
        left: -8px;
        font-size: 25px;
        line-height: 29px;
    }
    .TaxExemption_logo__1dhu6 {
        margin: 64px 0 37px;
        width: 257px;
        height: 263px;
    }
    .TaxExemption_modalStepsTitle__1h2Eq {
        margin-left: -8px;
    }
    .TaxExemption_listContainer__2Tpvc {
        margin-left: -8px;
    }
    .TaxExemption_listItem__eszRU {
        font-size: 16px;
    }
    .TaxExemption_bullet__vTBTu {
        margin-top: 10px;
    }
    .TaxExemption_listItemLink__3Ro3f {
        margin-right: 5px;
    }
    .TaxExemption_btnDescargar__2mGpB {
        font-size: 15px;
        line-height: 12px;
    }
}

.Carousel_slidesContainer__uFFlZ {
    max-width: 100%;
}

/********************************************
	BREAKPOINT WIDTHS
********************************************/
/********************************************
	FONTS
********************************************/
/********************************************
	COLOURS
********************************************/
.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
    transition: all 0.25s ease-in;
    opacity: 0.4;
    filter: alpha(opacity=40);
    position: absolute;
    z-index: 2;
    top: 20px;
    background: none;
    border: 0;
    font-size: 32px;
    cursor: pointer;
}

.carousel .control-arrow:focus,
.carousel .control-arrow:hover {
    opacity: 1;
    filter: alpha(opacity=100);
}

.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
    margin: 0 5px;
    display: inline-block;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    content: "";
}

.carousel .control-disabled.control-arrow {
    opacity: 0;
    filter: alpha(opacity=0);
    cursor: inherit;
    display: none;
}

.carousel .control-prev.control-arrow {
    left: 0;
}

.carousel .control-prev.control-arrow:before {
    border-right: 8px solid #fff;
}

.carousel .control-next.control-arrow {
    right: 0;
}

.carousel .control-next.control-arrow:before {
    border-left: 8px solid #fff;
}

.carousel-root {
    outline: none;
}

.carousel {
    position: relative;
    width: 100%;
}

.carousel * {
    box-sizing: border-box;
}

.carousel img {
    width: 100%;
    display: inline-block;
    pointer-events: none;
}

.carousel .carousel {
    position: relative;
}

.carousel .control-arrow {
    outline: 0;
    border: 0;
    background: none;
    top: 50%;
    margin-top: -13px;
    font-size: 18px;
}

.carousel .thumbs-wrapper {
    margin: 20px;
    overflow: hidden;
}

.carousel .thumbs {
    transition: all 0.15s ease-in;
    transform: translate3d(0, 0, 0);
    position: relative;
    list-style: none;
    white-space: nowrap;
}

.carousel .thumb {
    transition: border 0.15s ease-in;
    display: inline-block;
    margin-right: 6px;
    white-space: nowrap;
    overflow: hidden;
    border: 3px solid #fff;
    padding: 2px;
}

.carousel .thumb:focus {
    border: 3px solid #ccc;
    outline: none;
}

.carousel .thumb.selected,
.carousel .thumb:hover {
    border: 3px solid #333;
}

.carousel .thumb img {
    vertical-align: top;
}

.carousel.carousel-slider {
    position: relative;
    margin: 0;
    overflow: hidden;
}

.carousel.carousel-slider .control-arrow {
    top: 0;
    color: #fff;
    font-size: 26px;
    bottom: 0;
    margin-top: 0;
    padding: 5px;
}

.carousel.carousel-slider .control-arrow:hover {
    background: rgba(0, 0, 0, 0.2);
}

.carousel .slider-wrapper {
    overflow: hidden;
    margin: auto;
    width: 100%;
    transition: height 0.15s ease-in;
}

.carousel .slider-wrapper.axis-horizontal .slider {
    -ms-box-orient: horizontal;
    display: -moz-flex;
    display: flex;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide {
    flex-direction: column;
    flex-flow: column;
}

.carousel .slider-wrapper.axis-vertical {
    -ms-box-orient: horizontal;
    display: -moz-flex;
    display: flex;
}

.carousel .slider-wrapper.axis-vertical .slider {
    flex-direction: column;
}

.carousel .slider {
    margin: 0;
    padding: 0;
    position: relative;
    list-style: none;
    width: 100%;
}

.carousel .slider.animated {
    transition: all 0.35s ease-in-out;
}

.carousel .slide {
    min-width: 100%;
    margin: 0;
    position: relative;
    text-align: left;
}

.carousel .slide img {
    width: 100%;
    vertical-align: top;
    border: 0;
}

.carousel .slide iframe {
    display: inline-block;
    width: calc(100% - 80px);
    margin: 0 40px 40px;
    border: 0;
}

.carousel .slide .legend {
    transition: all 0.5s ease-in-out;
    position: absolute;
    bottom: 40px;
    left: 50%;
    margin-left: -45%;
    width: 90%;
    border-radius: 10px;
    background: #000;
    color: #fff;
    padding: 10px;
    font-size: 12px;
    text-align: center;
    opacity: 0.25;
    transition: opacity 0.35s ease-in-out;
}

.carousel .control-dots {
    position: absolute;
    bottom: 0;
    margin: 10px 0;
    padding: 0;
    text-align: center;
    width: 100%;
    z-index: 1;
}

@media (min-width: 960px) {
    .carousel .control-dots {
        bottom: 0;
    }
}

.carousel .control-dots .dot {
    transition: opacity 0.25s ease-in;
    opacity: 0.3;
    filter: alpha(opacity=30);
    /* box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9); */
    background-color: #6195d4;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    cursor: pointer;
    display: inline-block;
    margin: 0 8px;
}

.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
    opacity: 1;
    filter: alpha(opacity=100);
    background-color: #006ef2;
}

/* Estilos específicos para el Santita card */
.carousel.whiteDots .control-dots .dot {
    background-color: #ffffff;
}

.carousel.whiteDots .control-dots .dot.selected,
.carousel.whiteDots .control-dots .dot:hover {
    background-color: #ffffff;
}

.carousel .carousel-status {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    font-size: 10px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
    color: #fff;
}

.carousel:hover .slide .legend {
    opacity: 1;
}

@media (max-width: 768px) {
    .carousel .control-dots {
        bottom: 5px;
    }
}

@media screen and (max-width: 415px) {
    .carousel .control-dots {
        bottom: -4px;
    }
}

.SucursalesCard_container__3h_6y {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #fff;
    border-radius: 10px;
    justify-content: space-between;
    padding: 40px 60px;
    box-sizing: border-box;
}

.SucursalesCard_sucursalName__1XErg {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #282828;
}

.SucursalesCard_sucursalDireccion__3u3bG {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #4d4d4d;
}

.SucursalesCard_parent__2-110 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 4px;
    padding: 0 80px;
    margin: 0;
}

.SucursalesCard_parent__2-110 p {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: rgba(40, 40, 40, 0.75);
    margin: 0;
}

.SucursalesCard_btnContainer__24hmI {
    display: flex;
    flex-direction: row;
    grid-gap: 24px;
    gap: 24px;
    justify-content: center;
    margin-top: 16px;
}

.SucursalesCard_locationBtn__3u3pP {
    flex: 1 1;
    display: flex;
    flex-direction: row;
    height: 40px;
    border: 1px solid rgba(212, 212, 212, 0.7);
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    padding: 10.5px 16px;
}

.SucursalesCard_locationIcon__3PtuW {
    background-image: url(/static/media/location.b6b32da3.svg);
    background-repeat: no-repeat;
    background-size: contain;
    width: 11px;
    height: 16px;
    margin-right: 8px;
}

.SucursalesCard_btnTxt1__F4t20 {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    align-content: center;
    color: #4d4d4d;
    margin-top: 16px;
    padding: 0;
}

.SucursalesCard_waBtn__3KHXx {
    height: 40px;
    background: #128c7e;
    border: 1px solid rgba(212, 212, 212, 0.7);
    border-radius: 8px;
    display: flex;
    flex: 1 1;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 12px 20px;
}

.SucursalesCard_waIcon__19HzA {
    background-image: url(/static/media/whatsapp.6320cd31.svg);
    background-repeat: no-repeat;
    background-size: contain;
    width: 16px;
    height: 16px;
    margin-right: 5px;
}

.SucursalesCard_btnTxt2__16lVy {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #f8f8f8;
    margin-top: 16px;
}

@media screen and (max-width: 1300px) {
    .SucursalesCard_container__3h_6y {
        padding: 30px 40px;
    }

    .SucursalesCard_parent__2-110 {
        padding: 0 46px;
    }

}

@media screen and (max-width: 1025px) {
    .SucursalesCard_container__3h_6y {
        padding-left: 20px;
        padding-right: 20px;
    }

    .SucursalesCard_parent__2-110 {
        padding: 0 96px;
    }

}

@media screen and (max-width: 821px) {

    .SucursalesCard_parent__2-110 {
        padding: 0 40px;
    }

    .SucursalesCard_waBtn__3KHXx {
        padding: 12px 40px;
    }
}

@media screen and (max-width: 500px) {
    .SucursalesCard_sucursalName__1XErg {
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 24px;
    }
}

@media screen and (max-width: 415px) {
    .SucursalesCard_container__3h_6y {
        padding: 24px 16px;
    }

    .SucursalesCard_parent__2-110 {
        padding: 0 36px;
    }

    .SucursalesCard_btnTxt1__F4t20,
    .SucursalesCard_btnTxt2__16lVy {
        font-size: 13px;
        line-height: 16px;
    }

    .SucursalesCard_sucursalDireccion__3u3bG {
        font-size: 13px;
        line-height: 15px;
    }

    .SucursalesCard_parent__2-110 p {
        font-size: 13px;
        line-height: 15px;
    }

    .SucursalesCard_waBtn__3KHXx {
        padding: 12px 28px;
    }

}

@media screen and (max-width: 376px) {
    .SucursalesCard_waBtn__3KHXx {
        padding: 12px 15px;
    }

    .SucursalesCard_parent__2-110 {
        padding: 0 19px;
    }
}

.NewHome_container__2tY71 {
    max-width: 1390px;
    margin: 0 auto;
    padding: 69px 32px 69px 40px;
    background-image: url(/static/media/background.a57bebb1.svg);
    background-repeat: no-repeat;
    background-position: right top;
    background-size: auto;
    padding-bottom: 69px !important;
}

.NewHome_container__2tY71.NewHome_widget__1-oJi {
    background-image: url(/static/media/background-widget-1.64d68a18.svg);
    background-position: right -15px top 23vh;
}

.NewHome_cardContainer__1JgJi {
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    gap: 10px;
}

.NewHome_relampago__1nMdK {
    position: relative;
    flex: 1 1;
    overflow: hidden;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #fff;
}

.NewHome_relampago__1nMdK:after {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background-image: url(/static/media/bluepoints-desktop.1bd425bc.svg);
    background-position: center top;
    background-repeat: no-repeat;
    background-size: auto;
    top: -70px;
    left: 18vw;
    z-index: -1;
}

@media screen and (max-width: 1025px) {
    .NewHome_container__2tY71 {
        justify-content: center;
        padding: 69.37px 40px;
    }

    .NewHome_cardContainer__1JgJi {
        flex-direction: column;
        justify-content: center;
        grid-gap: 16px;
        gap: 16px;
    }
}

@media screen and (max-width: 821px) {
    .NewHome_container__2tY71 {
        background-image: none;
        width: 100%;
    }

    .NewHome_container__2tY71.NewHome_widget__1-oJi {
        background-image: none;
    }

    .NewHome_cardContainer__1JgJi {
        padding: 0;
    }

    .NewHome_relampago__1nMdK:after {
        background-image: url(/static/media/bluepoints-2.d39a9539.svg);
        background-size: cover;
        top: -18px;
        left: 10px;
        z-index: -1;
    }
}

@media screen and (max-width: 450px) {
    .NewHome_container__2tY71 {
        padding: 24px 16px;
    }

    .NewHome_cardContainer__1JgJi {
        flex-direction: column;
        overflow-x: unset;
    }
}
/* *resets */
.RecoverPassword_title__3sHP9,
.RecoverPassword_subtitle__1PzPa {
    margin-bottom: 0;
    font-weight: 0;
    line-height: 0;
}

/* *base styles */

.RecoverPassword_body__bkmq6 {
    width: 100%;
    box-sizing: border-box;
}

.RecoverPassword_toast__1ntVa {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #4d4d4d;
}

.RecoverPassword_regresar__1tSYl {
    display: flex;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    margin-right: auto;
    margin-top: 17px;
}

/* *fonts */
.RecoverPassword_title__3sHP9 {
    font-weight: 700;
    line-height: 30px;
    color: var(--title);
}

.RecoverPassword_subtitle__1PzPa {
    font-weight: 400;
    line-height: 24px;
    color: var(--light-gray);
    margin-bottom: 24px;
}

/* *movil */
@media screen and (min-width: 325px) {
    .RecoverPassword_title__3sHP9 {
        font-size: 24px;
        margin-top: 30px;
    }

    .RecoverPassword_subtitle__1PzPa {
        font-size: 13px;
        margin-top: 8px;
    }
}

/* *ipad */

@media screen and (min-width: 769px) {
    .RecoverPassword_title__3sHP9 {
        line-height: 36px;
    }

    .RecoverPassword_subtitle__1PzPa {
        font-size: 16px;
    }
}

/* *desktop */

/* *resets */
.PasswordLayout_container__2w9er,
.PasswordLayout_childContainer__1Xi-K {
    margin: 0;
    padding: 0;
    border: 0;
}

/* *base styles */
.PasswordLayout_container__2w9er,
.PasswordLayout_headerContainer__1yip8 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
}

.PasswordLayout_childContainer__1Xi-K {
    align-self: baseline;
}

.PasswordLayout_candado__IzqFw {
    background-image: url(/static/media/lock.b8c4ac3b.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 250px;
    height: 225px;
}

/* *fonts */

/* *movil */
@media screen and (min-width: 325px) {
    .PasswordLayout_container__2w9er {
        padding: 57px 24px;
    }

    .PasswordLayout_header__2MJTu {
        justify-self: left;
        width: 100%;
    }

    .PasswordLayout_childContainer__1Xi-K {
        width: 100%;
    }
}

/* *ipad */
/* *desktop */
@media screen and (min-width: 769px) {
    .PasswordLayout_headerContainer__1yip8,
    .PasswordLayout_childContainer__1Xi-K {
        padding: 0;
        width: 373px;
    }

    .PasswordLayout_childContainer__1Xi-K {
        align-self: unset;
    }

    .PasswordLayout_container__2w9er {
        margin: 120px 0;
    }

    .PasswordLayout_candado__IzqFw {
        order: -1;
    }
}

/* *resets */
.NewPassword_title__3TUHc,
.NewPassword_subtitle__2OlRs {
    margin-bottom: 0;
    font-weight: 0;
    line-height: 0;
}

/* *base styles */

.NewPassword_body__1OkJ0 {
    width: 100%;
    box-sizing: border-box;
}

.NewPassword_regresar__2asuR {
    display: flex;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    margin-right: auto;
    margin-top: 17px;
}

/* *fonts */
.NewPassword_title__3TUHc {
    font-weight: 700;
    line-height: 30px;
    color: var(--title);
}

.NewPassword_subtitle__2OlRs {
    font-weight: 400;
    line-height: 24px;
    color: var(--light-gray);
}

/* *movil */
@media screen and (min-width: 325px) {
    .NewPassword_title__3TUHc {
        font-size: 24px;
        margin: 30px 0;
    }

    .NewPassword_subtitle__2OlRs {
        font-size: 13px;
        margin-top: 8px;
    }

    .NewPassword_inputContainer__8USqm {
        width: 100%;
    }
}

/* *ipad */
/* *desktop */
@media screen and (min-width: 769px) {
    .NewPassword_title__3TUHc {
        line-height: 36px;
    }

    .NewPassword_subtitle__2OlRs {
        font-size: 16px;
    }
}

:root {
    --yellowRNB: #fdc000;
    --blueRNB: #006ef2;
    --grayRNB: #6e6e78;
    --grayRNB2: #4d4d4d;
    --RNB-radius: 8px;
    --MAX-WIDTHRNB: 1500px;
}

a:hover {
    text-decoration: none !important;
}

.web {
    display: block;
}
.mobile {
    display: none;
}
.grecaptcha-badge {
    visibility: hidden;
}
@media (max-width: 690px) {
    .web {
        display: none;
    }
    .mobile {
        display: block;
    }
}

body {
    overflow: auto !important;
    overflow-x: hidden !important;
    font-size: 15px !important;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

main {
    width: 100%;
    font-family: Roboto;
    color: var(--grayRNB2);
}

.BeneficiosCard_card__2WfaM {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    padding: 25px 32px;
    min-width: 266.53px;
    width: 30.792%;
    height: 242px;
}
.BeneficiosCard_card__2WfaM h3 {
    margin: 0;
    font-family: "Roboto";
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
}
.BeneficiosCard_card__2WfaM p {
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    margin: 0;
}
.BeneficiosCard_cardIcon__36mds {
    background-repeat: no-repeat;
    background-position: center;
    background-color: #0E2777;
    border-radius: 1000px;
    width: 64px;
    height: 64px;
    flex-shrink: 0;
}
.BeneficiosCard_icono1__1IW9_ {
    background-image: url(/static/media/truck-fast.69c2f744.svg);
}
.BeneficiosCard_icono2__kyM9d {
    background-image: url(/static/media/discount-shape.77f040db.svg);
}
.BeneficiosCard_icono3__3DkGs {
    background-image: url(/static/media/clock.b3c99755.svg);
}
.BeneficiosCard_icono4__8Glug {
    background-image: url(/static/media/wallet-add.cbc20812.svg);
}
.BeneficiosCard_icono5__BEgbi {
    background-image: url(/static/media/truck-fast.69c2f744.svg);
}
@media screen and (max-width: 820px) {
    .BeneficiosCard_card__2WfaM {
        grid-gap: 0;
        gap: 0;
        height: -moz-fit-content;
        height: fit-content;
    }
    .BeneficiosCard_card__2WfaM h3 {
        font-size: 20px;
        margin-block: 10px;
    }
}
@media screen and (max-width: 710px) {
    .BeneficiosCard_card__2WfaM {
        max-width: 343px;
        width: 100%;
    }
}

.Beneficios_beneficiosMainContainer__2p6MY{
  display: flex;
  flex-direction: column;
  grid-gap:16px;
  gap:16px;
}
.Beneficios_title__2YS_A{
  margin: 0;
}
.Beneficios_cardsContainer__2I9kx{
  max-width: 924px;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 32px;
  gap: 32px;
  justify-content: center;
}
@media screen and (max-width:820px) {
  .Beneficios_beneficiosMainContainer__2p6MY{
    padding-block-start: 24px;
  }
  .Beneficios_title__2YS_A{
  text-align: center;
  }
  .Beneficios_cardsContainer__2I9kx{
    grid-gap: 0;
    gap: 0;
  }
}
@media screen and (max-width:710px) {
  .Beneficios_title__2YS_A{
    font-size: 25px;
  }
}
.Accordion_accordionCollapsed__10mgk,
.Accordion_accordion__38f62 {
    position: relative;
    max-width: 924px;
    width: 100%;
    background: #f5f7fa;
}

.Accordion_accordionCollapsed__10mgk {
}
.Accordion_accordion__38f62 {
    /* box-shadow: 0px 12px 24px -4px rgba(145, 158, 171, 0.12); */
    border-radius: 8px;
}

.Accordion_accordionTitle__1g9a_ {
    font-weight: 600;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px 8px 12px 16px;
    cursor: pointer;
}
.Accordion_arrow__20JFo,
.Accordion_arrowColapsed__3j5RI {
    margin: auto 4px;
    background-image: url(/static/media/accodion-arrow.b85499ef.svg);
    width: 11.69px;
    height: 5.84px;
    transition: transform 0.3s ease-in-out;
}
.Accordion_arrow__20JFo {
    transform: rotate(180deg);
    transition: transform 0.3s ease-in-out;
}
.Accordion_accordionContentColapsed__ntiOn {
    font-size: 16px;
    background-color: #f5f7fa;
    height: auto;
    max-height: 0px;
    overflow: hidden;
    padding: 0px 8px 0px 16px;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
}
.Accordion_accordionContent__UFUAy {
    font-size: 16px;
    background-color: #f5f7fa;
    height: auto;
    max-height: 999px;
    padding: 0px 8px 8px 16px;
    transition: max-height 0.5s ease-in-out;
}
.Accordion_marginBottomCollapsed__OvwS0 {
    height: 0;
    transition: all 0.2s ease-in-out;
}
.Accordion_marginBottom__1fkTN {
    height: 16px;
    transition: all 0.2s ease-in-out;
}
.Accordion_link__2q3O7 {
    color: #0d2675;
}
@media screen and (max-width: 455px) {
    .Accordion_accordionTitle__1g9a_ {
        font-weight: 500;
    }
}

.PreguntasFrecuentes_mainContainer__RskJU{
  max-width:924px;
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 32px;
  gap: 32px;
  margin-block-end: 53px;
}
.PreguntasFrecuentes_mainContainer__RskJU h2{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  margin: 0;
}
.PreguntasFrecuentes_accordionContainer__1LnXR{
  border-radius: 8px;
  overflow: hidden;
}
@media screen and (max-width:820px) {
  .PreguntasFrecuentes_mainContainer__RskJU{
    grid-gap: 24px;
    gap: 24px;
  }
  .PreguntasFrecuentes_mainContainer__RskJU h2{
  text-align: center;
  font-size: 25px;
  margin: 0;
  }
}
.MainCard_mainContainer__2aqnf {
    display: grid;
    padding: 24px 32px;
    max-width: 924px;
    width: 100%;
    height: 207px;
    border-radius: 10px;
    box-shadow: 0px 12px 24px -4px #ebeff3;
    background-color: #0d2675;
    color: #fff;
}
.MainCard_headerLogo__fTamA {
    height: 48px;
    width: 210px;
    margin-bottom: 32px;
    align-self: flex-end;
    background-image: url(/static/media/PremiumLogo.7d9ac542.svg);

    background-repeat: no-repeat;
    background-position: left;
    background-size: contain;
}
.MainCard_pContainer__1HR9t {
    grid-row: 2;

    max-width: 457px;
    width: 100%;
}
.MainCard_pContainer__1HR9t p {
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 125%;
}
.MainCard_button__2OBo_,
.MainCard_buttonConfig__1WXKn {
    grid-column: 2;
    margin: auto 0 0 auto;

    cursor: pointer;
    display: flex;
    place-content: center;
    align-items: center;
    width: 183px;
    height: 48px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #0d2675;
    background: #ffff;
    border-radius: 8px;
    transition: background-color 0.3s;
}
.MainCard_button__2OBo_:hover,
.MainCard_buttonConfig__1WXKn:hover {
    filter: brightness(0.9);
    transition: filter 0.3s;
    color: #0d2675;
    cursor: pointer;
}
.MainCard_buttonConfig__1WXKn {
    width: 272px;
}
.MainCard_configIcon__1gjNl {
    background-image: url(/static/media/setting-2.87620e79.svg);
    background-repeat: no-repeat;
    background-position: center;
    width: 24px;
    height: 24px;
    margin: 0 9.23px 0 0;
}
@media screen and (max-width: 771px) {
    .MainCard_mainContainer__2aqnf {
        height: -moz-fit-content;
        height: fit-content;
    }
}
@media screen and (max-width: 710px) {
    .MainCard_mainContainer__2aqnf {
        flex-direction: column;
        padding: 36px 4.75% 36px 4.75%;
        width: 100%;
        max-height: -moz-fit-content;
        max-height: fit-content;
    }

    .MainCard_headerLogo__fTamA {
        height: 24px;
        width: 100%;
        margin-bottom: 0;
    }
    .MainCard_pContainer__1HR9t p {
        font-size: 16px;
        margin: 8px 0 11px 0;
    }
    .MainCard_diamond__16wIe,
    .MainCard_diamondLottie__1fPFd {
        grid-column: 1;
        grid-row: 1;
        margin: 0 auto;
    }
    .MainCard_pContainer__1HR9t {
        padding: 8px 0px;
    }
    .MainCard_button__2OBo_,
    .MainCard_buttonConfig__1WXKn {
        grid-column: 1;
        width: 100%;
        padding: 0;
        max-height: 40px;
        margin: 0;
    }
    .MainCard_diamondLottie__1fPFd {
        margin: 0 auto;
    }
}

.MainPremium_premiumMainContainer__3NwwE{
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-gap: 60px;
  gap: 60px;
  padding: 80px 15.776% 0 15.776% ;
}
@media screen and (max-width:970px) {
  .MainPremium_premiumMainContainer__3NwwE{padding: 32px 4.27% 0 4.27% ;
    grid-gap: 24px;
    gap: 24px;
  }
}
@media screen and (max-width:1350px) {
  .MainPremium_premiumMainContainer__3NwwE{padding: 32px 10% 0 10% ;
  }
}
@media screen and (max-width:1162px) {
  .MainPremium_premiumMainContainer__3NwwE{padding: 32px 7% 0 7% ;
  }
}
@media screen and (max-width:1088px) {
  .MainPremium_premiumMainContainer__3NwwE{padding: 32px 5% 0 5% ;
  }
}
.UnsuscribeModal_header__poDmW {
    padding: 43px 45px 0 31px;
    display: flex;
    justify-content: space-evenly;
    margin-block-end: 20px;
    background: linear-gradient(180deg, #ecf1f9 0%, rgba(245, 247, 250, 0) 100%);
}
.UnsuscribeModal_headerIlustration__1n_-g {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 38%, rgb(255, 255, 255)),
        url(/static/media/Peronsaje-en-arte.e5158f02.svg);
    background-repeat: no-repeat;
    background-position: center;
    width: 170.84px;
    height: 177.08px;
    margin-block-start: 10px;
}
.UnsuscribeModal_headerTitle__3m_G4 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    line-height: 1;
    font-size: 45px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #282828;
    margin-block-end: 15px;
}
.UnsuscribeModal_headerSubtitle__KG_uq {
    width: 245.26px;
    height: 48.25px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #282828;
}
.UnsuscribeModal_body__mSqe_ {
    padding: 0 24px;
}
.UnsuscribeModal_bodyTitle__2scw0 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    margin-block-end: 24px;
    color: #282828;
}
.UnsuscribeModal_bodyP__2CNGd {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #52606c;
    margin-block-end: 16px;
}
.UnsuscribeModal_bodyBenefits__2JaAX {
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
}
.UnsuscribeModal_bodyBenefits__2JaAX li {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    list-style: url(/static/media/Ellipse-1.d8f2508a.svg);
    margin-inline-start: 21px;
    color: #52606c;
}
.UnsuscribeModal_bodyBenefits__2JaAX li span {
    font-weight: 600;
}
.UnsuscribeModal_buttonContainer__1xouR {
    padding: 0 24px 24px 24px;
}

@media screen and (max-width: 488px) {
    .UnsuscribeModal_header__poDmW {
        padding: 42px 38px 0 38px;
    }
    .UnsuscribeModal_headerTitle__3m_G4 {
        margin-block-start: 5px;
        font-size: 38px;
    }
    .UnsuscribeModal_headerSubtitle__KG_uq {
        width: 159.43px;
        height: 31.36px;
        font-size: 14px;
    }
    .UnsuscribeModal_headerIlustration__1n_-g {
        width: 162.53px;
        height: 138.88px;
        background-position: top;
        background-image: linear-gradient(
                to bottom,
                rgba(255, 255, 255, 0) 59%,
                rgba(255, 255, 255, 0.854)
            ),
            url(/static/media/Peronsaje-en-arte.e5158f02.svg);
    }
    /* .button {
        width: 327px;
        height: 48px;
        color: #919eab;
    } */
    .UnsuscribeModal_bodyTitle__2scw0,
    .UnsuscribeModal_bodyP__2CNGd,
    .UnsuscribeModal_bodyBenefits__2JaAX li {
        font-size: 13px;
    }
}
@media screen and (max-width: 398px) {
    .UnsuscribeModal_header__poDmW {
        padding: 42px 25px 0 25px;
    }
    .UnsuscribeModal_bodyBenefits__2JaAX li {
        margin-inline-end: 21px;
    }
}

@media (min-width: 500px) {
    .UnsuscribeModal_headerTitle__3m_G4 {
        white-space: nowrap;
        width: 200px;
    }
}

.TarjetaModal_body__zzndL {
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
    overflow-y: scroll;
    max-height: 265px;
}
/* ===== Scrollbar CSS ===== */
/* Firefox */
.TarjetaModal_body__zzndL {
    scrollbar-width: thin;
    scrollbar-color: #c7c7c7 #ffffff;
}

/* Chrome, Edge, and Safari */
.TarjetaModal_body__zzndL::-webkit-scrollbar {
    width: 15px;
}

.TarjetaModal_body__zzndL::-webkit-scrollbar-track {
    background: #ffffff;
}

.TarjetaModal_body__zzndL::-webkit-scrollbar-thumb {
    background-color: #c7c7c7;
    border-radius: 10px;
    border: 6px solid #ffffff;
}
.TarjetaModal_card__378hy {
    border: 1px solid rgba(212, 212, 212, 0.5);
    border-radius: 10px;
    width: 100%;
    height: 76px;
    display: flex;
    align-items: center;
    padding: 16px 24px;
    grid-gap: 12px;
    gap: 12px;
}
.TarjetaModal_cardActive__2pVZQ {
    border: 1.5px solid #006EF2;
    border-radius: 10px;
    width: 100%h;
    height: 80px;
    display: flex;
    align-items: center;
    padding: 16px 24px;
    grid-gap: 12px;
    gap: 12px;
}
.TarjetaModal_cardActive__2pVZQ .TarjetaModal_circleRadio__3lts9 {
    border: 1.5px solid #006EF2;
    display: grid;
    place-content: center;
}
.TarjetaModal_cardActive__2pVZQ .TarjetaModal_circleRadio__3lts9::after {
    content: "";
    background: #006EF2;
    width: 8px;
    height: 8px;
    border-radius: 100px;
    display: block;
}
.TarjetaModal_cardMainContainer__2-xfZ {
    display: flex;
    align-items: center;
    grid-gap: 12px;
    gap: 12px;
}
.TarjetaModal_circleRadio__3lts9 {
    margin-left: auto;
    border: 1.5px solid #d4d4d4;
    width: 16px;
    height: 16px;
    border-radius: 100px;
}
.TarjetaModal_visaLogo__1iCl- {
    background-image: url(/static/media/Vector-visa.0440b7f5.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 20.89px;
    width: 64.11px;
}
.TarjetaModal_masterCardLogo__2Pz-6 {
    background-image: url(/static/media/mastercard.1998dde2.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 47px;
    width: 64.11px;
}
.TarjetaModal_cardTitle__2TjQX {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 25px;
    color: #4d4d4d;
    letter-spacing: 0.02em;
}
.TarjetaModal_cardDescription__21AVk {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: #52606c;
}
@media screen and (max-width: 768px) {
    .TarjetaModal_card__378hy,
    .TarjetaModal_cardActive__2pVZQ {
        height: 68px;
        padding: 14px 24px 14px 20px;
    }
}

.PDModalLayout_mainContainer__F9uSn{
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
  gap: 24px;
}
.PDModalLayout_headerTitle__125AC{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 29px;
  letter-spacing: 0.02em;
  color: #4D4D4D;
}
.PDModalLayout_addCard__3u3pV{
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 8px;
    gap: 8px;
    width: 100%;
    margin-top: -8px;
    cursor: pointer;
}
.PDModalLayout_addIcon__3xEqu {
    width: 24px;
    height: 24px;
    background-image: url(/static/media/card-add.96ab6525.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.PDModalLayout_addLocationText__2gL9-{
    font-weight: 500;
    font-size: 16px;
    line-height: 18.75px;
    color: #006EF2;
}
.PDModalLayout_buttonsContainer__aklqP{
  display: flex;
  justify-content: flex-end;
  grid-gap: 24px;
  gap: 24px;
}
.PDModalLayout_buttonCancel__1oRRB,
.PDModalLayout_buttonGuardar__23ST6{
  display: grid !important;
  place-content: center !important ;
  width: 104px ;
  height: 40px !important;
  cursor: pointer !important;
  padding: 10px 24px !important;
  border-radius: 8px !important;
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 15px !important;
  letter-spacing: 0.02em !important;
  transition: transform 0.2s ease-in-out;
}
.PDModalLayout_buttonCancel__1oRRB{
  border: 1px solid rgba(212, 212, 212, 0.5);
  transition: all 0.2s ease-in-out;
}
.PDModalLayout_buttonCancel__1oRRB:hover{
  border: 1px solid rgba(212, 212, 212, 0.5);
  background-color: #eeeeee;
  transition: all 0.2s ease-in-out;
}
.PDModalLayout_buttonGuardar__23ST6{
  color: #ffff;
  background: #006EF2;
}
@media screen and (max-width:568px) {
  .PDModalLayout_buttonCancel__1oRRB,
  .PDModalLayout_buttonGuardar__23ST6{
    width: 100%;
  }
  .PDModalLayout_headerTitle__125AC{
    margin: 0 30px 8px 0;
    font-size: 20px;
  }
  .PDModalLayout_mainContainer__F9uSn{
    padding:32px 16px 24px 16px ;
    grid-gap: 32px;
    gap: 32px;
  }
}
.SimpleUIRadioCard_mainContainer__hRyUd{
  background-color: #F4F6F8;
  border-radius: 16px;
  padding: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.SimpleUIRadioCard_active__2E-_t{
  border: 1px solid #006EF2;
}
.SimpleUIRadioCard_disabled__3TWnL{
  opacity: 0.5;
}
.RadioButton_radio__34TZa{
  display: grid;
  place-content: center;
  padding: 4px;
  border: #0D267580 1.5px solid;
  border-radius: 50%;
}
.RadioButton_innerCircle__3aMCc{
  transition: scale 0.2s;
  scale: 0;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  content: '';
  background-color: #0D2675;
}
.RadioButton_innerCircleActive__2cSOz{
  transition: scale 0.2s;
  scale: 1;
}
.Onboarding_onBoardingSection__2c2az {
    margin: 50px 16px 32px;
    display: flex;
    justify-content: center;
}
.Onboarding_onBoardingSection2__mElv1 {
    margin: 24px 16px 65px;
    display: flex;
    justify-content: center;
    position: relative;
    min-height: 65vh;
    align-items: center;
}
.Onboarding_confetti__cglri {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 9999;
}

.Onboarding_onBoardingContent__1JwKE {
    width: 100%;
    max-width: 924px;
}
@media (min-width: 768px) {
    .Onboarding_onBoardingSection__2c2az,
    .Onboarding_onBoardingSection2__mElv1 {
        margin: 80px 16px 50px;
    }
    .Onboarding_onBoardingSection2__mElv1 {
        min-height: auto;
    }
}

.Steps_container__2PqV6 {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.Steps_stepsContent__3eBVj,
.Steps_stepsContent2__1Vt2t {
    display: flex;
    height: 88px;
    max-width: 500px;
    width: 100%;
    padding: 44px;
}
.Steps_stepsContent__3eBVj {
    margin: 0 0;
}
.Steps_stepsContent2__1Vt2t {
    margin: 0 0;
}
ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.Steps_stepNumber__2NUo3,
.Steps_stepNumberFill__3IlGR,
.Steps_stepNumberVoid__2RT2S {
    min-width: 40px;
    min-height: 40px;
    position: relative;
    background-size: 100% 100%;
    margin: -0.5px;
    display: flex;
    align-self: center;
}
.Steps_stepNumber__2NUo3:nth-child(1) {
    background-image: url(/static/media/step1.c760c4d9.svg);
    background-repeat: no-repeat;
}
.Steps_stepNumber__2NUo3:nth-child(3) {
    background-image: url(/static/media/step2.5a57dddb.svg);
    background-repeat: no-repeat;
}
.Steps_stepNumberVoid__2RT2S {
    background-image: url(/static/media/step3Void.811ef59d.svg);
    background-repeat: no-repeat;
}
.Steps_stepNumberFill__3IlGR {
    background-image: url(/static/media/step3Fill.1b2f51c5.svg);
    background-repeat: no-repeat;
}
.Steps_stepText__zk3XK,
.Steps_stepTextVoid__2QkwB,
.Steps_stepTextFill__2LsfG {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 24px;
    text-align: center;
    position: absolute;
    width: 105px;
    left: 50%;
    transform: translateX(-50%);
    bottom: -30px;
}
.Steps_stepText__zk3XK,
.Steps_stepTextFill__2LsfG {
    color: #282828;
}
.Steps_stepTextVoid__2QkwB {
    color: #637381;
}
.Steps_barSeparatorFill__1TVto,
.Steps_barSeparatorVoid__n8MjR,
.Steps_barSeparator__237cG {
    width: 100%;
    height: 1.5px;
    display: flex;
    align-self: center;
}
.Steps_barSeparator__237cG {
    border-top: 1.5px solid #0d2675;
}
.Steps_barSeparatorFill__1TVto {
    border-top: 1.5px solid #0d2675;
}
.Steps_barSeparatorVoid__n8MjR {
    border-top: 1.5px solid #0d2675;
    opacity: 0.5;
}

@media (min-width: 345px) {
    .Steps_stepText__zk3XK,
    .Steps_stepTextFill__2LsfG,
    .Steps_stepTextVoid__2QkwB {
        font-size: 13px;
    }
    .Steps_stepsContent__3eBVj,
    .Steps_stepsContent2__1Vt2t {
        padding: 0 45px;
    }
    .Steps_stepsContent__3eBVj {
        margin: 0 0;
    }
}
@media (min-width: 768px) {
    .Steps_stepsContent__3eBVj {
        padding: 0 30px;
    }
    .Steps_stepsContent2__1Vt2t {
        padding: 0 30px;
    }
    .Steps_stepsContent__3eBVj,
    .Steps_stepsContent2__1Vt2t {
        max-width: 100%;
    }
}

.OnBoardingStart_onBoardingSections__zA5OF {
    display: flex;
    flex-direction: column;
    grid-row-gap: 48px;
    row-gap: 48px;
    margin-top: 48px;
}
.OnBoardingStart_buttons__2XwvT {
    width: 100%;
    display: flex;
    justify-content: space-around;
    grid-column-gap: 16px;
    column-gap: 16px;
}
.OnBoardingStart_errorMsgContainer__6PSyI {
    margin: 16px 0;
}

@media (min-width: 540px) {
    .OnBoardingStart_buttons__2XwvT {
        justify-content: flex-end;
        grid-column-gap: 24px;
        column-gap: 24px;
    }
}
@media (min-width: 768px) {
    .OnBoardingStart_onBoardingSections__zA5OF {
        grid-row-gap: 92px;
        row-gap: 92px;
    }
    .OnBoardingStart_errorMsgContainer__6PSyI {
        margin: 32px 0;
    }
    .OnBoardingStart_onBoardingSections__zA5OF {
        margin-top: 64px;
    }
}

.ChangeRateBS_title__7Ckna{
  font-size: 24px;
  line-height: 28.13px;
  font-weight: 700;
}
.ChangeRateBS_text__32JDC{
  padding: 24px 0;
  font-size: 16px;
  line-height: 20px;
}
.ChangeRateBS_text__32JDC span{
  font-weight: 700;
}
.ChangeRateBS_buttonsContainer__1H3s7{
  display: flex;
  flex-direction: column;
  grid-gap:20px;
  gap:20px
}
.ChangeRateBS_button__1_soI{
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}
.ChangeRateBS_button__1_soI span{
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
}
.ChangeRateBS_iconSpecContainer__R6VU6{
  margin-top: 16px;
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}
.ChangeRateBS_iIcon__uCnvt{
  height: 15px;
  width: 15px;
  background-image: url(/static/media/i.ce2ba7bf.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.ChangeRateBS_tarifaSpec__1FIwy{
  font-size: 12px;
  line-height: 24px;
  color: #52606C;
  margin-bottom: 0;
}
.ChangeRateBS_buttonSubmit__1oZMH{
  margin-top: 24px;
}
.ConfiCards_confiCards__3dWrT {
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
}
.ConfiCards_skeletonSize__2S30O{
    width: 100%;
    height: 112px;
}

.ConfiCards_card__3sOXW,
.ConfiCards_addCard__234TY {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 24px;
    background: #f5f7fa;
    border-radius: 16px;
    position: relative;
    grid-column-gap: 16px;
    column-gap: 16px;
    width: 100%;
}

.ConfiCards_fullWidthCard__3qi0T{
    grid-column: 1 / span 2;
}
.ConfiCards_addCard__234TY {
    cursor: pointer;
}
.ConfiCards_addCard__234TY,
.ConfiCards_cardCredit__2wMNg {
    justify-content: center;
}
.ConfiCards_cardCredit__2wMNg {
    cursor: pointer;
}
.ConfiCards_cardIconPickUp__1idaw {
    width: 64px;
    height: 64px;
    background-image: url(/static/media/sucursalesIcon.7a66921c.svg);
    background-repeat: no-repeat;
    background-size: contain;
}
.ConfiCards_cardIcon__1DvIq {
    width: 64px;
    height: 64px;
    background-image: url(/static/media/location.da415b47.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}
.ConfiCards_cardCaptionAdd__1auPe {
    width: 40px;
    height: 40px;
    background-image: url(/static/media/addLocation.abe2e0a4.svg);
    background-repeat: no-repeat;
    background-size: contain;
}
.ConfiCards_addTitle__GfDBs {
    margin: 0;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #282828;
    background-color: transparent;
    border: none;
}
.ConfiCards_iconContainer__mFjea {
    display: flex;
    align-items: center;
}
.ConfiCards_creditCardIcon__C8Rtn {
    width: 64px;
    height: 64px;
    background-image: url(/static/media/creditCardIcon.9b93e992.svg);
    background-size: contain;
    background-repeat: no-repeat;
}
.ConfiCards_visaLogo__1SI3G {
    background-image: url(/static/media/Vector-visa.0440b7f5.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 20.89px;
    width: 64.11px;
    min-width: 64.11px;
}
.ConfiCards_calendarIcon__1Mo_1{
    background-color: #0d2675;
    padding: 16px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 64px;
    width: 64px;
}
.ConfiCards_calendarIcon__1Mo_1::after{
    content: "";
    background-image: url(/static/media/calendar.914e89d7.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 32px;
    width: 32px;
}
.ConfiCards_masterCardLogo__1lcFv {
    background-image: url(/static/media/mastercard.1998dde2.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 47px;
    width: 64.11px;
    min-width: 64.11px;
}
.ConfiCards_cardCaption__33hjE {
    display: flex;
    flex-direction: column;
    grid-row-gap: 8px;
    row-gap: 8px;
    overflow: hidden;
}
.ConfiCards_cardCaption__33hjE > h1 {
    margin: 0;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #282828;
}
.ConfiCards_sucursalTitleCard__J9Z-v {
    margin: 0;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px !important;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #282828;
}
.ConfiCards_cardCaption__33hjE > p {
    margin: 0;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: #52606c;
}
.ConfiCards_sucursal__2XoJh {
    font-size: 16px !important;
}
.ConfiCards_direccionDetalles__1FuSZ {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    /* max-width: 65%; */
}
.ConfiCards_cardCaption__33hjE > button,
.ConfiCards_changeBtn__1lIpT {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #006EF2;
    margin-left: auto;
    border: none;
    background: none;
    cursor: pointer;
    margin-top: auto;
}
@media (min-width: 768px) {
    .ConfiCards_confiCards__3dWrT {
        /*  flex-direction: row; */
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
    }
    .ConfiCards_card__3sOXW {
        width: 100%;
        min-width: 100%;
        max-width: 454px;
    }
    .ConfiCards_cardCaption__33hjE > h1 {
        font-size: 16px;
    }
    .ConfiCards_cardCaption__33hjE > p {
        font-size: 16px;
    }
    .ConfiCards_cardCaption__33hjE > button,
    .ConfiCards_changeBtn__1lIpT {
        font-size: 18px;
        line-height: 20px;;
    }
    .ConfiCards_addTitle__GfDBs {
        font-size: 20px;
    }
    .ConfiCards_direccionDetalles__1FuSZ {
        /*  max-width: 65%; */
    }
}

@media screen and (max-width: 768px) {
    .ConfiCards_cardIconPickUp__1idaw, .ConfiCards_calendarIcon__1Mo_1, .ConfiCards_creditCardIcon__C8Rtn, .ConfiCards_cardIcon__1DvIq {
        width: 51px;
        height: 51px;
    }
    .ConfiCards_calendarIcon__1Mo_1{
        padding: 9px;
    }
}

.AddressList_direcciones__1I9OP {
    padding: 0 10px;
    margin-top: 47px;
    border-radius: 10px;
    font-size: 16px;
    overflow-y: auto;
    scroll-behavior: smooth;
    display: flex;
    flex-direction: column;
    grid-row-gap: 16px;
    row-gap: 16px;
    max-height: 42vh;
}
.AddressList_direcciones__1I9OP::-webkit-scrollbar {
    width: 4px;
    background: rgba(201, 204, 216, 0.3);
    border-radius: 5px;
    -webkit-transition: 1s ease all;
    transition: 1s ease all;
}
/* Track */
.AddressList_direcciones__1I9OP::-webkit-scrollbar-track {
    background: #f1f1f1;
}
/* Handle */
.AddressList_direcciones__1I9OP::-webkit-scrollbar-thumb {
    background: #c9ccd8;
    border-radius: 5px;
}
/* Handle on hover */
.AddressList_direcciones__1I9OP::-webkit-scrollbar-thumb:hover {
    background: #87898f;
    width: 5px;
}
.AddressList_dirTitle__2pJ-_ {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.02em;
    color: #4d4d4d;
    margin: 0;
    position: absolute;
    top: 31px;
    left: 16px;
}
.AddressList_dirContainer__IgLrg {
    min-height: 64px;
    cursor: pointer;
    background: transparent;
    border: 1.5px solid rgba(212, 212, 212, 0.5);
    border-radius: 10px;
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* max-width: 360px; */
    margin-bottom: 16px;
}
.AddressList_dirContainer__IgLrg:focus {
    border-color: var(--blue);
}

.AddressList_dirSelect__2C-IH {
    display: flex;
    flex: 1 1;
    align-items: center;
    justify-content: space-between;
}
.AddressList_direcciones__1I9OP:last-of-type {
    border: 0;
}

.AddressList_aliasDireccion__DWZmA {
    color: #4d4d4d;
    font-size: 13px;
    font-weight: 500;
    line-height: 125%;
    letter-spacing: -0.09px;
    margin: 0;
}

.AddressList_detalleDireccion__1SqGF {
    font-size: 13px;
    line-height: 16px;
    font-weight: 400;
    letter-spacing: 0.02em;
    margin: 0;
    color: #52606c;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 200px;
}
.AddressList_text__1k5zk {
    max-width: 270px;
    /* background-color: yellowgreen; */
    display: flex;
    flex-direction: column;
    grid-row-gap: 8px;
    row-gap: 8px;
}
.AddressList_fav__CofBo {
    margin-left: 8px;
    width: 25.13px;
}
.AddressList_radioIcon__2bbyD {
    min-width: 24px;
    width: 24px;
    height: 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(/static/media/radioIconOff.71473d31.svg);
    margin-right: 8px;
    cursor: pointer;
    /* background-color: yellowgreen; */
}
.AddressList_radioIcon__2bbyD.AddressList_active__CctOx {
    background-image: url(/static/media/radioIconOn.8a9804c2.svg);
}
.AddressList_addDirection__1h82R{
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 8px;
    gap: 8px;
    width: 100%;
    margin-top: 16px;
    margin-bottom: 24px;
    cursor: pointer;
}
.AddressList_addIcon__3DEdz {
    width: 24px;
    height: 24px;
    background-image: url(/static/media/location-add.b446efc6.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.AddressList_addLocationText__3eWPI{
    font-weight: 500;
    font-size: 16px;
    line-height: 18.75px;
    color: #006EF2;
}
.AddressList_btnContainer__qoHV2 {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-column-gap: 16px;
    column-gap: 16px;
    margin-top: 16px;
}
.AddressList_btnCancel__LdU5h {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px 10px;
    grid-gap: 8px;
    gap: 8px;
    width: auto;
    min-width: 89px;
    width: 100%;
    height: 40px;
    background-color: #ffffff;
    border: 1.5px solid rgba(212, 212, 212, 0.5);
    border-radius: 8px;
    cursor: pointer;
}
.AddressList_btnCancel__LdU5h p {
    font-weight: 500;
    font-size: 15px;
    line-height: 12px;
    color: #282828;
    text-align: center;
    margin: 0px 0;
}
.AddressList_btnSave__kZXXJ {
    max-width: 100%;
    width: 100%;
}

@media (min-width: 569px) {

    .AddressList_dirTitle__2pJ-_ {
        top: 32px;
        left: 24px;
        font-size: 25px;
        line-height: 29px;
    }
    .AddressList_aliasDireccion__DWZmA {
        font-size: 16px;
    }
    .AddressList_detalleDireccion__1SqGF {
        line-height: 12px;
    }
    .AddressList_btnContainer__qoHV2 {
        justify-content: flex-end;
        padding: 0 4px 4px;
        margin-top: 24px;
    }
    .AddressList_btnCancel__LdU5h {
        width: auto;
    }
}
.textArea_myTextArea__24i5j {
    padding: 9.5px 16px;
    /* color: #4d4d4d; */
    font-weight: normal;
    border: 1px solid #ccc;
    width: 100%;
    /* background-color: #f5f7fa; */
    background-color: hsl(0, 0%, 100%);
    border-radius: 8px;
    outline: 0;
    font-size: 16px;
}

.textArea_myTextArea__24i5j:disabled {
    background-color: #ffffff;
    color: #999999a1;
    opacity: 0.5;
}

.mapa-form {
  padding: 20px;
  padding-top: 4px;
}

.mapa-form h1 {
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;
  color: #282828;
  letter-spacing: 0.02em;
}

.mapa-form h2 {
  margin: 16px 0;
  font-weight: normal;
  font-size: 18px;
  line-height: 21.09px;
}

.formulario-input{
  border: none;
  background-color: #F5F7FA;
  border-radius: 8px;
}

/* .mapa .gmnoprint, .mapa .gm-fullscreen-control, .mapa .gm-style-cc, .mapa .gm-style div a div img{   Esconde los botones y mensajes que trae el mapa por defecto  
    display: none !important;
} */

.mapa .gm-fullscreen-control,
.mapa .gm-style div a div img,
.gm-bundled-control,
.gm-style-cc {
  /* Esconde los botones y mensajes que trae el mapa por defecto  */
  display: none !important;
}

.gmnoprint {
  /* top: 30px !important; */
  border-radius: 10px;
  font-size: 3px !important;
}

.gm-style-mtc li {
  display: none;
}

.gm-style-mtc button {
  height: 30px !important;
}

.mapa div {
  border-radius: 10px !important;
}

button.abrir-mapa {
  position: relative;
  float: right;
  bottom: 39px;
  right: 12px;
  background-color: var(--blueRNB);
  color: white !important;
  border-radius: 5px;
  padding: 5px 10px;
  border: none;
}

button.abrir-mapa span {
  font-size: 13px !important;
  margin-right: 10px;
}

div.container-confirmar {
  width: 93%;
  max-width: 402px;
  position: absolute;
  height: 0;
}

button.confirmar-ubicacion {
  position: relative;
  float: center;
  bottom: 55px;
  right: -52%;
  transform: translateX(-50%);
  font-size: 14px !important;
  left: auto;
  width: 85%;
  background-color: var(--blueRNB);
  color: white !important;
  border-radius: 5px;
  padding: 5px 10px;
  border: none;
}

button.ubicacion-usuario {
  position: relative;
  float: right;
  bottom: 172px !important;
  right: 15px;
  background-color: transparent;
  color: white !important;
  border: none;
}

.container-marker {
  position: absolute;
  width: 92%;
  max-width: 402px;
  z-index: 999999999999;
  pointer-events: none;
}

.container-marker img {
  height: 30px;
  width: 30px;
  position: relative;
  right: -50%;
  transform: translate(-50%, -50%);
  bottom: -50%;
  z-index: 1 !important;
}

.container-searchbox {
  position: absolute;
  width: 92%;
  z-index: 1;
  height: 0;
  max-width: 402px;
}

input.searchbox {
  position: relative;
  right: -54%;
  top: 80px;
  transform: translateX(-50%);
  padding: 6px 4px 6px 10px;
  border: none;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.297);
  border-radius: 5px;
  /* box-sizing: border-box; */
  outline: none;
}

.container-searchbox > div {
  position: relative;
  right: -50%;
  top: 80px;
  padding: 6px 4px 6px 10px;
  max-width: 227px;
  transform: translateX(-50%);
  padding: 2px 4px;
  border-radius: 3px !important;
  z-index: 1000 !important;
}

.container-searchbox div div {
  padding: 4px 4px 4px 10px;
  border-radius: 3px !important;
  max-width: 227px;
}

.mapa-form .formulario-mapa {
  margin: 25px 0;
}

.mapa-form .formulario-mapa label {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 12px;
  letter-spacing: 0.02em;
  margin-bottom: 10px;
}

.formulario-mapa .input-container {
  margin: 15px 0;
}

.formulario-mapa .input-container label span {
  color: #e22e3d;
}

.mapa-form .guardar button {
  background-color: var(--blueRNB);
  border-radius: 5px;
  text-align: center;
  font-size: 15px;
  line-height: 12px;
  padding: 10px;
  color: white;
  width: 100%;
  border: none;
}

.mapa-form .guardar-exito button {
  border: none;
  border-radius: 5px;
  text-align: center;
  font-size: 15px;
  line-height: 12px;
  padding: 10px;
  background-color: white;
  border: 3px solid var(--blueRNB);
  color: var(--blueRNB);
  width: 100%;
}

.mapa-form .guardar-exito button span span {
  text-decoration: underline;
}

.error {
  align-items: center;
  text-align: center;
  padding: 0.5rem;
  background-color: #e22e3d;
  border-radius: 5px;
  font-size: 14px;
  /* font-weight: 500 !important; */
}
.error span {
  width: 50px;
  margin: 0;
  padding: 0;
  color: white;
  text-align: center;
}
.buttons-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  grid-gap: 24px;
  gap: 24px;
  max-width: 75%;
  margin-left: auto;
}


.SucursalesList_direcciones__2u-mc {
    padding: 0 14px 0 0;
    margin: 47px -12px 0 2px;
    border-radius: 10px;
    font-size: 16px;
    overflow-y: auto;
    scroll-behavior: smooth;
    max-height: 500px;
    display: flex;
    flex-direction: column;
    grid-row-gap: 16px;
    row-gap: 16px;
}
.SucursalesList_direcciones__2u-mc::-webkit-scrollbar {
    width: 4px;
    background: rgba(201, 204, 216, 0.3);
    border-radius: 5px;
    -webkit-transition: 1s ease all;
    transition: 1s ease all;
}
/* Track */
.SucursalesList_direcciones__2u-mc::-webkit-scrollbar-track {
    background: #f1f1f1;
}
/* Handle */
.SucursalesList_direcciones__2u-mc::-webkit-scrollbar-thumb {
    background: #c9ccd8;
    border-radius: 5px;
}
/* Handle on hover */
.SucursalesList_direcciones__2u-mc::-webkit-scrollbar-thumb:hover {
    background: #87898f;
    width: 5px;
}
.SucursalesList_dirTitle__RqAfD {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.02em;
    color: #4d4d4d;
    margin: 0;
    position: absolute;
    top: 32px;
    left: 16px;
}
.SucursalesList_dirContainer__1AOSr,
.SucursalesList_dirContainerUnable__1Pqmc {
    min-height: 64px;
    cursor: pointer;
    background: transparent;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* max-width: 360px; */
    margin-bottom: 16px;
    width: 100%;
}
.SucursalesList_dirContainer__1AOSr {
    padding: 16px 24px;
    border: 1.5px solid rgba(212, 212, 212, 0.5);
}
.SucursalesList_dirContainerUnable__1Pqmc {
    padding: 16px;
    border: 1px solid rgba(212, 212, 212, 0.25);
    color: #919eab;
}

.SucursalesList_active__2Tnu- {
    border-color: #0d2675;
}

.SucursalesList_dirSelect__qfH68 {
    display: flex;
    align-items: center;
}
.SucursalesList_direcciones__2u-mc:last-of-type {
    border: 0;
}

.SucursalesList_aliasDireccion__2jqj0 {
    color: #4d4d4d;
    font-size: 16px;
    font-weight: 500;
    line-height: 125%;
    letter-spacing: -0.09px;
    margin: 0;
}

.SucursalesList_detalleDireccion__2LQSa {
    font-size: 13px;
    line-height: 12px;
    font-weight: 400;
    letter-spacing: 0.02em;
    margin: 0;
    color: #52606c;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 200px;
}
.SucursalesList_text__3nzYa,
.SucursalesList_textUnable__3oMKo {
    max-width: 270px;
    /* background-color: yellowgreen; */
    display: flex;
    flex-direction: column;
    grid-row-gap: 5px;
    row-gap: 5px;
    text-align: left;
}
.SucursalesList_textUnable__3oMKo > p {
    color: #919eab;
}

.SucursalesList_fav__3PTJ2 {
    margin-left: 8px;
    width: 25.13px;
}
.SucursalesList_radioIcon__26-cd {
    min-width: 24px;
    width: 24px;
    height: 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(/static/media/radioIconOff.71473d31.svg);
    margin-right: 8px;
    cursor: pointer;
    /* background-color: yellowgreen; */
}
.SucursalesList_radioIcon__26-cd.SucursalesList_active__2Tnu- {
    background-image: url(/static/media/radioIconOn.8a9804c2.svg);
}
.SucursalesList_btnContainer__2jSLM {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-column-gap: 16px;
    column-gap: 16px;
    margin: 16px 4px 0;
}
.SucursalesList_btnCancel__1hIly {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px 10px;
    grid-gap: 8px;
    gap: 8px;
    width: auto;
    min-width: 89px;
    width: 100%;
    height: 40px;
    background-color: #ffffff;
    border: 1.5px solid rgba(212, 212, 212, 0.5);
    border-radius: 8px;
    cursor: pointer;
}
.SucursalesList_btnCancel__1hIly p {
    font-weight: 500;
    font-size: 15px;
    line-height: 12px;
    color: #282828;
    text-align: center;
    margin: 0px 0;
}
.SucursalesList_btnSave__20TO5 {
    max-width: 100%;
    width: 100%;
}
.SucursalesList_infoMsg__2A8vQ {
    display: flex;
    flex-direction: row;
    grid-column-gap: 10px;
    column-gap: 10px;
    align-items: center;
    margin-top: 24px;
    padding: 0 4px;
}
.SucursalesList_infoIcon__3EF0f {
    width: 20px;
    height: 20px;
}
.SucursalesList_infoDescription__1iQO4 {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 125%;
    color: #4d4d4d;
    margin: 0;
}

@media (min-width: 570px) {
    /* .dirContainer {
        padding: ;
    } */
    .SucursalesList_direcciones__2u-mc {
        padding: 0 20px 0 4px;
        margin: 45px -16px 0 0;
    }
    .SucursalesList_dirTitle__RqAfD {
        top: 32px;
        left: 24px;
        font-size: 25px;
        line-height: 29px;
    }
    .SucursalesList_aliasDireccion__2jqj0 {
        font-size: 16px;
    }
    .SucursalesList_textUnable__3oMKo {
        max-width: 340px;
    }
    .SucursalesList_detalleDireccion__2LQSa {
        line-height: 12px;
        max-width: 340px;
    }
    .SucursalesList_btnContainer__2jSLM {
        justify-content: flex-end;
        margin: 10px 0 0;
    }
    .SucursalesList_btnCancel__1hIly {
        width: auto;
    }
    .SucursalesList_infoMsg__2A8vQ {
        margin-top: 8px;
        grid-column-gap: 8px;
        column-gap: 8px;
        padding: 0 20px;
    }
    .SucursalesList_infoIcon__3EF0f {
        width: 32px;
        height: 32px;
    }
    .SucursalesList_infoDescription__1iQO4 {
        font-size: 16px;
        line-height: 20px;
    }
}

.ErrorMsg_errorMsgContainer__3XN4j {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    grid-gap: 8px;
    gap: 8px;
    width: 100%;
    min-height: 48px;
    background: rgba(224, 0, 33, 0.1);
    border-radius: 8px;
    margin-top: 16px;
    position: relative;
}
.ErrorMsg_errorMsgContainer__3XN4j > p {
    margin: 0;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #B3001A;
}
.ErrorMsg_alertIcon__AUMuK {
    width: 24px;
    height: 24px;
}
@media (min-width: 580px) {
    .ErrorMsg_toRightArrow__2Ub8b {
        position: absolute;
        right: 16px;
    }
}
@media (min-width: 768px) {
    .ErrorMsg_errorMsgContainer__3XN4j {
        margin-top: 32px;
        height: 64px;
    }
    .ErrorMsg_errorMsgContainer__3XN4j > p {
        font-size: 16px;
        list-style: 18.5px;
    }
    .ErrorMsg_alertIcon__AUMuK {
        width: 32px;
        height: 32px;
    }
}

.Description_description__17RzH {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #52606c;
    margin: 0 0;
}

.SubTitle_subTitle__j6teG {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.02em;
    color: #282828;
    margin: 0 0;
    font-size: 25px;
    line-height: 100%;
}
@media (min-width) {
    .SubTitle_subTitle__j6teG {
        font-size: 32px;
    }
}

.TipoEntregaCard_cardsContainer__2Uk46 {
    display: flex;
    flex-direction: column;
    grid-row-gap: 16px;
    row-gap: 16px;
}
.TipoEntregaCard_relampagoCard__1Ub5i,
.TipoEntregaCard_expressCard__2cPHJ {
    display: flex;
    flex-direction: column;
    padding: 0px 16px 24px;
    /* width: 343px;
    height: 156px; */
    position: relative;
    border-radius: 12px;
    border: none;
    padding: 20px 16px 24px;
    grid-gap: 10px;
    gap: 10px;
    cursor: pointer;
}
.TipoEntregaCard_cardDisable__1AS3a {
    background: #ffffff;
    box-shadow: 0px 0px 24px -4px rgba(0, 0, 0, 0.12);
}
.TipoEntregaCard_cardActive__2ZOoW {
    background: #0d2675;
    box-shadow: 0px 0px 30px rgba(0, 102, 238, 0.45);
}
.TipoEntregaCard_topCard__1nwfB {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.TipoEntregaCard_cardIconRelampago__1t2FO {
    width: 19px;
    height: 32px;
    margin: 0 15px;
}
.TipoEntregaCard_relampagoActive__3nnNt {
    background-image: url(/static/media/relampagoIconActive.963bcd59.svg);
    background-size: 100%;
    background-repeat: no-repeat;
}
.TipoEntregaCard_relampagoInActive__3YxL5 {
    background-image: url(/static/media/relampagoIcon.e352f728.svg);
    background-size: 100%;
    background-repeat: no-repeat;
}
.TipoEntregaCard_cardIconExpress__3Awiy {
    width: 23px;
    height: 32px;
    margin: 0 15px;
}
.TipoEntregaCard_expressActive__1yq-_ {
    background-image: url(/static/media/expressIconActive.eb2e0aa0.svg);
    background-size: 100%;
    background-repeat: no-repeat;
}
.TipoEntregaCard_expressInActive__2WamG {
    background-image: url(/static/media/expressIcon.9c08b281.svg);
    background-size: 100%;
    background-repeat: no-repeat;
}
.TipoEntregaCard_hidden__2KFtl {
    opacity: 1;
    position: absolute;
    z-index: -1;
}
.TipoEntregaCard_cardTitle__3Msyd {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 100%;
    letter-spacing: 0.02em;
    width: 250px;
    margin: 0;
    text-align: left;
}
.TipoEntregaCard_titleBlackText__3JZMt {
    color: #282828;
}
.TipoEntregaCard_whiteText__-VNG8 {
    color: #fff;
}
.TipoEntregaCard_circleIcon__U43TU {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 26px;
    top: 26px;
}
.TipoEntregaCard_circleIconActive__RmWC8 {
    background-image: url(/static/media/circleActive.e78751d5.svg);
    background-size: contain;
}
.TipoEntregaCard_circleIconDisable__2q5Hq {
    background-image: url(/static/media/circle.358f9e75.svg);
    background-size: contain;
}

.TipoEntregaCard_description__3BsaE {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 125%;
    text-align: left;
}
.TipoEntregaCard_descriptionBlackText__2oD5Q {
    color: #52606c;
}
.TipoEntregaCard_logoRelampago__2Vl6K .TipoEntregaCard_logoExpress__29VwJ {
    display: none;
}
.TipoEntregaCard_separator__38czC {
    height: 20px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: #919eab;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    grid-column-gap: 10px;
    column-gap: 10px;
}
.TipoEntregaCard_line__1eT_Q {
    display: inline-block;
    border: 1px solid #dce0e4;
    height: 1px;
    width: 100%;
}
@media (min-width: 768px) {
    .TipoEntregaCard_cardsContainer__2Uk46 {
        flex-direction: row;
        grid-column-gap: 32px;
        column-gap: 32px;
    }
    .TipoEntregaCard_relampagoCard__1Ub5i,
    .TipoEntregaCard_expressCard__2cPHJ {
        padding: 32px 24px;
        max-width: 446px;
        width: 100%;
        max-height: 374.99px;
    }
    .TipoEntregaCard_cardIconRelampago__1t2FO,
    .TipoEntregaCard_cardIconExpress__3Awiy {
        display: none;
    }
    .TipoEntregaCard_cardTitle__3Msyd {
        font-weight: 600;
        line-height: 125%;
    }
    .TipoEntregaCard_description__3BsaE {
        max-width: 334px;
        align-self: center;
    }
    .TipoEntregaCard_separator__38czC {
        display: none;
    }
    .TipoEntregaCard_logoRelampago__2Vl6K,
    .TipoEntregaCard_logoExpress__29VwJ,
    .TipoEntregaCard_logoRelampagoInActive__2-zig {
        width: 196.5px;
        min-height: 161.99px;
        margin: 17.37px auto 32px;
    }
    .TipoEntregaCard_logoRelampago__2Vl6K {
        background-image: url(/static/media/relampagoLogo.3b02e64a.svg);
        background-size: 100% 100%;
    }
    .TipoEntregaCard_logoRelampagoInActive__2-zig {
        height: 171.52px;
        background-image: url(/static/media/relampagoLogoInActive.2357832a.svg);
        background-size: 100% 100%;
    }
    .TipoEntregaCard_logoExpActive__27tCF {
        background-image: url(/static/media/expressLogoActive.472a084f.svg);
        background-repeat: no-repeat;
        background-size: 100%;
    }
    .TipoEntregaCard_logoExpInActive__3meSc {
        background-image: url(/static/media/expressLogo.20dcf862.svg);
        background-repeat: no-repeat;
        background-size: 100%;
    }
}

.Title_title__1AdAB {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 100%;
    letter-spacing: 0.02em;
    color: #282828;
    margin: 0 0;
    position: relative;
    width: max-content;
}
@media (min-width: 768px) {
    .Title_title__1AdAB {
        font-weight: 700;
        font-size: 32px;
        line-height: 36px;
    }
    .Title_stars__1Ti-X {
        right: -25px;
    }
}

.WarningMsgBlue_mainContainer__3zcP1 {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
}
.WarningMsgBlue_infoCircleBlue__2ySdm {
    background-image: url(/static/media/info-circle.96fa26f3.svg);
    background-repeat: no-repeat;
    background-position: center;
    height: 32px;
    width: 32px;
}
.WarningMsgBlue_textContainer__YCt6S {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
    font-size: 16px;
}
.WarningMsgBlue_link__2Ke9m {
    color: #0d2675;
}
@media screen and (max-width: 550px) {
    .WarningMsgBlue_textContainer__YCt6S {
        font-size: 13px;
        grid-gap: 2px;
        gap: 2px;
    }
}

.Wrappers_insideSectionWrapper__2CV2_ {
    display: flex;
    flex-direction: column;
    grid-row-gap: 24px;
    row-gap: 24px;
}

.Wrappers_headerWrapper__2Q8si {
    display: flex;
    flex-direction: column;
    grid-row-gap: 16px;
    row-gap: 16px;
}

@media (min-width: 768px) {
    .Wrappers_insideSectionWrapper__2CV2_ {
        grid-row-gap: 32px;
        row-gap: 32px;
    }
    .Wrappers_headerWrapper__2Q8si {
        grid-row-gap: 16px;
        row-gap: 16px;
    }
}

.WelcomeCard_welcomeCardSection__3VajA {
    margin-top: 32px;
    position: relative;
}
.WelcomeCard_welcomeCardContent__1zNRF {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 54px 16px 60px;
    background: #0d2675;
    box-shadow: 0px 5px 24px -4px rgba(0, 0, 0, 0.12);
    border-radius: 12px;
    min-height: 412px;
}
.WelcomeCard_welcomeCardContainer__10TQj {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
    margin-bottom: 24px;
}
.WelcomeCard_title__WuBRX {
    width: 245px;
    font-family: "Noway";
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 100%;
    letter-spacing: 0.02em;
    color: #ffffff;
    text-align: center;
    margin: 0;
    padding-left: 5px;
}
.WelcomeCard_title__WuBRX > b {
    font-weight: 700;
}
.WelcomeCard_logoPremium__oASTV {
    width: 325px;
    height: 80px;
    background-image: url(/static/media/premium.7d9ac542.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
.WelcomeCard_description__1onHO {
    width: 252.67px;
    margin: 0 0 27px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 125%;
    text-align: center;
    color: #52606c;
}
.WelcomeCard_btnContainer__2rhMn {
    position: absolute;
    bottom: 20px;
}
.WelcomeCard_btnRegresar__1ty-m {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #0d2675 !important;
    width: auto;
    cursor: pointer;
    position: relative;
    z-index: 100;
    background-color: #ffffff !important;
    padding: 15px 20px;
}
.WelcomeCard_btnRegresar__1ty-m:hover {
    color: #006ef2;
}

@media (min-width: 768px) {
    .WelcomeCard_title__WuBRX {
        font-size: 24px;
        line-height: 24px;
        width: auto;
    }
    .WelcomeCard_description__1onHO {
        width: -moz-fit-content;
        width: fit-content;
        font-size: 16px;
        line-height: 24px;
        margin: 0 0 43px;
    }
    .WelcomeCard_btnRegresar__1ty-m {
        font-size: 18px;
    }
    .WelcomeCard_welcomeCardContent__1zNRF {
        padding: 133px 0 111px;
    }
}

.ConfirmarPago_confimarPagoSection__2G-rB {
    min-height: 400px;
}
.ConfirmarPago_confirmarPagoHeader__1-qZX {
    height: 100vh;
    max-height: 192.75px;
    border-radius: 0 0 150px 0;
    position: relative;
    color: white;
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.ConfirmarPago_confirmarPagoHeaderBackground__-W_S5 {
    position: absolute;
    z-index: -10;
    width: 972px;
    height: 865px;
    left: -47px;
    top: -500px;
    background: linear-gradient(180deg, #023382 18.03%, #0d2675 100%),
        linear-gradient(237.34deg, #120d32 19.95%, #120d34 56.23%, #006ef2 99.49%), #d9d9d9;
}
.ConfirmarPago_ellipsisBackground__8VkX3 {
    position: absolute;
    opacity: 0.5;
    width: 100%;
    height: 100vh;
    bottom: 0;
    background-image: url(/static/media/EllipseModal.1865ee11.svg);
    background-repeat: no-repeat;
    background-position: bottom left;
}
.ConfirmarPago_headerTitle__3rvvm {
    font-weight: 500;
    font-size: 20px;
    line-height: 125%;
    letter-spacing: 0.02em;
    color: #ffffff;
    margin: 0;
    margin-top: 59.75px;
    width: 309px;
    text-align: center;
    margin-right: 6px;
}
.ConfirmarPago_headerDescription__1CacO {
    position: absolute;
    left: 18px;
    bottom: 32px;
    width: 264px;
    height: 32px;
    font-weight: 500;
    font-size: 13px;
    line-height: 125%;
    letter-spacing: 0.02em;
    color: #ffffff;
    margin: 0 0;
}
.ConfirmarPago_modalStars__1rgRx {
    position: absolute;
    top: 140.75px;
    right: 0;
}
.ConfirmarPago_modalCaption__3_-80 {
    padding: 24px 24px 20px;
}
.ConfirmarPago_modalCaption__3_-80 > h1 {
    font-weight: 400;
    font-size: 13px;
    line-height: 125%;
    color: #52606c;
    margin: 0 !important;
    text-align: center;
}
.ConfirmarPago_price__1Xkrp {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: 100%;
    min-height: 64px;
    background: #f5f7fa;
    border-radius: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 46px;
    line-height: 125%;
    letter-spacing: 0.02em;
    color: #282828;
    margin-top: 8px;
}

.ConfirmarPago_infoMsg__2_oLX {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 16px 0 24px;
    grid-column-gap: 10px;
    column-gap: 10px;
}
.ConfirmarPago_infoDescription__HNpCz {
    font-weight: 400;
    font-size: 11px;
    line-height: 125%;
    color: #52606c;
    margin: 0;
}
.ConfirmarPago_infoMsg__2_oLX > img {
    width: 24px;
    height: 24px;
}
.ConfirmarPago_link__2jHHN {
    color: #0d2675;
}

@media (min-width: 570px) {
    .ConfirmarPago_confirmarPagoHeader__1-qZX {
        max-height: 257px;
        border-radius: 0 0 220px 0;
    }
    .ConfirmarPago_ellipsisBackground__8VkX3 {
        background-image: url(/static/media/EllipseModalDesktop.8a2a73a7.svg);
    }
    .ConfirmarPago_headerTitle__3rvvm {
        font-size: 25px;
        line-height: 125%;
        margin: 60px 0 0;
    }
    .ConfirmarPago_headerDescription__1CacO {
        font-size: 16px;
        top: 164px;
        left: 16px;
        width: 405px;
    }
    .ConfirmarPago_infoMsg__2_oLX {
        margin: 16px 16px 24px;
    }
    .ConfirmarPago_infoMsg__2_oLX > img {
        width: 32px;
        height: 32px;
    }
    .ConfirmarPago_modalStars__1rgRx {
        right: 5.68px;
        width: 80.32px;
        height: 75.89px;
        top: 182.89px;
    }
    .ConfirmarPago_modalCaption__3_-80 {
        padding: 24px 16px 25px;
    }
    .ConfirmarPago_modalCaption__3_-80 > h1 {
        line-height: 16.25px;
    }
    .ConfirmarPago_price__1Xkrp {
    }
    .ConfirmarPago_infoDescription__HNpCz {
        font-size: 13px;
        line-height: 16.25px;
        width: 404px;
    }
    .ConfirmarPago_errorMsg__2pCav{
        background-color: white;
        margin: 16px 16px 24px;
        padding: 0;
        height: -moz-fit-content;
        height: fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }
    .ConfirmarPago_errorMsg__2pCav p {
        font-size: 13px;
    }
    .ConfirmarPago_btnContainer__gYF7G {
        max-width: 420px;
        margin: 0 auto;
    }
}

.Settings_settingSection__3vugb {
    margin: 48px 16px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.Settings_settingcontainer__2y2tD {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 924px;
}

.Settings_settingContent__2XatK {
    display: flex;
    flex-direction: column;
    grid-row-gap: 48px;
    row-gap: 48px;
    width: 100%;
}
.Settings_buttonsContainer__1vyiF {
    width: 100%;
    display: flex;
    justify-content: space-around;
    grid-column-gap: 16px;
    column-gap: 16px;
    margin-top: 51px;
}
.Settings_cancelarSuscripcion__1d5ZT {
    align-self: center;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    margin-top: 17px;
    color: #919eab;
    border: none;
    background-color: transparent;
}
.Settings_toast__1EzSm {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #4d4d4d;
}
@media (min-width: 540px) {
    .Settings_buttonsContainer__1vyiF {
        justify-content: flex-end;
        grid-column-gap: 24px;
        column-gap: 24px;
    }
}
@media (min-width: 768px) {
    .Settings_settingSection__3vugb {
        margin: 120px 16px 50px;
    }
    .Settings_settingContent__2XatK {
        grid-row-gap: 92px;
        row-gap: 92px;
    }
    .Settings_buttonsContainer__1vyiF {
        margin-top: 48px;
    }
}

.PremiumCard_mainContainer__3DrvB{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
}
.PremiumCard_container__3auVC{
  display: flex;
  flex-direction: column;
  grid-gap: 60px;
  gap: 60px;
  max-width: 925px;
  margin-top: 80px;
  margin-inline: 16px;
}

@media (max-width: 610px) {
  .PremiumCard_mainContainer__3DrvB{
    display: block;
    min-height: calc(100vh - 102px);
    height: 100%;
  }
  .PremiumCard_container__3auVC{
  margin-top: 24px;
  max-width: calc(100% - 32px);
  height: 100%;
  grid-gap:24px;
  gap:24px;
  }
}
.Card_container__2VXSB{
  background-color: #0D2675;
  border-radius: 10px;
  box-shadow: 0px 12px 24px -4px rgba(0, 110, 242, 0.15);
  padding: 32px;
  padding-bottom: 16px;
  position: relative;
  bottom:0;
  right: 0;
  background-image: url(/static/media/starDecorCard.5022df7b.svg);
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
}
.Card_DiscoundBadge__2CyfB{
  
}
.Card_dataContainer__2dyd6{
  display: flex;
  justify-content: space-between;
}
.Card_dataUserContainer__1B2vL{
  flex: 1 1;
}
.Card_PremiumLogo__JuqWU{
  background-image: url(/static/media/PremiumLogo.66cd9b85.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 120px;
  height: 27px;
}
.Card_clientInfo__2GRNr{
  margin-top: 24px;
  color: #FFFFFF;
}
.Card_verifiedContainer__MaA4z{
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}
.Card_checkSvg__1bG_r{
  background-image: url(/static/media/checkicon.f5c8e863.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 12px;
  height: 12px;
}
.Card_verifiedLabel__3ZBrb{
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.048px;
}
.Card_clientName__1ovwn{
  font-size: 26px;
  letter-spacing: -0.088px;
  font-weight: 500;
}
.Card_date__2c45O{
  font: size 12px;
  letter-spacing:-0.048px;
  text-transform: uppercase;
  color: #FFFFFF;
  font-weight: 400;
  opacity: 0.5;
}
.Card_buttonBeneficios__1atZb{
    display: none;
    z-index: 1;
}
.Card_copyRContainer__gy1ou{
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  font-size: 8px;
  width: 100%;
  grid-gap: 5px;
  gap: 5px;
  margin-top: 24px;
}
.Card_starDecoration__1UAB8{
  z-index: 0;
  position: absolute;
  bottom:0;
  right: 0;
  background-image: url(/static/media/starDecorCard.5022df7b.svg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 379px;
  height: 212px;
}

@media (max-width: 610px) {
  .Card_container__2VXSB{
    min-height: 531px;
    display: flex;
    flex-direction: column;
    padding-bottom: 32px;
    justify-content: space-between;
  }
  .Card_clientInfo__2GRNr{
    margin-top: 72px;
  }
  .Card_dataContainer__2dyd6{
    flex-wrap: wrap;
    flex-direction: column;
  }
  .Card_starDecoration__1UAB8{
    height: 250px;
  }
  .Card_buttonBeneficios__1atZb{
  display: block;
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 8px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  padding-inline: 32px;
  font-size: 16px;
  color:#0D2675;
  font-weight: 500;
}
.Card_buttonBeneficios__1atZb:hover{
 opacity: 0.8;
}
 .Card_copyRContainer__gy1ou{
    flex-direction: column;
    position: absolute;
    color:#52606C;
    bottom: -54px;
    right: 0;
  }
  .Card_container__2VXSB{
    margin-bottom: 75px;
    background-position:0% 25%;
    background-size: 100% 235%;
  }
}
.ActiveBenefitCard_mainContainer__3VB4q{
  padding:28px 24px;
  border-radius: 10px;
  border: 1.5px solid rgba(255, 255, 255, 0.10);
  background: #0D2675;
  -webkit-backdrop-filter: blur(7.5px);
          backdrop-filter: blur(7.5px);
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
  margin-bottom: 45px;
}
.ActiveBenefitCard_mainContainer__3VB4q:hover{
  opacity: 0.8;
  cursor: pointer;
  border-color:  rgba(255, 255, 255, 0.227);
}
.ActiveBenefitCard_header__2v1Oy{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
    color: #FFF;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; 
  letter-spacing: -0.068px;
}

.ActiveBenefitCard_timeContainer__22TMI{
  display: flex;
  flex-direction: row;
  grid-gap: 8px;
  gap: 8px;
  align-items: center;
}
.ActiveBenefitCard_clockIcon__1-9IN{
  background-image: url(/static/media/whiteClock.34a44c43.svg);
  background-position: center;
  background-repeat: no-repeat;
  width: 10px;
  height: 10px;
}
.ActiveBenefitCard_remainingTime__2UHSg{
  font-weight: 400;
}
.ActiveBenefitCard_description__2tqHx{
  color: #FFF;
font-family: Roboto;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 150%;
letter-spacing: -0.037px;
overflow: hidden;
display: -webkit-box;
-webkit-line-clamp: 2;
line-clamp: 2; 
-webkit-box-orient: vertical;
}
@media (max-width: 610px) {
  .ActiveBenefitCard_mainContainer__3VB4q{
    margin: 0;
    max-width: none;
  }
}
.ActiveBenefit_benefitCardsContainer__29HQk{
  overflow: hidden;
  grid-gap: 32px;
  gap: 32px;
  max-width: 295px;
}
.ActiveBenefit_slidesContainer__33qP0 {
    display: flex;
    width: 100%;
    height: 100%;
}
.ActiveBenefit_cardsContainerMobile__1VsxE{
  display: none;
}

@media (max-width: 610px) {
  .ActiveBenefit_benefitCardsContainer__29HQk{
    margin-top: 24px;
    max-width: 100%;
    overflow: scroll;
  }
  .ActiveBenefit_slidesContainer__33qP0{
    display: none;
  }
  .ActiveBenefit_cardsContainerMobile__1VsxE{
    max-height: 300px;
    margin-bottom: 24px;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    grid-gap: 24px;
    gap: 24px;
  }
}
.Beneficios_title__3_IrJ{
  font-size: 32px;
  font-weight: 500;
  letter-spacing: -0.64px;
  color: #282828;
}
.Beneficios_text__Ze-Bk{
  font-size: 16px;
  color: #52606C;
  letter-spacing: 0.32px;
}
.Beneficios_benefitContainer__3aMAI{
  display:grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap:16px;
  gap:16px;
  padding-bottom: 60px;
}
@media (max-width: 925px) {
.Beneficios_benefitContainer__3aMAI{
    grid-template-columns: 1fr;

}
  }
@media (max-width: 610px) {
  .Beneficios_text__Ze-Bk{
    display: none;
  }
  .Beneficios_title__3_IrJ{
    display: none;
  }
  .Beneficios_benefitContainer__3aMAI{
    grid-template-columns: repeat( 2, 1fr);
    grid-gap: 24px;
    gap: 24px;
    margin-bottom: 24px;
  }
}
@media (max-width: 375px){
  .Beneficios_benefitContainer__3aMAI{
    grid-template-columns: repeat( 2, 1fr);
  }
}
.BenefitCard_logo__2q-Kj{
  grid-area: logo;
  width: calc(100% - 16px);
  height: auto;
  border-radius: 8px;
  align-self: center;
}
.BenefitCard_logoDesactivado__2ZHs4{
  opacity: 0.3;
}
.BenefitCard_nombreEmpresa__1cE2Q{
  grid-area: nombreEmpresa;
  min-width: max-content;
  color:#282828;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
}
.BenefitCard_categoriasContainer__373eo{
  display: grid;
  align-items: end;
  min-width: max-content;
  align-self: baseline;
}
.BenefitCard_categoriaLabel__3zBFP{
  display: inline-block;
  overflow: hidden;
  text-align: center;
  max-width: 100%;
  font-size: 12px;
  font-weight: 500;
  background-color: #E5E8EB;
  color:#212B36;
  border-radius: 32px;
  grid-area: categorias;
}
.BenefitCard_catgoriaP__1Cmig{
  margin-inline: 8px;
  margin-block: 2px;
  max-width: 100%;
  overflow: hidden;
}

.BenefitCard_description__1DIhx{
  color: #52606C;
  letter-spacing: 0.048px;
  font-size: 12px;
  line-height: normal;
  grid-area: description;
}
.BenefitCard_container__2EYBg{
  overflow: hidden;
  position: relative;
  border-radius: 8px;
  padding: 16px;
  display: grid;
  grid-row-gap: 8px;
  row-gap: 8px;
  background-color: #F4F6F8;
  grid-template-columns:  [first] 96px [second] min-content [third] auto ;
  grid-template-rows: repeat(3, auto);
  grid-template-areas:
    "logo  categorias categorias "
    "logo nombreEmpresa nombreEmpresa "
    "logo description description ";
}
.BenefitCard_container__2EYBg:hover{
  background-color: #d9dddf;
  cursor: pointer;
}
.BenefitCard_DiscoundBadge__18EIJ{
  position: absolute;
  top: 0;
  left: 0;
  background-color: #0D2675;
  padding: 4px;
  border-radius: 0 0 8px 0;
}
.BenefitCard_discountLabel__362UG{
  color: #FFFFFF;
  font-size: 12px;
  font-weight: 700;
  line-height: 18.75px;
  border-radius: 8px;
  top: 0;
  right: 0;
}
.BenefitCard_giftIcon__19u2T{
  background-image: url(/static/media/giftIcon.49ac2126.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 16px;
  height: 16px;
  margin-inline: 4px;
}

@media (max-width: 610px) {
  .BenefitCard_nombreEmpresa__1cE2Q, .BenefitCard_description__1DIhx {
    display: none;
  }
  .BenefitCard_logo__2q-Kj, .BenefitCard_categoriasContainer__373eo{
    justify-self: center;
  }
  .BenefitCard_categoriaLabel__3zBFP{
    margin-left: 0;
    max-width: none;
    justify-self: stretch;
  }
  .BenefitCard_categoriasContainer__373eo{
    border-radius: 0 0 8px 8px ;
    background-color: #E5E8EB;
    display: grid;
    justify-content: center;
    justify-self: stretch;
    align-self: self-end;
  }
  .BenefitCard_container__2EYBg{
    grid-gap: 0;
    gap: 0;
    grid-template-columns: auto;
    grid-template-rows: repeat(2, auto);
    grid-template-areas: 
    "logo"
    "categorias";
    padding: 0;
    min-height: 100px;
  }
  .BenefitCard_logo__2q-Kj{
    border-radius: 8px 8px 0 0;
    max-width: none;
    max-height: none;  
    width: 100%;
    max-height: 100px;
    object-fit: cover;
  }
}
@media (max-width: 610px) {
  .BenefitCard_categoriaLabel__3zBFP{
    max-width: 130px;
    max-height: 22px;
  }
}
.BenefitInfo_mainContainer__jSuSi{
  display: flex;
  flex-direction: column;
  grid-gap: 32px;
  gap: 32px;
  height: 100%;
}
.BenefitInfo_availableCounter__2vK2X{
grid-gap:4px;
gap:4px;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
max-width: max-content;
background: rgba(253, 192, 0, 0.16);
color:#FDC000 ;
padding: 4px 8px;
border-radius: 56px;
font-size: 16;
font-weight: 500;
}
.BenefitInfo_warningIcon__1YD8z{
  background-image: url(/static/media/warningIcon.3d45b39a.svg);
  height: 24px;
  width: 24px;
}
.BenefitInfo_headerCard__1PuDP{
  display: flex;
  flex-direction: row;
  grid-gap: 16px;
  gap: 16px;
}
.BenefitInfo_logo__ECWcs{
  width: 64px;
  height: 64px;
  border-radius: 8px;
}
.BenefitInfo_name__1DOqx{
  color: #282828;
  font-size: 24px;
  font-weight: 500;
}
.BenefitInfo_category__ikCtP{
  max-width: max-content;
  background-color: #E5E8EB;
  padding: 4px 8px;
  border-radius: 32px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.BenefitInfo_infoContainer__2DTkW{
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  gap: 16px;
}
.BenefitInfo_descriptionTitle__1CRt6{
  font-weight: 500;
  color: #282828;
  font-size: 16px;
}
.BenefitInfo_descriptionText__1-lmX{
  font-size: 16px;
  line-height: 19px;
  display: flex;
  flex-direction: row;
  grid-gap: 8px;
  gap: 8px;
  align-items: center;
  justify-content: left;
}
.BenefitInfo_linksContainer__2ZyFL{
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  gap: 16px;
}
.BenefitInfo_linksSubContainer__3NTx9{
  display: flex;
  flex-direction: row;
  grid-gap: 8px;
  gap: 8px;
  color: #0D2675;
  font-weight: 600;
  letter-spacing: 0.14px;
}
.BenefitInfo_activationButton__3fwXf{
  background-color: #0D2675;
  height: 42px;
  display: grid;
  place-items: center;
  color: #FFFFFF;
  font-size:16px;
  font-weight: 500;
  border-radius: 8px;
  margin-top: calc(47px - 16px);
}
.BenefitInfo_activationButtonDisabled__2PqaC{
  opacity: 0.5;
}
.BenefitInfo_unselectable__2XCED {
-webkit-touch-callout: none;
-webkit-user-select: none;
user-select: none;
}
.BenefitInfo_activationButton__3fwXf:hover{
  opacity: 0.8;
  cursor: pointer;
}
.BenefitInfo_bottomLegendContainer__HCkn9{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  grid-gap: 4px;
  gap: 4px;
  color: #52606C;
  font-size: 12px;
  font-weight: 500;
}
.BenefitInfo_ClockIcon__3Edjj{
  background-image: url(/static/media/clock.9c60adcc.svg);
  height: 12px;
  width: 12px;
}
.BenefitInfo_info__jrr60{
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  flex-direction: row;
  grid-gap: 8px;
  gap: 8px;
  align-items: center;
  justify-content: center;;
}
.BenefitInfo_infoIcon__3K9Pm{
  background-image: url(/static/media/clockInfo.c5d1a2ee.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 12px;
  width: 12px;
}

  .BenefitInfo_buttonsContainer__L6-lC{
    height: 100%;
    grid-gap: 16px;
    gap: 16px;
    display: flex;
    flex-direction: column;
    justify-content: end;
  }

.phoneNavigation_navContainer__1EF2T{
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 500;
  color: #282828;
  cursor: pointer;
}
.phoneNavigation_chevronIcon__2XhWE{
  background-image: url(/static/media/leftChevron.42e1408b.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 24px;
  height: 24px;
}
.phoneNavigation_regresarText__2MJG_{
  color: #282828;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.088px;
}
.QRBottomSheet_mainContainer__cBxs9{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.QRBottomSheet_title__3gApq{
  text-align: center;
  font-size: 24px;
  margin-bottom: 24px;
  font-weight: 600;
}
.QRBottomSheet_qrImage__2CzLu{
  width: 100%;
  max-height: 408px;
  max-width: 408px;
  align-self: center;
  height: 100%;
  padding: 20px;
}
.QRBottomSheet_textContainer__30ZU1{
  border-radius: 10px;
  background-color:#F4F6F8;
  padding: 16px 36px;
  grid-gap: 16px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.QRBottomSheet_textContainer__30ZU1 span{
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
}
.QRBottomSheet_textContainer__30ZU1 p{
  margin-bottom: 0;
}

@media screen and (max-width: 570px) {
  .QRBottomSheet_textContainer__30ZU1{
    padding: 16px;
  }
  .QRBottomSheet_textContainer__30ZU1 span{
  font-size: 14px;
  line-height: 14px;
}
.QRBottomSheet_textContainer__30ZU1 p{
  font-size: 12px;
  line-height: 12px;
}
}
