.react-calendar {
  border-radius: 4px !important;
}

.react-calendar__month-view__days__day--weekend abbr {
  color: var(--blueRNB) !important;
}

.react-calendar__tile--now {
  background: #80b7f9;
  color: #fff;
  border-radius: 4px;
}

.react-calendar__tile--active {
  background: var(--blueRNB) !important;
  border-radius: 3px !important;
  color: white !important;
}

.react-calendar__tile--active abbr {
  color: white !important;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6 !important;
  border-radius: 4px !important;
}
.react-calendar__tile--hasActive {
  background: var(--blueRNB) !important;
  color: #fff !important;
  border-radius: 4px !important;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  border-radius: 4px !important;
}

.react-calendar__tile--hasActive abbr {
  color: #fff !important;
}

.react-calendar button abbr {
  color: rgb(117, 117, 117);
}
