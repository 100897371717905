.myTextArea {
    padding: 9.5px 16px;
    /* color: #4d4d4d; */
    font-weight: normal;
    border: 1px solid #ccc;
    width: 100%;
    /* background-color: #f5f7fa; */
    background-color: hsl(0, 0%, 100%);
    border-radius: 8px;
    outline: 0;
    font-size: 16px;
}

.myTextArea:disabled {
    background-color: #ffffff;
    color: #999999a1;
    opacity: 0.5;
}
