.title{
  font-size: 32px;
  font-weight: 500;
  letter-spacing: -0.64px;
  color: #282828;
}
.text{
  font-size: 16px;
  color: #52606C;
  letter-spacing: 0.32px;
}
.benefitContainer{
  display:grid;
  grid-template-columns: repeat(2, 1fr);
  gap:16px;
  padding-bottom: 60px;
}
@media (max-width: 925px) {
.benefitContainer{
    grid-template-columns: 1fr;

}
  }
@media (max-width: 610px) {
  .text{
    display: none;
  }
  .title{
    display: none;
  }
  .benefitContainer{
    grid-template-columns: repeat( 2, 1fr);
    gap: 24px;
    margin-bottom: 24px;
  }
}
@media (max-width: 375px){
  .benefitContainer{
    grid-template-columns: repeat( 2, 1fr);
  }
}