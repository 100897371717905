footer {
    background: linear-gradient(87.99deg, #006df2 3.65%, #01affa 121.61%);
    padding: 59px 64px 30px 64px;
    position: relative;
}

footer.footer_premium {
    background: #0d2675;
}

.footer-wrapper {
    max-width: 1300px;
    /* border: 1px solid #d4d4d4; */
    margin: 0 auto;
    position: relative;
    color: #ffffffb6;
}

.footer-arrow {
    position: absolute;
    right: 80px;
    /* top: 60px; */
    z-index: 1000;
}

.footerNav {
    padding: 10px;
    margin-bottom: 32px;

    width: 480px;
    /* height: 50px; */

    background: #ffffff25;
    border-radius: 8px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footerNav a {
    color: #fff;
    padding: 9px 20px;
    border-radius: 9px;
    border: 2px solid transparent;
}

.footerNav a:hover {
    border: 2px solid #fff;
    transition: all 0.2s ease-in-out;
    color: #f1f1f1;
}

.footerNav a:active {
    border: 2px solid #fff;
    transition: all 0.2s ease-in-out;
    color: #f1f1f1;
}

.footerContact-mobile {
    display: none;
}

.footerContact {
    padding: 35px 10px 15px 10px;

    width: 480px;

    background: #ffffff25;
    border-radius: 8px;
    margin-bottom: 54px;
}

.footerContact h2 {
    font-weight: 400;
    font-size: 25px;
    line-height: 29px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 31px;
}

.footerContact .btn-footerContact {
    border: 2px solid transparent;
    padding: 9px 10px;
    /* margin: 10px; */
    background: #ffffff25;
    color: #fff;
    margin-left: 10px;
    border-radius: 10px;
    cursor: pointer;
}

.footerContact .btn-footerContact:hover {
    border: 2px solid #fff;
    transition: all 0.2s ease-in-out;
}

.btn-footerContact_container {
    display: flex;
    justify-content: center;
    align-content: center;
    margin-bottom: 10px;
}

.footerContact .btn-footerContact span {
    font-weight: 300;
    margin-left: 10px;
}

.footerDivider {
    background: #f9f9f9;
    opacity: 0.25;
    height: 2px;
}

.copyright {
    margin: 20px 0;
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-around;
    align-items: center;
    align-content: center;
}

.copyright p {
    font-weight: 400;
    font-size: 15px;
    line-height: 15px;
    text-align: center;
    color: #ffffff80;
}

.svg-pattern {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0 !important;
    /* border: 1px solid red; */
    width: 575px;
}
.svg-pattern img {
    width: 100%;
    opacity: 0.2;
}

.footer-gridContainer {
    display: grid;
    grid-template-columns: 0.7fr 1fr;
    grid-template-rows: 1fr;
}

.footer-sugerencias {
    padding: 22px;
    width: 380px;
    background: #ffffff25;
    border-radius: 8px;
}

.footer-sugerencias p {
    margin-top: 18px;
    line-height: 30px;
    color: #fff;
}

.footer-sugerencias .btnsugerencia {
    display: block;
    width: max-content;
    margin-top: 22px;

    color: #d4d4d4;
    background: #f7f9fb;
    padding: 10px 25px;
    border-radius: 8px;
    border: 2px solid transparent;
    transition: all 0.3s ease-in-out;
}

.footer-sugerencias .btnsugerencia:hover {
    color: #4d4d4d;
    background: #f7f9fb;
    border: 2px solid #d4d4d4;
    transition: all 0.3s ease-in-out;
}

.footer-facebook-icon,
.footer-instagram-icon {
    margin-left: 16px;
    margin-left: 16px;
}

@media only screen and (min-width: 1580px) {
    .footer-nav-contact {
        justify-self: end;
    }
}

@media only screen and (max-width: 1064px) {
    .footer-gridContainer {
        display: flex;
        flex-direction: column-reverse;
    }

    .footer-nav-contact {
        grid-row: 1;
    }

    .footer-sugerencias_container,
    .footer-nav-contact {
        justify-self: center;
    }

    .footerContact {
        display: none;
    }

    .footerContact-mobile {
        display: block;
        margin-top: 35px;
        margin-bottom: 35px;
        width: 100%;
        height: max-content;
    }

    .btn-footerContact-mobile {
        padding: 15px 25px;
        margin: 0 8px 0 0;
        width: max-content;
        background: #ffffff25;
        border-radius: 8px;
        color: #ffffff;
        font-weight: 300;
        cursor: pointer;
        outline: none;
        border: none;
    }

    .btn-footerContact-mobile:hover {
        color: #ffffff;
    }

    .btn-footerContact-mobile svg {
        margin: 0 8px 0 0;
        width: 14px;
    }

    .btn-footerContact-mobile .footer-whatsapp-icon {
        width: 18px;
    }

    .svg-pattern {
        position: absolute;
        top: 120px;
        left: 30px;
    }

    .svg-pattern img {
        opacity: 0.1;
    }
}

@media only screen and (max-width: 800px) {
    footer {
        padding: 30px 20px 40px 20px;
        font-family: Roboto;
    }

    .footer-sugerencias,
    .footerContact-mobile {
        width: 80%;
    }

    .footer-sugerencias .btnsugerencia {
        display: block;
        width: 100%;
        margin-top: 22px;
        font-size: 15px;
        text-align: center;
        color: #4d4d4d;
        padding: 10px 5px;
    }

    .footerContact-mobile {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 15px;
    }

    .btn-footerContact-mobile {
        padding: 15px 0px;
        width: 100%;
        margin: 0;
        display: flex;
        justify-content: center;
    }

    .footer-arrow {
        /* right:20px;
     top: 30%; */

        bottom: 30%;
        right: 21px;
    }

    .footerNav {
        width: 100%;
    }
    .footerNav a {
        padding: 5px;
    }

    .footer-socialnetworks {
        position: absolute;
        /* bottom: 23%;
        right:0; */
        right: 0;
        top: 30%;

        display: flex;
        flex-direction: column;
    }

    .footer-facebook-icon,
    .footer-instagram-icon {
        padding: 0px;
        margin: 8px 0;
        width: 55px;
        height: 55px;
        background: #ffffff25;
        border-radius: 8px;

        display: flex;
        justify-content: center !important;
        align-content: center;
        align-items: center;
    }

    .footer-facebook-icon svg,
    .footer-instagram-icon svg {
        transform: scale(1.3);
        /* margin: auto !important; */
    }
}

@media only screen and (max-width: 630px) {
    .copyright > p {
        font-size: 13px;
    }
}
