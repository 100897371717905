.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 25px 32px;
    min-width: 266.53px;
    width: 30.792%;
    height: 242px;
}
.card h3 {
    margin: 0;
    font-family: "Roboto";
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
}
.card p {
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    margin: 0;
}
.cardIcon {
    background-repeat: no-repeat;
    background-position: center;
    background-color: #0E2777;
    border-radius: 1000px;
    width: 64px;
    height: 64px;
    flex-shrink: 0;
}
.icono1 {
    background-image: url(../../assets/truck-fast.svg);
}
.icono2 {
    background-image: url(../../assets/discount-shape.svg);
}
.icono3 {
    background-image: url(../../assets/clock.svg);
}
.icono4 {
    background-image: url(../../assets/wallet-add.svg);
}
.icono5 {
    background-image: url(../../assets/truck-fast.svg);
}
@media screen and (max-width: 820px) {
    .card {
        gap: 0;
        height: fit-content;
    }
    .card h3 {
        font-size: 20px;
        margin-block: 10px;
    }
}
@media screen and (max-width: 710px) {
    .card {
        max-width: 343px;
        width: 100%;
    }
}
