.navContainer{
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 500;
  color: #282828;
  cursor: pointer;
}
.chevronIcon{
  background-image: url('../../assets/leftChevron.svg');
  background-repeat: no-repeat;
  background-size: contain;
  width: 24px;
  height: 24px;
}
.regresarText{
  color: #282828;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.088px;
}