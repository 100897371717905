
.step1Container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.titleContainer{
  display: flex;
  align-items: center;
  margin-right: auto;
}

.step1TextContainer{
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.step1TextContainer p{
  margin-bottom: 24px;
  font-size: 16px;
  color: #52606C;
}

.nextStepButtonContainer{
  display: flex;
  justify-content: end;
}

.step1Icon{
  margin-right: 20px;
  width: 27.1px;
  height: 24.42px;
  background-image: url(./assets/warning.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.titleContainer span{
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;
  color: #282828;
}

.addCardContent {
  min-height: 420px;
}
.header {
  margin-bottom: 25px;
}
.header h1 {
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 25px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #4d4d4d;
}
.header p {
  margin-bottom: 0;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: rgba(77, 77, 77, 0.8);
}

.formGroup {
  margin-bottom: 24px;
}

.nombreFlex {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
}

.inputCustom{
  background-color:#F5F7FA !important;
  border: none !important;
  border-radius: 8px !important;
}

.formcc_input {
  height: 40px;
  background-color: #F5F7FA;
  /* background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee); */
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 8px;
  display: inline-block;
  line-height: 1;
  width: 100%;
  -webkit-animation: animation-bzdot9 1.2s ease-in-out infinite;
  animation: animation-bzdot9 1.2s ease-in-out infinite;
}

.botonDisabled {
  background-color: #006df285 !important;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.cryptContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
}
.cryptIcon {
  display: flex;
  width: 17px;
  height: 17px;
  background-image: url(./assets/cryptIcon.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.cryptText {
  font-weight: 400;
  font-size: 13px;
  line-height: 16.25px;
  color: #4d4d4d;
  margin-left: 8px;
}
.errorContainer {
  width: 100%;
}
.buttonSubmitContainer {
  width: 100%;
}

@media (min-width: 500px) {
  .footer {
    flex-direction: row;
  }
  .cryptContainer {
    margin-bottom: 0px;
  }
  .buttonSubmitContainer {
    display: flex;
    justify-content: flex-end;
    width: auto;
  }
  .buttonSubmitContainer section.boton {
    width: 108px;
  }
}
