.container {
    display: block;
    position: relative;
    padding-left: calc(20px + 10px);
    cursor: pointer;
    font-size: 13px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-weight: 400;
    font-size: 13px;
    color: #4d4d4d;
    margin-bottom: 0;
}

/* Hide the browser's default checkbox */
.container .checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    box-sizing: border-box;
    border-radius: 5px;
}

/* On mouse-over, add a grey background color */
.container:hover .checkbox ~ .checkmark {
    /* background-color: #ffffff; */
}

/* When the checkbox is checked, add a blue background */
.container .checkbox:checked ~ .checkmark {
    /* background-color: #006ef2; */
    background-position: center;
    transition: background 0.8s;
}

.container .checkbox:checked:hover ~ .checkmark {
    background: #035ece radial-gradient(circle, transparent 1%, #035ece 1%) center/15000%;
}

.container .checkbox:not(:checked):hover ~ .checkmark {
    background: rgba(0, 0, 0, 0.04) radial-gradient(circle, transparent 1%, rgba(0, 0, 0, 0.04) 1%)
        center/15000%;
    /* border: solid 1px #006ef2; */
}

.container .checkbox:checked ~ .checkmark {
    background-color: #006ef2;
    background-size: 100%;
    transition: background 0s;
}
.container .checkbox:not(:checked) ~ .checkmark {
    background-color: #ffffff;
    background-size: 100%;
    transition: background 0s;
    border: solid 1px #006ef2;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container .checkbox:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.error .container .checkbox:not(:checked) ~ .checkmark {
    border: 1px solid red;
    animation: shake 0.62s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
}

.error .helperText {
    color: #d32f2f;
}

.helperText {
    padding: 0px 14px 0px;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    text-align: left;
    margin-top: 3px;
    width: 100%;
}

@keyframes shake {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}

@media screen and (min-width: 768px) {
    .container {
        font-size: 16px;
        line-height: 20px;
    }
}
