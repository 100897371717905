.container {
    margin-bottom: 14px;
    width: 100%;
}

.root {
    position: relative;
    padding-top: 0px;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.4px;
}

.root .input,
.root textarea {
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 10px 14px 10px;
    font-size: 16px;
    line-height: inherit;
    color: #52606c;
    border-style: solid;
    border-width: 1px;
    border-color: transparent #dce0e4 #dce0e4;
    border-radius: 8px;
    -webkit-text-fill-color: currentColor;
    background-color: transparent;
    caret-color: #006ef2;
    transition: border 0.2s, box-shadow 0.2s;
}

.root.fieldIcon .input,
.root textarea {
    padding-right: 50px;
}

.root .input:not(:focus):placeholder-shown,
.root textarea:not(:focus):placeholder-shown {
    border-top-color: #dce0e4;
}

.root .input + .label,
.root textarea + .label {
    display: flex;
    width: 100%;
    max-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 13px;
    line-height: 3px;
    color: #919eab;
    cursor: text;
    transition: color 0.2s, font-size 0.2s, line-height 0.2s;
}

.root .label {
    margin-bottom: 0;
}

.root .input:not(:focus):placeholder-shown + .label,
.root .textarea:not(:focus):placeholder-shown + .label,
.root .input:not(:focus):placeholder-shown:not(:-webkit-autofill) + .label {
    font-size: 16px;
    line-height: 46px;
}

.root .input + .label::before,
.root .input + .label::after,
.root textarea + .label::before,
.root textarea + .label::after {
    content: "";
    display: block;
    box-sizing: border-box;
    height: 8px;
    min-width: 10px;
    margin-top: 0px;
    border-top: solid 1px #dce0e4;
    pointer-events: none;
    box-shadow: inset 0 1px transparent;
    transition: border 0.2s;
}

.root .input + .label::before,
.root textarea + .label::before {
    margin-right: 4px;
    border-left: solid 1px transparent;
    border-radius: 8px 0;
}

.root .input + .label::after,
.root textarea + .label::after {
    flex-grow: 1;
    margin-left: 4px;
    border-right: solid 1px transparent;
    border-radius: 0 8px;
}

.root .input:not(:focus):placeholder-shown + .label::before,
.root .input:not(:focus):placeholder-shown + .label::after,
.root textarea:not(:focus):placeholder-shown + .label::before,
.root textarea:not(:focus):placeholder-shown + .label::after {
    border-top-color: transparent;
}

.root .input:focus,
.root textarea:focus {
    border-color: transparent #006ef2 #006ef2;
    outline: none;
}

.root .input:focus + .label,
.root textarea:focus + .label {
    color: #006ef2;
}

.root .input:focus + .label::before,
.root .input:focus + .label::after,
.root textarea:focus + .label::before,
.root textarea:focus + .label::after {
    border-top-color: #006ef2;
}

.root .input:disabled,
.root .input:disabled + .label,
.root textarea:disabled,
.root textarea:disabled + .label {
    color: rgba(189, 192, 197, 0.5);
    border-color: transparent rgba(189, 192, 197, 0.5) rgba(189, 192, 197, 0.5);
    cursor: not-allowed;
}

.root .input:disabled + .label::before,
.root .input:disabled + .label::after,
.root textarea:disabled + .label::before,
.root textarea:disabled + .label::after {
    border-top-color: rgba(189, 192, 197, 0.5);
}

.root .input:disabled:placeholder-shown,
.root .input:disabled:placeholder-shown + .label,
.root textarea:disabled:placeholder-shown,
.root textarea:disabled:placeholder-shown + .label {
    border-top-color: rgba(189, 192, 197, 0.5);
}

.root .input:disabled:placeholder-shown + .label::before,
.root .input:disabled:placeholder-shown + .label::after,
.root textarea:disabled:placeholder-shown + .label::before,
.root textarea:disabled:placeholder-shown + .label::after {
    border-top-color: transparent;
}

.error .root .input,
.error .root textarea {
    border-color: transparent #d32f2f #d32f2f;
}

.error .root .input + .label,
.error .root textarea + .label {
    color: #d32f2f;
}

.error .root .input + .label::before,
.error .root .input + .label::after,
.error .root textarea + .label::before,
.error .root textarea + .label::after {
    border-top-color: #d32f2f;
}

.error .root .input:not(:focus):placeholder-shown,
.error .root .input:not(:focus):placeholder-shown + .label,
.error .root textarea:not(:focus):placeholder-shown,
.error .root textarea:not(:focus):placeholder-shown + .label {
    border-top-color: #d32f2f;
    color: #d32f2f;
}

.error .root .input:not(:focus):placeholder-shown + .label::before,
.error .root .input:not(:focus):placeholder-shown + .label::after,
.error .root textarea:not(:focus):placeholder-shown + .label::before,
.error .root textarea:not(:focus):placeholder-shown + .label::after {
    border-top-color: transparent;
}

.error .root .input:focus,
.error .root textarea:focus {
    border-color: transparent #d32f2f #d32f2f;
}

.error .root .input:focus + .label,
.error .root textarea:focus + .label {
    color: #d32f2f;
}

.error .root .input:focus + .label::before,
.error .root .input:focus + .label::after,
.error .root textarea:focus + .label::before,
.error .root textarea:focus + .label::after {
    border-top-color: #d32f2f;
}

.iconContainer {
    display: none;
    position: absolute;
    right: 4px;
    bottom: 4px;
    width: 42px;
    height: 42px;
    padding: 8px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50%;
    background-position: center;
    transition: background 0.8s;
}
.root.fieldIcon .iconContainer {
    display: flex;
}
.root.fieldIcon .icon {
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 24px;
}

.iconContainer:hover {
    background: rgba(0, 0, 0, 0.04) radial-gradient(circle, transparent 1%, rgba(0, 0, 0, 0.04) 1%)
        center/15000%;
}

.iconContainer:active {
    background-color: rgba(0, 0, 0, 0.08);
    background-size: 100%;
    transition: background 0s;
}

.icon.password {
    background-image: url(./../../../../icons/eye.svg);
}

.icon.passwordOff {
    background-image: url(./../../../../icons/eye-off.svg);
}

.input {
    -webkit-text-fill-color: #52606c !important;
    -webkit-background-clip: text !important;
    background-clip: text !important;
}

.input:-webkit-autofill,
.input:-webkit-autofill::first-line {
    font-size: 16px !important;
}

.helperText {
    padding: 0px 14px 0px;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    text-align: left;
    margin-top: 3px;
}

.error .helperText {
    color: #d32f2f;
}

@media (max-width: 425px) {
    .container {
        margin-bottom: 16px;
    }
    .root .input,
    .root textarea {
        padding: 8px 14px;
        font-size: 13px;
    }
    .root .input:not(:focus):placeholder-shown + .label,
    .root .textarea:not(:focus):placeholder-shown + .label,
    .root .input:not(:focus):placeholder-shown:not(:-webkit-autofill) + .label {
        line-height: 40px;
        font-size: 13px;
    }
    .iconContainer {
        bottom: 0px;
        width: 42px;
        height: 42px;
        padding: 8px;
    }
}

@media not all and (min-resolution: 0.001dpcm) {
    .root > .input,
    .root > .input + .label,
    .root > .input + .label::before,
    .root > .input + .label::after,
    .root > textarea,
    .root > textarea + .label,
    .root > textarea + .label::before,
    .root > textarea + .label::after {
        transition-duration: 0.1s;
    }
}
