.container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.stepsContent,
.stepsContent2 {
    display: flex;
    height: 88px;
    max-width: 500px;
    width: 100%;
    padding: 44px;
}
.stepsContent {
    margin: 0 0;
}
.stepsContent2 {
    margin: 0 0;
}
ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.stepNumber,
.stepNumberFill,
.stepNumberVoid {
    min-width: 40px;
    min-height: 40px;
    position: relative;
    background-size: 100% 100%;
    margin: -0.5px;
    display: flex;
    align-self: center;
}
.stepNumber:nth-child(1) {
    background-image: url("./assets/step1.svg");
    background-repeat: no-repeat;
}
.stepNumber:nth-child(3) {
    background-image: url("./assets/step2.svg");
    background-repeat: no-repeat;
}
.stepNumberVoid {
    background-image: url("./assets/step3Void.svg");
    background-repeat: no-repeat;
}
.stepNumberFill {
    background-image: url("./assets/step3Fill.svg");
    background-repeat: no-repeat;
}
.stepText,
.stepTextVoid,
.stepTextFill {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 24px;
    text-align: center;
    position: absolute;
    width: 105px;
    left: 50%;
    transform: translateX(-50%);
    bottom: -30px;
}
.stepText,
.stepTextFill {
    color: #282828;
}
.stepTextVoid {
    color: #637381;
}
.barSeparatorFill,
.barSeparatorVoid,
.barSeparator {
    width: 100%;
    height: 1.5px;
    display: flex;
    align-self: center;
}
.barSeparator {
    border-top: 1.5px solid #0d2675;
}
.barSeparatorFill {
    border-top: 1.5px solid #0d2675;
}
.barSeparatorVoid {
    border-top: 1.5px solid #0d2675;
    opacity: 0.5;
}

@media (min-width: 345px) {
    .stepText,
    .stepTextFill,
    .stepTextVoid {
        font-size: 13px;
    }
    .stepsContent,
    .stepsContent2 {
        padding: 0 45px;
    }
    .stepsContent {
        margin: 0 0;
    }
}
@media (min-width: 768px) {
    .stepsContent {
        padding: 0 30px;
    }
    .stepsContent2 {
        padding: 0 30px;
    }
    .stepsContent,
    .stepsContent2 {
        max-width: 100%;
    }
}
