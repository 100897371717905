.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #fff;
    border-radius: 10px;
    justify-content: space-between;
    padding: 40px 60px;
    box-sizing: border-box;
}

.sucursalName {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #282828;
}

.sucursalDireccion {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #4d4d4d;
}

.parent {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 4px;
    padding: 0 80px;
    margin: 0;
}

.parent p {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: rgba(40, 40, 40, 0.75);
    margin: 0;
}

.btnContainer {
    display: flex;
    flex-direction: row;
    gap: 24px;
    justify-content: center;
    margin-top: 16px;
}

.locationBtn {
    flex: 1;
    display: flex;
    flex-direction: row;
    height: 40px;
    border: 1px solid rgba(212, 212, 212, 0.7);
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    padding: 10.5px 16px;
}

.locationIcon {
    background-image: url("../../assets/location.svg");
    background-repeat: no-repeat;
    background-size: contain;
    width: 11px;
    height: 16px;
    margin-right: 8px;
}

.btnTxt1 {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    align-content: center;
    color: #4d4d4d;
    margin-top: 16px;
    padding: 0;
}

.waBtn {
    height: 40px;
    background: #128c7e;
    border: 1px solid rgba(212, 212, 212, 0.7);
    border-radius: 8px;
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 12px 20px;
}

.waIcon {
    background-image: url("../../assets/whatsapp.svg");
    background-repeat: no-repeat;
    background-size: contain;
    width: 16px;
    height: 16px;
    margin-right: 5px;
}

.btnTxt2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #f8f8f8;
    margin-top: 16px;
}

@media screen and (max-width: 1300px) {
    .container {
        padding: 30px 40px;
    }

    .parent {
        padding: 0 46px;
    }

}

@media screen and (max-width: 1025px) {
    .container {
        padding-left: 20px;
        padding-right: 20px;
    }

    .parent {
        padding: 0 96px;
    }

}

@media screen and (max-width: 821px) {

    .parent {
        padding: 0 40px;
    }

    .waBtn {
        padding: 12px 40px;
    }
}

@media screen and (max-width: 500px) {
    .sucursalName {
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 24px;
    }
}

@media screen and (max-width: 415px) {
    .container {
        padding: 24px 16px;
    }

    .parent {
        padding: 0 36px;
    }

    .btnTxt1,
    .btnTxt2 {
        font-size: 13px;
        line-height: 16px;
    }

    .sucursalDireccion {
        font-size: 13px;
        line-height: 15px;
    }

    .parent p {
        font-size: 13px;
        line-height: 15px;
    }

    .waBtn {
        padding: 12px 28px;
    }

}

@media screen and (max-width: 376px) {
    .waBtn {
        padding: 12px 15px;
    }

    .parent {
        padding: 0 19px;
    }
}
