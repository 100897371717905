.direcciones {
    padding: 0 14px 0 0;
    margin: 47px -12px 0 2px;
    border-radius: 10px;
    font-size: 16px;
    overflow-y: auto;
    scroll-behavior: smooth;
    max-height: 500px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}
.direcciones::-webkit-scrollbar {
    width: 4px;
    background: rgba(201, 204, 216, 0.3);
    border-radius: 5px;
    transition: 1s ease all;
}
/* Track */
.direcciones::-webkit-scrollbar-track {
    background: #f1f1f1;
}
/* Handle */
.direcciones::-webkit-scrollbar-thumb {
    background: #c9ccd8;
    border-radius: 5px;
}
/* Handle on hover */
.direcciones::-webkit-scrollbar-thumb:hover {
    background: #87898f;
    width: 5px;
}
.dirTitle {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.02em;
    color: #4d4d4d;
    margin: 0;
    position: absolute;
    top: 32px;
    left: 16px;
}
.dirContainer,
.dirContainerUnable {
    min-height: 64px;
    cursor: pointer;
    background: transparent;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* max-width: 360px; */
    margin-bottom: 16px;
    width: 100%;
}
.dirContainer {
    padding: 16px 24px;
    border: 1.5px solid rgba(212, 212, 212, 0.5);
}
.dirContainerUnable {
    padding: 16px;
    border: 1px solid rgba(212, 212, 212, 0.25);
    color: #919eab;
}

.active {
    border-color: #0d2675;
}

.dirSelect {
    display: flex;
    align-items: center;
}
.direcciones:last-of-type {
    border: 0;
}

.aliasDireccion {
    color: #4d4d4d;
    font-size: 16px;
    font-weight: 500;
    line-height: 125%;
    letter-spacing: -0.09px;
    margin: 0;
}

.detalleDireccion {
    font-size: 13px;
    line-height: 12px;
    font-weight: 400;
    letter-spacing: 0.02em;
    margin: 0;
    color: #52606c;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 200px;
}
.text,
.textUnable {
    max-width: 270px;
    /* background-color: yellowgreen; */
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    text-align: left;
}
.textUnable > p {
    color: #919eab;
}

.fav {
    margin-left: 8px;
    width: 25.13px;
}
.radioIcon {
    min-width: 24px;
    width: 24px;
    height: 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("../../assets/radioIconOff.svg");
    margin-right: 8px;
    cursor: pointer;
    /* background-color: yellowgreen; */
}
.radioIcon.active {
    background-image: url("../../assets/radioIconOn.svg");
}
.btnContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 16px;
    margin: 16px 4px 0;
}
.btnCancel {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px 10px;
    gap: 8px;
    width: auto;
    min-width: 89px;
    width: 100%;
    height: 40px;
    background-color: #ffffff;
    border: 1.5px solid rgba(212, 212, 212, 0.5);
    border-radius: 8px;
    cursor: pointer;
}
.btnCancel p {
    font-weight: 500;
    font-size: 15px;
    line-height: 12px;
    color: #282828;
    text-align: center;
    margin: 0px 0;
}
.btnSave {
    max-width: 100%;
    width: 100%;
}
.infoMsg {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    align-items: center;
    margin-top: 24px;
    padding: 0 4px;
}
.infoIcon {
    width: 20px;
    height: 20px;
}
.infoDescription {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 125%;
    color: #4d4d4d;
    margin: 0;
}

@media (min-width: 570px) {
    /* .dirContainer {
        padding: ;
    } */
    .direcciones {
        padding: 0 20px 0 4px;
        margin: 45px -16px 0 0;
    }
    .dirTitle {
        top: 32px;
        left: 24px;
        font-size: 25px;
        line-height: 29px;
    }
    .aliasDireccion {
        font-size: 16px;
    }
    .textUnable {
        max-width: 340px;
    }
    .detalleDireccion {
        line-height: 12px;
        max-width: 340px;
    }
    .btnContainer {
        justify-content: flex-end;
        margin: 10px 0 0;
    }
    .btnCancel {
        width: auto;
    }
    .infoMsg {
        margin-top: 8px;
        column-gap: 8px;
        padding: 0 20px;
    }
    .infoIcon {
        width: 32px;
        height: 32px;
    }
    .infoDescription {
        font-size: 16px;
        line-height: 20px;
    }
}
