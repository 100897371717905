.copyclip {
    width: 36px;
    height: 25px;
    background-image: url(./assets/copyclip.svg);
    background-size: 12px 18px;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
}


.new {
    width: 24px;
    height: 24px;
    background-image: url(./assets/copyclip-new.svg);
    background-size: contain;
}

.new:hover,
.new_active {
    cursor: pointer;
    background-image: url(./assets/copy_icon_hover.svg);
}

/* Base styles for the element that has a tooltip */
.copyclip_tooltip {
    position: relative;
    cursor: pointer;
}

/* Base styles for the entire tooltip */
.copyclip_tooltip:before,
.copyclip_tooltip:after {
    position: absolute;
    visibility: hidden;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
        -webkit-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
    -moz-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
        -moz-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
        transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    pointer-events: none;
}

.copyclip_tooltip.tooltipAc:before,
.copyclip_tooltip.tooltipAc:after,
.copyclip_tooltip.tooltipAc:before,
.copyclip_tooltip.tooltipAc:after {
    visibility: visible;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
}

/* Base styles for the tooltip's directional arrow */
.copyclip_tooltip:before {
    z-index: 1001;
    border: 6px solid transparent;
    background: transparent;
    content: "";
}


/* Base styles for the tooltip's content area */
.copyclip_tooltip:after {
    z-index: 1000;
    padding: 4px 8px;
    background-color: #006ef4;
    color: #fff;
    content: attr(data-tooltip);
    text-align: center;
    font-size: 13px;
    line-height: 100%;
    border-radius: 4px;
}

.copyclip_tooltip:before,
.copyclip_tooltip:after {
    bottom: 50%;
    left: 100%;
}

.copyclip_tooltip:before {
    margin-bottom: -6px;
    margin-left: -11px;
    border-top-color: transparent;
    border-right-color: #006ef4;
}

.copyclip_tooltip.tooltipAc:before,
.copyclip_tooltip.tooltipAc:after {
    -webkit-transform: translateX(12px);
    -moz-transform: translateX(12px);
    transform: translateX(12px);
}

.copyclip_tooltipright:before {
    top: 3px;
}

.copyclip_tooltip:after {
    margin-left: 0;
    margin-bottom: -14px;
}

.copyclip_tooltipB:after {
    margin-left: 0;
    margin-bottom: -16px;
}


.copyclip_tooltip.left:after {
    transform: translate(-60px, -35px);
}

.copyclip_tooltip.left:before {
    transform: translate(13px, -7px);
    rotate: 270deg;
}