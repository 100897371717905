.subTitle {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.02em;
    color: #282828;
    margin: 0 0;
    font-size: 25px;
    line-height: 100%;
}
@media (min-width) {
    .subTitle {
        font-size: 32px;
    }
}
