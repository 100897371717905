.container {
    margin-bottom: 24px;
    width: 100%;
    max-width: 500px;
}
.title {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #282828;
    margin-bottom: 8px;
}
.description {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #637381;
}

@media screen and (min-width: 769px) {
    .container {
        margin-bottom: 36px;
    }
    .title {
        font-size: 24px;
        line-height: 36px;
    }
    .description {
        font-size: 16px;
        line-height: 24px;
    }
    .titleAlter {
        font-size: 34px;
        line-height: 36px;
    }
}
