.container {
    padding: 0 24px;
}

.row {
    padding: 9px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.justifyCenter {
    justify-content: center;
}

.submitContainer {
    margin: 16px 0;
}

.text {
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    color: #282828;
}

.header {
    margin-left: 24px;
}

@media screen and (min-width: 769px) {
    .container {
        padding: 0;
    }

    .submitContainer {
        margin: 24px 0;
    }

    .submitBtn {
        height: 46px !important;
        font-weight: 500 !important;
        font-size: 16px !important;
        line-height: 26px !important;
    }

    .text {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #282828;
    }

    .header {
        margin-bottom: 77px;
        margin-left: 0;
    }
}
