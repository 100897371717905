.image {
  display: block;
  margin: 20px auto 0;
}

.footer {
  padding: 12px 24px 0;
}

.des {
  color: #4d4d4d;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  padding-bottom: 16px;
}

.title {
  font-weight: 500;
  font-size: 25px;
  margin-bottom: 24px;
}

.btn {
  color: #006ef2;
  background: #f5f7fa;
  border-radius: 5px;
  border: 1px solid rgba(212, 212, 212, 0.5);
  font-size: 15px;
  font-weight: 500;
  padding: 10px 0;
  width: 100%;
  text-align: center;
  cursor: pointer;
  background-image: url(./assets/card-add.svg);
  background-repeat: no-repeat;
  background-position: 5% 50%;
}
