.mainContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.title{
  text-align: center;
  font-size: 24px;
  margin-bottom: 24px;
  font-weight: 600;
}
.qrImage{
  width: 100%;
  max-height: 408px;
  max-width: 408px;
  align-self: center;
  height: 100%;
  padding: 20px;
}
.textContainer{
  border-radius: 10px;
  background-color:#F4F6F8;
  padding: 16px 36px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.textContainer span{
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
}
.textContainer p{
  margin-bottom: 0;
}

@media screen and (max-width: 570px) {
  .textContainer{
    padding: 16px;
  }
  .textContainer span{
  font-size: 14px;
  line-height: 14px;
}
.textContainer p{
  font-size: 12px;
  line-height: 12px;
}
}