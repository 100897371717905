.container {
  padding: 24px 0 0 0;

  height: max-content;
  opacity: 0.5;
  pointer-events: none;
}

.container.row {
  gap: 15px;
  display: grid;
  grid-template-columns: auto auto auto;
}

.container.column {
  margin-bottom: 15px;
}

.button {
  margin-bottom: 15px;
}

.pay_enabled {
  opacity: 1;
  pointer-events: auto;
}

.disabledReason {
  margin-top: 24px;
  text-align: center;
}

@media only screen and (max-width: 650px) {
  .container {
    grid-template-columns: 1fr;
  }
}
