.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 98px;
    height: 40px;
    border: 1px solid rgba(145, 158, 171, 0.32);
    background-color: #fff;
    border-radius: 8px;
    margin-right: 16px;
    cursor: pointer;
    transition: filter 0.2s ease-in-out;
}
.button:last-child {
    margin-right: 0;
}
.button:hover {
    filter: brightness(0.96);
}
.icon {
    width: 24px;
    height: 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.google {
    background-image: url(/src/icons/google.svg);
}
.facebook {
    background-image: url(/src/icons/facebook.svg);
}
.apple {
    background-image: url(/src/icons/apple.svg);
}

@media screen and (min-width: 769px) {
    .button {
        width: 156px;
        height: 48px;
    }
}
