.infoach_overlay {
  background: #000;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 99999;
  opacity: 0.6;
}

.infoach_modal {
  position: fixed;
  z-index: 99999;
  left: 50%;
  top: 50%;
  background: #fff;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  padding: 20px;
}

.infoach_header {
  display: flex;
  margin-bottom: 15px;
}

.infoach_logo {
  width: 22px;
  height: 25px;
  background-image: url(./assets/logo_ach.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.infoach_title {
  font-size: 17px;
  font-weight: 500;
  color: #4d4d4d;
  margin-left: 10px;
}

.infoach_close {
  width: 24px;
  height: 24px;
  background-image: url(./assets/close_modal.svg);
  background-size: 12px 12px;
  background-position: center;
  background-repeat: no-repeat;
  margin-left: auto;
  cursor: pointer;
}

.infoach_body {
  border-top: 1px solid #e1e3ea;
  border-bottom: 1px solid #e1e3ea;
  padding: 15px 10px;
}

.infoach_dato {
  color: #4d4d4d;
  font-size: 15px;
  line-height: 20px;
  padding-bottom: 15px;
}

.infoach_dato:last-of-type {
  padding-bottom: 0;
}

.infoach_dato_label {
  opacity: 0.5;
}

.infoach_dato_value {
  display: flex;
  margin-right: 12px;
}

.infoach_disclaimer {
  font-size: 13px;
  color: #a6a6a6;
  margin-top: 15px;
  width: 288px;
}

.infoach_b {
  color: #006ef2;
}
