.formGroup {
  margin-bottom: 24px;
}

.nombreFlex {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
}
.formGroup label {
  opacity: 0.5;
  font-weight: 400;
  font-size: 16px;
  line-height: 12px;
  letter-spacing: 0.02em;
}

.formcc_input {
  opacity: 0.5;
  height: 40px;
  line-height: 1;
  width: 100%;
  background: #f5f7fa;
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  padding: 16px 9.5px;
  color: #4d4d4d;
}
input.confirmCVV {
  outline: rgb(0, 0, 0) none 0px;
  outline-style: none;
  outline-offset: 0px;
  width: 100%;
  font-weight: 300;
  border: 1px solid transparent;
  border-style: solid solid solid solid;
  padding: 9.5px 16px;
  font-size: 16px;
  border-radius: 8px;
  background-color: #f5f7fa;
  color: #4d4d4d;
  -webkit-font-smoothing: antialiased;
  text-rendering: geometricPrecision;
}
.cvvContainer label {
  opacity: 1;
}

.botonDisabled {
  background-color: #006df285 !important;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.errorContainer {
  width: 100%;
  margin-top: 20px;
}

@media (min-width: 500px) {
  .footer {
    flex-direction: row;
  }
  .buttonSubmitContainer {
    display: flex;
    justify-content: flex-end;
    width: auto;
  }
  .buttonSubmitContainer section.boton {
    width: 108px;
  }
}
