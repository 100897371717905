.errorMsgContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    gap: 8px;
    width: 100%;
    min-height: 48px;
    background: rgba(224, 0, 33, 0.1);
    border-radius: 8px;
    margin-top: 16px;
    position: relative;
}
.errorMsgContainer > p {
    margin: 0;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #B3001A;
}
.alertIcon {
    width: 24px;
    height: 24px;
}
@media (min-width: 580px) {
    .toRightArrow {
        position: absolute;
        right: 16px;
    }
}
@media (min-width: 768px) {
    .errorMsgContainer {
        margin-top: 32px;
        height: 64px;
    }
    .errorMsgContainer > p {
        font-size: 16px;
        list-style: 18.5px;
    }
    .alertIcon {
        width: 32px;
        height: 32px;
    }
}
