.link {
    color: rgb(0, 110, 242);
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
}
.link2 {
    font-weight: 400;
    font-size: 11px;
    line-height: 125%;
    color: #006ef2;
}

.container {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
}

.arrow {
    background-image: url("../../auth/assets/blue_arrow.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 10px;
    height: 7px;
    margin-right: 4px;
    /* margin-top: 4px; */
}
@media (min-width: 570px) {
    .link2 {
        font-size: 13px;
    }
}

@media (min-width: 769px) {
    .link {
        font-size: 16px;
        line-height: 22px;
    }
}
