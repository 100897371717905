.confimarPagoSection {
    min-height: 400px;
}
.confirmarPagoHeader {
    height: 100vh;
    max-height: 192.75px;
    border-radius: 0 0 150px 0;
    position: relative;
    color: white;
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.confirmarPagoHeaderBackground {
    position: absolute;
    z-index: -10;
    width: 972px;
    height: 865px;
    left: -47px;
    top: -500px;
    background: linear-gradient(180deg, #023382 18.03%, #0d2675 100%),
        linear-gradient(237.34deg, #120d32 19.95%, #120d34 56.23%, #006ef2 99.49%), #d9d9d9;
}
.ellipsisBackground {
    position: absolute;
    opacity: 0.5;
    width: 100%;
    height: 100vh;
    bottom: 0;
    background-image: url("../../assets/EllipseModal.svg");
    background-repeat: no-repeat;
    background-position: bottom left;
}
.headerTitle {
    font-weight: 500;
    font-size: 20px;
    line-height: 125%;
    letter-spacing: 0.02em;
    color: #ffffff;
    margin: 0;
    margin-top: 59.75px;
    width: 309px;
    text-align: center;
    margin-right: 6px;
}
.headerDescription {
    position: absolute;
    left: 18px;
    bottom: 32px;
    width: 264px;
    height: 32px;
    font-weight: 500;
    font-size: 13px;
    line-height: 125%;
    letter-spacing: 0.02em;
    color: #ffffff;
    margin: 0 0;
}
.modalStars {
    position: absolute;
    top: 140.75px;
    right: 0;
}
.modalCaption {
    padding: 24px 24px 20px;
}
.modalCaption > h1 {
    font-weight: 400;
    font-size: 13px;
    line-height: 125%;
    color: #52606c;
    margin: 0 !important;
    text-align: center;
}
.price {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: 100%;
    min-height: 64px;
    background: #f5f7fa;
    border-radius: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 46px;
    line-height: 125%;
    letter-spacing: 0.02em;
    color: #282828;
    margin-top: 8px;
}

.infoMsg {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 16px 0 24px;
    column-gap: 10px;
}
.infoDescription {
    font-weight: 400;
    font-size: 11px;
    line-height: 125%;
    color: #52606c;
    margin: 0;
}
.infoMsg > img {
    width: 24px;
    height: 24px;
}
.link {
    color: #0d2675;
}

@media (min-width: 570px) {
    .confirmarPagoHeader {
        max-height: 257px;
        border-radius: 0 0 220px 0;
    }
    .ellipsisBackground {
        background-image: url("../../assets/EllipseModalDesktop.svg");
    }
    .headerTitle {
        font-size: 25px;
        line-height: 125%;
        margin: 60px 0 0;
    }
    .headerDescription {
        font-size: 16px;
        top: 164px;
        left: 16px;
        width: 405px;
    }
    .infoMsg {
        margin: 16px 16px 24px;
    }
    .infoMsg > img {
        width: 32px;
        height: 32px;
    }
    .modalStars {
        right: 5.68px;
        width: 80.32px;
        height: 75.89px;
        top: 182.89px;
    }
    .modalCaption {
        padding: 24px 16px 25px;
    }
    .modalCaption > h1 {
        line-height: 16.25px;
    }
    .price {
    }
    .infoDescription {
        font-size: 13px;
        line-height: 16.25px;
        width: 404px;
    }
    .errorMsg{
        background-color: white;
        margin: 16px 16px 24px;
        padding: 0;
        height: fit-content;
        width: fit-content;
    }
    .errorMsg p {
        font-size: 13px;
    }
    .btnContainer {
        max-width: 420px;
        margin: 0 auto;
    }
}
