/* *resets */
.title,
.subtitle {
    margin-bottom: 0;
    font-weight: 0;
    line-height: 0;
}

/* *base styles */

.body {
    width: 100%;
    box-sizing: border-box;
}

.regresar {
    display: flex;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    margin-right: auto;
    margin-top: 17px;
}

/* *fonts */
.title {
    font-weight: 700;
    line-height: 30px;
    color: var(--title);
}

.subtitle {
    font-weight: 400;
    line-height: 24px;
    color: var(--light-gray);
}

/* *movil */
@media screen and (min-width: 325px) {
    .title {
        font-size: 24px;
        margin: 30px 0;
    }

    .subtitle {
        font-size: 13px;
        margin-top: 8px;
    }

    .inputContainer {
        width: 100%;
    }
}

/* *ipad */
/* *desktop */
@media screen and (min-width: 769px) {
    .title {
        line-height: 36px;
    }

    .subtitle {
        font-size: 16px;
    }
}
