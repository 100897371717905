.confetti {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    font-style: normal;
}
.img {
    width: 275px;
    height: 217px;
    background-image: url("./assets/aniversarioLogo.svg");
    background-size: 100% 100%;
    position: relative;
    bottom: 4px;
}
.description {
    line-height: 20px;
    font-size: 16px;
    color: #fff;
    font-weight: 400;
    margin: 8;
}
