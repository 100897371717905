.mainContainer{
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: 100%;
}
.availableCounter{
gap:4px;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
max-width: max-content;
background: rgba(253, 192, 0, 0.16);
color:#FDC000 ;
padding: 4px 8px;
border-radius: 56px;
font-size: 16;
font-weight: 500;
}
.warningIcon{
  background-image: url('./assets/warningIcon.svg');
  height: 24px;
  width: 24px;
}
.headerCard{
  display: flex;
  flex-direction: row;
  gap: 16px;
}
.logo{
  width: 64px;
  height: 64px;
  border-radius: 8px;
}
.name{
  color: #282828;
  font-size: 24px;
  font-weight: 500;
}
.category{
  max-width: max-content;
  background-color: #E5E8EB;
  padding: 4px 8px;
  border-radius: 32px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.infoContainer{
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.descriptionTitle{
  font-weight: 500;
  color: #282828;
  font-size: 16px;
}
.descriptionText{
  font-size: 16px;
  line-height: 19px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: left;
}
.linksContainer{
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.linksSubContainer{
  display: flex;
  flex-direction: row;
  gap: 8px;
  color: #0D2675;
  font-weight: 600;
  letter-spacing: 0.14px;
}
.activationButton{
  background-color: #0D2675;
  height: 42px;
  display: grid;
  place-items: center;
  color: #FFFFFF;
  font-size:16px;
  font-weight: 500;
  border-radius: 8px;
  margin-top: calc(47px - 16px);
}
.activationButtonDisabled{
  opacity: 0.5;
}
.unselectable {
-webkit-touch-callout: none;
-webkit-user-select: none;
-khtml-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
}
.activationButton:hover{
  opacity: 0.8;
  cursor: pointer;
}
.bottomLegendContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: #52606C;
  font-size: 12px;
  font-weight: 500;
}
.ClockIcon{
  background-image: url('../../../../assets/clock.svg');
  height: 12px;
  width: 12px;
}
.info{
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;;
}
.infoIcon{
  background-image: url('../../../../assets/clockInfo.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 12px;
  width: 12px;
}

  .buttonsContainer{
    height: 100%;
    gap: 16px;
    display: flex;
    flex-direction: column;
    justify-content: end;
  }
