.PhoneInput {
    width: 100%;
    border: 1px solid #dce0e4;
    border-radius: 8px;
    height: 38px;
    margin-top: 0px;
    padding: 0px;
    display: flex;
    align-items: center;
    margin-bottom: 14px;
}

.PhoneInputInput {
    width: 100%;
    height: 100%;
    color: #52606c;
    background-color: #fff;
    border-radius: 0 8px 8px 0;
    outline: none;
    border: none;
    font-size: 13px;
    border-left: 1px solid #dce0e4;
    padding-left: 15px;
}
.PhoneInputInput::placeholder {
    color: #919eab;
}
.PhoneInputCountry {
    width: 50px;

    justify-content: center;
    margin: 0;
}

.PhoneInputCountrySelec {
    display: flex;
    align-items: center;
}
.PhoneInputCountrySelectArrow {
    display: none;
}
@media (min-width: 426px) {
    .PhoneInput {
        height: 46px;
    }
    .PhoneInputInput {
        font-size: 16px;
    }
}
@media (min-width: 678px) {
    .PhoneInput {
        width: 97.2%;
    }
}
