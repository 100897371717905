.overlay {
    position: fixed;
    opacity: 0.25;
    background: #000;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    cursor: pointer;
    touch-action: none;
}

.overlay.overlayNoDismissible {
    display: none;
}

.modal {
    position: fixed;
    background: #fff;
    width: 100%;
    max-width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    box-shadow: 0 0 10px rgb(0 0 0 / 25%);
    border-radius: 20px 20px 0 0;
    z-index: 1001;
    bottom: 0;
    left: 50%;
    top: 150%;
    transform: translate(-50%, 150%);
    transition: 0.4s ease-in-out;
}

.modal.enabled {
    transform: translate(-50%, -100%);
    top: 100%;
}

.modal_body {
    padding-top: 35px;
    padding-bottom: 25px;
}

.modal_close,
.modal_close_white {
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    top: 25px;
    right: 25px;
}

.modal_close {
    background-image: url(./assets/CloseModalC.svg);
    background-color: #f6f6f6;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-size: 12px;
}

.modal_close_white {
    background-image: url("./assets/closeSvgWhite.svg");
    background-size: 100% 100%;
    width: 16px;
    height: 16px;
}

.modal_lottie {
    z-index: 1;
    width: 88%;
    height: 100%;
    margin: 0 auto;
    padding-top: 35px;
}

.modal_image {
    display: block;
    margin: 20px auto 0;
}

.modal_footer {
    padding: 25px 38px 0 38px;
}

.modal_des {
    color: #4d4d4d;
    opacity: 0.5;
    font-size: 13px;
    text-align: center;
    padding-bottom: 16px;
}

.modal_title {
    font-weight: 500;
    font-size: 25px;
    margin-bottom: 16px;
}

.modal_btn {
    background: #006ef2;
    color: #fff;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 500;
    padding: 10px 0;
    width: 100%;
    text-align: center;
    cursor: pointer;
}

@media (min-width: 570px) {
    .modal {
        border-radius: 20px;
        width: calc(100% - 16px * 2);
        max-width: 425px;
        opacity: 0;
        transform: translate(-50%, -50%);
    }
    .modal_body {
        padding: 40px;
    }
    .modal_des {
        opacity: 1;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #4d4d4d;
        padding-bottom: 24px;
    }
    .modal_footer {
        padding: 25px 0 0 0;
    }
    .modal.enabled {
        top: 50%;
        opacity: 1;
        transform: translate(-50%, -50%);
        transition: 0.2s ease-in-out;
    }
    .overlay {
        background: #000;
    }
}