.accordionCollapsed,
.accordion {
    position: relative;
    max-width: 924px;
    width: 100%;
    background: #f5f7fa;
}

.accordionCollapsed {
}
.accordion {
    /* box-shadow: 0px 12px 24px -4px rgba(145, 158, 171, 0.12); */
    border-radius: 8px;
}

.accordionTitle {
    font-weight: 600;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px 8px 12px 16px;
    cursor: pointer;
}
.arrow,
.arrowColapsed {
    margin: auto 4px;
    background-image: url("../../assets/accodion-arrow.svg");
    width: 11.69px;
    height: 5.84px;
    transition: transform 0.3s ease-in-out;
}
.arrow {
    transform: rotate(180deg);
    transition: transform 0.3s ease-in-out;
}
.accordionContentColapsed {
    font-size: 16px;
    background-color: #f5f7fa;
    height: auto;
    max-height: 0px;
    overflow: hidden;
    padding: 0px 8px 0px 16px;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
}
.accordionContent {
    font-size: 16px;
    background-color: #f5f7fa;
    height: auto;
    max-height: 999px;
    padding: 0px 8px 8px 16px;
    transition: max-height 0.5s ease-in-out;
}
.marginBottomCollapsed {
    height: 0;
    transition: all 0.2s ease-in-out;
}
.marginBottom {
    height: 16px;
    transition: all 0.2s ease-in-out;
}
.link {
    color: #0d2675;
}
@media screen and (max-width: 455px) {
    .accordionTitle {
        font-weight: 500;
    }
}
