.mainContainer {
    display: grid;
    padding: 24px 32px;
    max-width: 924px;
    width: 100%;
    height: 207px;
    border-radius: 10px;
    box-shadow: 0px 12px 24px -4px #ebeff3;
    background-color: #0d2675;
    color: #fff;
}
.headerLogo {
    height: 48px;
    width: 210px;
    margin-bottom: 32px;
    align-self: flex-end;
    background-image: url("../../assets/PremiumLogo.svg?1");

    background-repeat: no-repeat;
    background-position: left;
    background-size: contain;
}
.pContainer {
    grid-row: 2;

    max-width: 457px;
    width: 100%;
}
.pContainer p {
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 125%;
}
.button,
.buttonConfig {
    grid-column: 2;
    margin: auto 0 0 auto;

    cursor: pointer;
    display: flex;
    place-content: center;
    align-items: center;
    width: 183px;
    height: 48px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #0d2675;
    background: #ffff;
    border-radius: 8px;
    transition: background-color 0.3s;
}
.button:hover,
.buttonConfig:hover {
    filter: brightness(0.9);
    transition: filter 0.3s;
    color: #0d2675;
    cursor: pointer;
}
.buttonConfig {
    width: 272px;
}
.configIcon {
    background-image: url("../../assets/setting-2.svg");
    background-repeat: no-repeat;
    background-position: center;
    width: 24px;
    height: 24px;
    margin: 0 9.23px 0 0;
}
@media screen and (max-width: 771px) {
    .mainContainer {
        height: fit-content;
    }
}
@media screen and (max-width: 710px) {
    .mainContainer {
        flex-direction: column;
        padding: 36px 4.75% 36px 4.75%;
        width: 100%;
        max-height: fit-content;
    }

    .headerLogo {
        height: 24px;
        width: 100%;
        margin-bottom: 0;
    }
    .pContainer p {
        font-size: 16px;
        margin: 8px 0 11px 0;
    }
    .diamond,
    .diamondLottie {
        grid-column: 1;
        grid-row: 1;
        margin: 0 auto;
    }
    .pContainer {
        padding: 8px 0px;
    }
    .button,
    .buttonConfig {
        grid-column: 1;
        width: 100%;
        padding: 0;
        max-height: 40px;
        margin: 0;
    }
    .diamondLottie {
        margin: 0 auto;
    }
}
