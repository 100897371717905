.container {
    width: 100%;
}
.header {
    padding-bottom: 16px;
    border-bottom: 1px solid #d4d4d4;
}
.title {
    font-weight: 500;
    font-size: 25px;
    letter-spacing: 0.02em;
    color: #4d4d4d;
    margin: 0;
    margin-right: 10px;
}
.titleContainer {
    display: flex;
    align-items: center;
}
.topHead {
    display: flex;
    align-items: center;
    padding-bottom: 16px;
}
.containerHeaderComponent {
    display: none;
}
.description {
    font-weight: normal;
    font-size: 13px;
    letter-spacing: 0.02em;
    color: rgba(77, 77, 77, 0.8);
    margin: 0;
}
.infoIcon {
    cursor: pointer;
    background-image: url(./assets/info.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 22px;
    width: 22px;
    height: 22px;
}
.footer {
    display: flex;
    width: 100%;
    margin-top: 0px;
}
@media screen and (min-width: 850px) {
    .container {
        padding-bottom: 41px;
    }
    .header {
        padding-bottom: 24px;
    }
    .containerHeaderComponent {
        display: block;
    }
    .topHead {
        justify-content: space-between;
    }
    .title {
        font-size: 40px;
    }
    .topHead {
        padding-bottom: 24px;
    }
    .description {
        font-size: 16px;
    }
    .footer {
        display: none;
        margin-top: 0;
    }
}
