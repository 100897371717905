/* *resets */
.container,
.childContainer {
    margin: 0;
    padding: 0;
    border: 0;
}

/* *base styles */
.container,
.headerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
}

.childContainer {
    align-self: baseline;
}

.candado {
    background-image: url("../../../assets/lock.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 250px;
    height: 225px;
}

/* *fonts */

/* *movil */
@media screen and (min-width: 325px) {
    .container {
        padding: 57px 24px;
    }

    .header {
        justify-self: left;
        width: 100%;
    }

    .childContainer {
        width: 100%;
    }
}

/* *ipad */
/* *desktop */
@media screen and (min-width: 769px) {
    .headerContainer,
    .childContainer {
        padding: 0;
        width: 373px;
    }

    .childContainer {
        align-self: unset;
    }

    .container {
        margin: 120px 0;
    }

    .candado {
        order: -1;
    }
}
