.overlay {
    position: fixed;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
}
.noOverlay {
    pointer-events: none;
    position: absolute;
}
.userModal.header {
    position: absolute;
    opacity: 0;
    top: calc(-35px - 82px);
    right: 24px;
    padding: 18px 16px;
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    z-index: 9999;
    transition: top 0.3s ease, opacity 0.7s ease;
    pointer-events: none;
}
.userModal.header.visible {
    top: calc(35px + 82px);
    opacity: 1;
    pointer-events: visible;
}

.header {
    display: flex;
    align-items: center;
    padding: 0 0 calc(16px + 8px) 0;
    border-bottom: 1px solid #d4d4d4;
}
.profilePicture {
}
.profilePicture img {
    display: flex;
    width: 64px;
    height: 64px;
    object-fit: cover;
    border-radius: 50%;
    background: #d4d4d4;
    border: 2px solid #d4d4d4;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.userInfo {
    padding: 0 8px;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.client_verify {
    background: #f1f5f9;
    border: 1px solid #e2e8f0;
    border-radius: 20px;
    width: fit-content;
    padding: 0px 10px 0px 0px;
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    cursor: pointer;
}

.client_verify_icon {
    width: 20px;
    height: 21px;
    background-image: url(./assets/IconNoVerificado2.svg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    margin: -3px 5px -3px -3px;
}

.client_verify.client_verified .client_verify_icon {
    background-image: url(./assets/IconVerificado2.svg);
}
.client_verify.client_verified_process .client_verify_icon {
    background-image: url(./assets/IconEnVerificacion.svg);
}

.client_verify_text {
    font-size: 12px;
    color: #939393;
}

.client_verify.client_verified .client_verify_text {
    color: #4d4d4d;
}

.fullName {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #4d4d4d;
    margin-bottom: 4px;
    max-width: 165px;
}
.email {
    font-size: 13px;
    line-height: 150%;
    letter-spacing: 0.02em;

    color: rgba(77, 77, 77, 0.8);
}
.footer {
}
.menu {
    margin-top: 10px;
}
.menuItem {
    cursor: pointer;
    display: flex;
    min-width: auto;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    justify-content: flex-start;
    white-space: nowrap;
    margin-top: 8px;
    padding: 4px 0px;
}
.menuItemIcon {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 21px;
    height: 21px;
}
.menuItemText {
    font-size: 16px;
    line-height: 32px;
    color: #4d4d4d;
    margin-left: 8px;
}

.settingsIcon {
    cursor: pointer;
    display: none;
    justify-content: flex-end;
    width: 30px;
    height: 21px;
}

.settings1 {
    background-image: url(./assets/settings1.svg);
}
.settings2 {
    background-image: url(./assets/settings2.svg);
}

.logout {
    background-image: url(./assets/logout.svg);
}

@media (max-width: 431px) {
    .settingsIcon {
        display: flex;
    }
    .userInfo {
        padding: 0 0 0 8px;
    }
    .userModal.header {
        display: none;
    }
    .profilePicture img {
        width: 52px;
        height: 52px;
    }
}
