.container {
    max-width: 100%;
    padding: 24px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
}

.buttonContainer {
    max-width: 500px;
    width: 100%;
    margin-top: 8px;
}

.submitBtn:hover {
    cursor: pointer;
}

@media (min-width: 769px) {
    .container {
        max-width: 500px;
        padding: 0;
        margin: 0;
    }

    .buttonContainer {
        margin-top: 10px;
    }
}
