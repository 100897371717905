.container {
    position: relative;
    width: 100%;
}

.modalTitle {
    position: absolute;
    top: -16px;
    left: 0;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.02em;
    color: #4d4d4d;
    margin: 0;
}
.modalStepsTitle {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.02em;
    color: #4d4d4d;
    margin: 0 0 16px;
}
.logoContainer {
    display: flex;
    justify-content: center;
}
.logo {
    display: inline-block;
    width: 203.27px;
    height: 208.01px;
    margin: 29px 0 16px;
}
.listContainer {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    margin-bottom: 24px;
}
.bullet {
    min-width: 5px;
    min-height: 5px;
    background-image: url("./assets/Punto.svg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 8px 8px 0;
    align-self: flex-start;
}
.listItem {
    font-weight: 400;
    font-size: 13px;
    line-height: 150%;
    color: #52606c;
}
.listItem {
    font-weight: 400;
    font-size: 13px;
    line-height: 150%;
    color: #52606c;
    display: flex;
    align-items: center;
}
.listItemLink {
    text-decoration-line: underline;
    color: #006ef2;
    margin-right: 4px;
}
.btnsContainer {
    display: flex;
}
.btnDescargar {
    padding: 10px 20px;
    gap: 8px;
    width: 158.5px;
    height: 40px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 13px;
    line-height: 12px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #006ef2;
    background: transparent;
    border: none;
    width: 100%;
}
@media (min-width: 570px) {
    .modalTitle {
        top: -8px;
        left: -8px;
        font-size: 25px;
        line-height: 29px;
    }
    .logo {
        margin: 64px 0 37px;
        width: 257px;
        height: 263px;
    }
    .modalStepsTitle {
        margin-left: -8px;
    }
    .listContainer {
        margin-left: -8px;
    }
    .listItem {
        font-size: 16px;
    }
    .bullet {
        margin-top: 10px;
    }
    .listItemLink {
        margin-right: 5px;
    }
    .btnDescargar {
        font-size: 15px;
        line-height: 12px;
    }
}
