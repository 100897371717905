.body {
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow-y: scroll;
    max-height: 265px;
}
/* ===== Scrollbar CSS ===== */
/* Firefox */
.body {
    scrollbar-width: thin;
    scrollbar-color: #c7c7c7 #ffffff;
}

/* Chrome, Edge, and Safari */
.body::-webkit-scrollbar {
    width: 15px;
}

.body::-webkit-scrollbar-track {
    background: #ffffff;
}

.body::-webkit-scrollbar-thumb {
    background-color: #c7c7c7;
    border-radius: 10px;
    border: 6px solid #ffffff;
}
.card {
    border: 1px solid rgba(212, 212, 212, 0.5);
    border-radius: 10px;
    width: 100%;
    height: 76px;
    display: flex;
    align-items: center;
    padding: 16px 24px;
    gap: 12px;
}
.cardActive {
    border: 1.5px solid #006EF2;
    border-radius: 10px;
    width: 100%h;
    height: 80px;
    display: flex;
    align-items: center;
    padding: 16px 24px;
    gap: 12px;
}
.cardActive .circleRadio {
    border: 1.5px solid #006EF2;
    display: grid;
    place-content: center;
}
.cardActive .circleRadio::after {
    content: "";
    background: #006EF2;
    width: 8px;
    height: 8px;
    border-radius: 100px;
    display: block;
}
.cardMainContainer {
    display: flex;
    align-items: center;
    gap: 12px;
}
.circleRadio {
    margin-left: auto;
    border: 1.5px solid #d4d4d4;
    width: 16px;
    height: 16px;
    border-radius: 100px;
}
.visaLogo {
    background-image: url("../../assets/Vector-visa.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 20.89px;
    width: 64.11px;
}
.masterCardLogo {
    background-image: url("../../assets/mastercard.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 47px;
    width: 64.11px;
}
.cardTitle {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 25px;
    color: #4d4d4d;
    letter-spacing: 0.02em;
}
.cardDescription {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: #52606c;
}
@media screen and (max-width: 768px) {
    .card,
    .cardActive {
        height: 68px;
        padding: 14px 24px 14px 20px;
    }
}
