.container {
    max-width: 1390px;
    margin: 0 auto;
    padding: 69px 32px 69px 40px;
    background-image: url(./assets/background.svg);
    background-repeat: no-repeat;
    background-position: right top;
    background-size: auto;
    padding-bottom: 69px !important;
}

.container.widget {
    background-image: url(./assets/background-widget-1.svg);
    background-position: right -15px top 23vh;
}

.cardContainer {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.relampago {
    position: relative;
    flex: 1;
    overflow: hidden;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #fff;
}

.relampago:after {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background-image: url(./assets/bluepoints-desktop.svg);
    background-position: center top;
    background-repeat: no-repeat;
    background-size: auto;
    top: -70px;
    left: 18vw;
    z-index: -1;
}

@media screen and (max-width: 1025px) {
    .container {
        justify-content: center;
        padding: 69.37px 40px;
    }

    .cardContainer {
        flex-direction: column;
        justify-content: center;
        gap: 16px;
    }
}

@media screen and (max-width: 821px) {
    .container {
        background-image: none;
        width: 100%;
    }

    .container.widget {
        background-image: none;
    }

    .cardContainer {
        padding: 0;
    }

    .relampago:after {
        background-image: url(./assets/bluepoints-2.svg);
        background-size: cover;
        top: -18px;
        left: 10px;
        z-index: -1;
    }
}

@media screen and (max-width: 450px) {
    .container {
        padding: 24px 16px;
    }

    .cardContainer {
        flex-direction: column;
        overflow-x: unset;
    }
}