.onBoardingSections {
    display: flex;
    flex-direction: column;
    row-gap: 48px;
    margin-top: 48px;
}
.buttons {
    width: 100%;
    display: flex;
    justify-content: space-around;
    column-gap: 16px;
}
.errorMsgContainer {
    margin: 16px 0;
}

@media (min-width: 540px) {
    .buttons {
        justify-content: flex-end;
        column-gap: 24px;
    }
}
@media (min-width: 768px) {
    .onBoardingSections {
        row-gap: 92px;
    }
    .errorMsgContainer {
        margin: 32px 0;
    }
    .onBoardingSections {
        margin-top: 64px;
    }
}
