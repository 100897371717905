.title {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 100%;
    letter-spacing: 0.02em;
    color: #282828;
    margin: 0 0;
    position: relative;
    width: max-content;
}
@media (min-width: 768px) {
    .title {
        font-weight: 700;
        font-size: 32px;
        line-height: 36px;
    }
    .stars {
        right: -25px;
    }
}
