@font-face {
  font-family: "Noway";
  font-weight: bold;
  font-style: normal;
  src: url("//db.onlinewebfonts.com/t/7e0f75fa1eb5ee523ae2961d4e7a11a6.eot");
  src: url("//db.onlinewebfonts.com/t/7e0f75fa1eb5ee523ae2961d4e7a11a6.eot?#iefix")
      format("embedded-opentype"),
    url("//db.onlinewebfonts.com/t/7e0f75fa1eb5ee523ae2961d4e7a11a6.woff2") format("woff2"),
    url("//db.onlinewebfonts.com/t/7e0f75fa1eb5ee523ae2961d4e7a11a6.woff") format("woff"),
    url("//db.onlinewebfonts.com/t/7e0f75fa1eb5ee523ae2961d4e7a11a6.ttf") format("truetype"),
    url("//db.onlinewebfonts.com/t/7e0f75fa1eb5ee523ae2961d4e7a11a6.svg#Noway") format("svg");
}
@font-face {
  font-family: "Noway";
  font-weight: normal;
  font-style: normal;
  src: url("//db.onlinewebfonts.com/t/1b186240287df767976b05cd51ceb761.eot");
  src: url("//db.onlinewebfonts.com/t/1b186240287df767976b05cd51ceb761.eot?#iefix")
      format("embedded-opentype"),
    url("//db.onlinewebfonts.com/t/1b186240287df767976b05cd51ceb761.woff2") format("woff2"),
    url("//db.onlinewebfonts.com/t/1b186240287df767976b05cd51ceb761.woff") format("woff"),
    url("//db.onlinewebfonts.com/t/1b186240287df767976b05cd51ceb761.ttf") format("truetype"),
    url("//db.onlinewebfonts.com/t/1b186240287df767976b05cd51ceb761.svg#Noway") format("svg");
}
.remcontador_contador {
  display: flex;
  justify-content: center;
}
.remcontador_group {
  margin-right: 27px;
}
.remcontador_group:last-of-type {
  margin-right: 0;
}
.remcontador_value {
  font-size: 84px;
  font-family: "Noway", sans-serif;
  font-style: normal;
  font-weight: bold;
  letter-spacing: -0.0859731px;
  text-align: center;
  color: #282828;
}
.remcontador_label {
  font-family: "Noway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  letter-spacing: -0.0859731px;
  text-align: center;
  color: rgba(77, 77, 77, 0.5);
}

.remcontador_finished .remcontador_label,
.remcontador_finished .remcontador_value {
  color: #000000;
  opacity: 0.2;
}

.remcontador_ilustracion {
  max-width: 90vw;
  margin: 0 auto;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.remcontador_ilu_maintenance {
  width: 541px;
  height: 268px;
  background-image: url(./assets/mante.png);
  margin: 59px auto 24px;
}
.remcontador_ilu_toopen {
  width: 575px;
  height: 425px;
  background-image: url(./assets/toopen.svg);
}
.remcontador_ilu_finished {
  width: 475px;
  height: 541px;
  background-image: url(./assets/finished.svg);
  margin: 59px auto 24px;
}

@media screen and (max-width: 651px) {
  .remcontador_ilu_toopen {
    width: 346px;
    height: 327px;
  }
  .remcontador_ilu_finished {
    width: 285px;
    height: 325px;
  }
  .remcontador_value {
    font-size: 50px;
  }
  .remcontador_label {
    font-size: 15px;
  }
}
