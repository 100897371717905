.mainContainer{
  background-color: #F4F6F8;
  border-radius: 16px;
  padding: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.active{
  border: 1px solid #006EF2;
}
.disabled{
  opacity: 0.5;
}