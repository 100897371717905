/* *reset */
.total,
.servicio,
.secondText {
    margin: 0;
    padding: 0;
}

.container {
    margin-bottom: 24px;
}

.boton_Billetera,
.boton_BilleteraDisabled {
    width: 100%;
    height: auto;
    min-height: 48px;
    padding: 5px 10px;
    border-radius: 5px;
    border: medium none;
    outline: currentcolor none medium;
    font-weight: 500;
    font-size: 16px;
    line-height: 14px;
}

.boton_Billetera {
    background-color: #006ef2;
    color: white;
    cursor: pointer;
}

.boton_BilleteraDisabled {
    background-color: #f5f7fa;
    color: #a1a2a3;
}

.img {
    margin-right: 15px;
    margin-top: -3px;
    /* background-color: yellowgreen; */
}

.boton_Billetera_Modal {
    display: block;
    margin: 0;
    padding: 0;
    opacity: 1;
    color: #4d4d4d;
    border-radius: 10px;
}

.boton_Billetera_Modal_top {
    display: flex;
}

.boton_Billetera_Modal_close {
    width: 24px;
    height: 24px;
    margin-top: -12px;
    background-image: url(./assets/close_modal.svg);
    background-size: 12px 12px;
    background-position: center;
    background-repeat: no-repeat;
    margin-left: auto;
    cursor: pointer;
}

/* div.boton_Billetera_Modal_header h1 {
    font-weight: 500;
    font-size: 25px;
    margin-bottom: 20px;
} */

.boton_Billetera_dsc {
    font-size: 13px;
    line-height: 15px;
    color: #4d4d4d80;
    margin: 0;
    padding: 0;
}

.boton_Billetera_Modal_body_saldo {
    padding: 10px 15px;
    width: calc(100%);
    height: 51px;
    background-color: #f6f6f6;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* font-size: 13px;
    color: #4d4d4dcc; */
    margin-bottom: 20px;
}

.total {
    font-weight: 400;
    font-size: 13px;
    line-height: 21px;
    margin: 10px 0;
    height: 20px;
    text-align: center;
}

.footer_buttons_container {
    display: flex;
    justify-content: space-between;
}

.footer_text_container {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0 14px;
    width: calc(100%);
}

.footer_text {
    font-weight: 500;
    font-size: 16px;
    color: #4d4d4d;
}

.boton_Billetera_info_container {
    margin: 20px 0;
    display: flex;
    align-items: center;
}

.boton_Billetera_info_circle {
    width: 24px;
    height: 24px;
    background-image: url("./assets/info_circle.svg");
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 8px;
    cursor: pointer;
}

.cancelar_btn {
    border: 1px solid #d4d4d4;
    border-radius: 8px;
    color: #4d4d4d80;
    background-color: #fff;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    padding: 11px 42px;
    min-width: 155.5px;
}

.pagar_btn {
    background: #006ef2;
    border-radius: 8px;
    border: none;
    font-weight: 500;
    font-size: 16px;
    color: #fff;
    padding: 11px 52px;
    min-width: 155.5px !important;
}

.boton_Billetera_transfe {
    font-size: 11px;
    font-weight: normal;
    color: #4d4d4d80;
    margin-top: 10px;
    margin-bottom: 0;
}

.serviceRow {
    display: flex;
    align-items: center;
}

.serviceCol {
    margin-right: 14px;
}

.secondText {
    margin: 0;
    font-weight: normal;
    font-size: 13px;
    line-height: 21px;
    color: #4d4d4d;
}

.servicio {
    margin: 0 !important;
    font-weight: normal;
    font-size: 10px;
    line-height: 100%;
    color: rgba(77, 77, 77, 0.5);
}

.animation {
    display: flex;
    align-items: center;
    justify-content: center;
}

.animation {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}

.animation.showError {
    opacity: 1;
}

.errorContainer {
    margin-top: 8px;
    min-height: 16px;
}

.errorIcon {
    width: 16px;
    height: 16px;
    margin-right: 5px;
    background-image: url(./assets/error-icon.svg);
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
}

.errorText {
    font-weight: 700;
    font-size: 13px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #e00021;
}

@media screen and (max-width: 650px) {
    .boton_Billetera_dsc {
        font-size: 15px;
    }

    .boton_Billetera_Modal_body_saldo {
        font-size: 15px;
    }

    .boton_Billetera_Modal_body {
        margin-left: 15px;
        margin-right: 15px;
        /* background-color: wheat; */
    }
}
