.confetti {
    position: absolute;
    bottom: 200px;
    transform: translate(0, 50%);
    scale: 1.1;
}
@media (min-width: 570px) {
    .confetti {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
    }
}
