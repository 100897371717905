.container{
  background-color: #0D2675;
  border-radius: 10px;
  box-shadow: 0px 12px 24px -4px rgba(0, 110, 242, 0.15);
  padding: 32px;
  padding-bottom: 16px;
  position: relative;
  bottom:0;
  right: 0;
  background-image: url('../../assets/starDecorCard.svg');
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
}
.DiscoundBadge{
  
}
.dataContainer{
  display: flex;
  justify-content: space-between;
}
.dataUserContainer{
  flex: 1;
}
.PremiumLogo{
  background-image: url('../../assets/PremiumLogo.svg');
  background-repeat: no-repeat;
  background-size: contain;
  width: 120px;
  height: 27px;
}
.clientInfo{
  margin-top: 24px;
  color: #FFFFFF;
}
.verifiedContainer{
  display: flex;
  align-items: center;
  gap: 5px;
}
.checkSvg{
  background-image: url('../../assets/checkicon.svg');
  background-repeat: no-repeat;
  background-size: contain;
  width: 12px;
  height: 12px;
}
.verifiedLabel{
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.048px;
}
.clientName{
  font-size: 26px;
  letter-spacing: -0.088px;
  font-weight: 500;
}
.date{
  font: size 12px;
  letter-spacing:-0.048px;
  text-transform: uppercase;
  color: #FFFFFF;
  font-weight: 400;
  opacity: 0.5;
}
.buttonBeneficios{
    display: none;
    z-index: 1;
}
.copyRContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  font-size: 8px;
  width: 100%;
  gap: 5px;
  margin-top: 24px;
}
.starDecoration{
  z-index: 0;
  position: absolute;
  bottom:0;
  right: 0;
  background-image: url('../../assets/starDecorCard.svg');
  background-repeat: no-repeat;
  background-size: cover;
  width: 379px;
  height: 212px;
}

@media (max-width: 610px) {
  .container{
    min-height: 531px;
    display: flex;
    flex-direction: column;
    padding-bottom: 32px;
    justify-content: space-between;
  }
  .clientInfo{
    margin-top: 72px;
  }
  .dataContainer{
    flex-wrap: wrap;
    flex-direction: column;
  }
  .starDecoration{
    height: 250px;
  }
  .buttonBeneficios{
  display: block;
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 8px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  padding-inline: 32px;
  font-size: 16px;
  color:#0D2675;
  font-weight: 500;
}
.buttonBeneficios:hover{
 opacity: 0.8;
}
 .copyRContainer{
    flex-direction: column;
    position: absolute;
    color:#52606C;
    bottom: -54px;
    right: 0;
  }
  .container{
    margin-bottom: 75px;
    background-position:0% 25%;
    background-size: 100% 235%;
  }
}