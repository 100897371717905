.continueNext {
  display: flex;
  cursor: pointer;
  width: 182px;
  height: 48px;
  background-color: rgb(0, 110, 242);
  padding: 5px 10px;
  border-radius: 5px;
  border: medium none;
  outline: currentcolor none medium;
  color: white;
  letter-spacing: 0.02em;
  font-size: 15px;
  margin-left: auto;
  margin-top: 15px;
  align-items: center;
}
.text_container {
  display: flex;
  width: 100%;

  align-items: center;
  justify-content: center;
}
.text {
}
.flecha {
  width: 9px;
  height: 15px;
  margin-left: 10px;
  background-image: url(./assets/flecha.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
