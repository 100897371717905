.loginContainer {
    width: 100%;
}

.illustrationContainer {
    display: none;
}
.illustrationTextContainer {
    display: none;
}
.imgHeader {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 209px;
    border-radius: 0 0 32px 32px;
    margin-bottom: 40px;
    object-fit: cover;
}

@media screen and (min-width: 769px) {
    .main {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .loginContainer {
        width: 65%;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .loginSection {
        max-width: 500px;
        width: 100%;
    }
    .illustrationContainer {
        display: block;
        position: relative;
        /*  background-color: #006ef2; */
        height: 100vh;
        width: 41%;
        max-width: 650px;
        overflow: hidden;
        top: 0;
        right: 0;
    }
    .illustration {
        position: fixed;
        height: 100vh;
        width: 41%;
        max-width: 650px;
        object-fit: cover;
        border-top-left-radius: 100px;
        top: 0;
        right: -27px;
    }
    .illustrationTextContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        max-width: 650px;
        height: 173px;
        width: 41%;
        padding-right: 27px;
        position: fixed;
        z-index: 2;
        bottom: 0px;
        right: -27px;

        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(15, 15, 15, 0.6) 40.46%);
    }
    .illustrationTextTitle {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 126.19%;
        letter-spacing: 0;
        color: #ffffff;
        margin-bottom: 21px;
    }
    .illustrationTextDescription {
        max-width: 400px;
        width: 100%;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 154.69%;
        text-align: center;
        letter-spacing: 0;
        color: #ffffff;
        text-align: center;
    }
    .imgHeader {
        display: none;
    }
}
