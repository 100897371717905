.container {
    margin-top: 60px;

    padding: 0 20px;
}
.title {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #4d4d4d;
    margin-bottom: 8px;
}
.subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 29px;
    color: #282828;
}
.cardContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px;
    margin: -16px;
}
@media screen and (max-width: 1286px) {
    .cardContainer {
        overflow-x: scroll;
    }
}
/* @media screen and (max-width: 1025px) {
    .cardContainer {
        overflow-x: scroll;
    }
} */

@media screen and (max-width: 821px) {
    .container {
        width: 100%;
        /* padding: 0; */
    }
    .title {
        font-size: 20px;
        line-height: 23px;
        margin-bottom: 16px;
    }

    .subtitle {
        display: none;
    }
}

@media screen and (max-width: 415px) {
    .container {
        margin-top: 24.5px;
    }
}
