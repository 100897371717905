.content {
  min-height: 420px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  animation-duration: 0.5s;
  animation-name: animate-fade;
  animation-delay: 0.1s;
  animation-fill-mode: backwards;
}
.animation {
  display: block;
  margin: 20px auto 0;
}

.footer {
  padding: 12px 24px 0;
}

.title {
  font-weight: 500;
  font-size: 25px;
  margin-bottom: 16px;
  text-align: center;
}

.title p {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #4d4d4d;
}

@keyframes animate-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
