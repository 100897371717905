.overlay {
  position: fixed;
  opacity: 0;
  background: #000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  cursor: pointer;
  touch-action: none;
  transition: all 0.4s ease-in-out;
}

.overlay.animate {
  opacity: 0.3;
}

.modal {
  opacity: 0;
  position: fixed;
  background: #fff;
  padding: 20px;
  width: 92%;
  max-width: 425px;
  height: -moz-fit-content;
  height: fit-content;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  z-index: 1001;
  bottom: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.4s ease-in-out;
}

.modal.noTouch {
  touch-action: none;
}

.modal.animate {
  opacity: 1;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.close {
  right: 20px;
  top: 27px;
  z-index: 1002;
}
.modalHeader img {
  margin-left: auto;
  width: 16px;
  height: 16px;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .modal {
    padding: 40px;
  }
}
