.container {
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 9999999;
  bottom: -60px;
  right: 16px;
  background: #ffffff;
  border-radius: 4px;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 30%);
}

.iconContainer {
  display: flex;

  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 18px;
  width: 60px;
  height: 60px;
  background-color: #fdc000;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.icon {
  width: 24px;
  height: 24px;
  background-image: url(./assets/refresh.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
}

.text {
  padding: 8px;
  min-width: 255px;
  display: flex;
  flex-direction: column;
  color: #4d4d4d;
  font-size: 12px;
  line-height: 30px;
}

.title {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 6px;
}

.subtitle {
  font-size: 12px;
  font-weight: normal;
  margin: 0 !important;
}

.close {
  width: 12px;
  height: 12px;
  margin: 0 16px;
  background-image: url(./assets/close.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
}

.container.show {
  opacity: 1;
  bottom: 16px;
}

.container.hide {
  opacity: 0;
  bottom: -60px;
}

@media screen and (max-width: 450px) {
  .container {
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    width: 90%;
  }
}
