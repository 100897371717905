.onBoardingSection {
    margin: 50px 16px 32px;
    display: flex;
    justify-content: center;
}
.onBoardingSection2 {
    margin: 24px 16px 65px;
    display: flex;
    justify-content: center;
    position: relative;
    min-height: 65vh;
    align-items: center;
}
.confetti {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 9999;
}

.onBoardingContent {
    width: 100%;
    max-width: 924px;
}
@media (min-width: 768px) {
    .onBoardingSection,
    .onBoardingSection2 {
        margin: 80px 16px 50px;
    }
    .onBoardingSection2 {
        min-height: auto;
    }
}
