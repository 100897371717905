.overlay {
    position: fixed !important;
    opacity: 0;
    background: #000;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    cursor: pointer;
    touch-action: none !important;
    transition: all 0.4s ease-in-out;
}

.overlay.animate {
    opacity: 0.3;
}

.modal,
.modal2,
.aniversarioStyle {
    position: fixed;
    padding: 0px;
    width: calc(100% + 2px);
    max-height: 95%;
    height: -moz-fit-content;
    height: fit-content;
    overflow-y: auto;
    border-radius: 20px 20px 0 0;
    box-shadow: 0 0 10px rgb(0 0 0 / 25%);
    z-index: 1002;
    bottom: 0;
    left: calc(50% + 1px);
    top: 150%;
    transform: translate(-50%, 150%);
    transition: 0.4s ease-in-out;
}

.modal,
.modal2 {
    background: #fff;
}
.aniversarioStyle {
    background: #006ef2;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
}

.modalContent {
    padding: 20px;
}
.modalContentNewPadding {
    padding: 24px;
}

.modalContentWallet {
    padding: 16px;
}
.modalContent2 {
    padding: 20px 16px;
}

.noTouch {
    touch-action: none;
}

.modal.animate,
.modal2.animate {
    transform: translate(calc(-50% - 1px), -100%);
    top: 100%;
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.titleContainer {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}
.modalTitle,
.whiteTitle {
    font-weight: 500;
    font-size: 25px;
    line-height: 29px;
    letter-spacing: 0.02em;
}
.modalTitle {
    color: #4d4d4d;
}
.whiteTitle {
    color: #fff;
}

.close,
.closeWhite {
    background-size: 100% 100%;
    width: 19px;
    height: 18px;
    right: 20px;
    top: 27px;
    z-index: 1002;
    cursor: pointer;
}

.closeNew {
    width: 16px;
    background-size: 100% 100%;
    height: 16px;
    margin: 11px 8px 0 0;
}

.closeBlack {
    min-width: 32px;
    min-height: 32px;
    background-size: 100% 100%;
    position: relative;
    right: 0px;
    top: 0px;
    align-self: flex-start;
}
.closeNew {
    background-image: url("../assets/closeModalNew.svg");
}
.close {
    background-image: url("../assets/closeModal.svg");
}

.closeWhite {
    background-image: url("../assets/closeModal.svg");
}
.closeBlack {
    background-image: url("../assets/closeBlack.svg");
}

.modalHeader img {
    margin-left: auto;
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.modalBody {
}

@media (min-width: 570px) {
    .modal,
    .modal2 {
        width: calc(100% - 16px * 2);
        opacity: 0;
        transform: translate(-50%, -50%);
    }
    .modal2 {
        max-width: 439px;
    }

    .modal.animate,
    .modal2.animate {
        top: 50%;
        opacity: 1;
        transform: translate(-50%, -50%);
        transition: 0.2s ease-in-out;
    }
    .modal {
        border-radius: 20px;
        max-width: 425px;
    }
    .modal2 {
        border-radius: 20px;
        max-width: 500px;
    }

    .overlay {
        background: #000;
    }

    .modalContent {
        padding: 40px;
    }

    .modalContentNewPadding {
        padding: 2;
    }
    .modalContent2 {
        padding: 20px;
    }

    .closeWhite {
        background-image: url("../assets/closeSvgWhite.svg");
    }
    .closeNew {
        margin: 0 0;
    }
}
