.mainContainer{
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.headerTitle{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 29px;
  letter-spacing: 0.02em;
  color: #4D4D4D;
}
.addCard{
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100%;
    margin-top: -8px;
    cursor: pointer;
}
.addIcon {
    width: 24px;
    height: 24px;
    background-image: url("../../assets/card-add.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.addLocationText{
    font-weight: 500;
    font-size: 16px;
    line-height: 18.75px;
    color: #006EF2;
}
.buttonsContainer{
  display: flex;
  justify-content: flex-end;
  gap: 24px;
}
.buttonCancel,
.buttonGuardar{
  display: grid !important;
  place-content: center !important ;
  width: 104px ;
  height: 40px !important;
  cursor: pointer !important;
  padding: 10px 24px !important;
  border-radius: 8px !important;
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 15px !important;
  letter-spacing: 0.02em !important;
  transition: transform 0.2s ease-in-out;
}
.buttonCancel{
  border: 1px solid rgba(212, 212, 212, 0.5);
  transition: all 0.2s ease-in-out;
}
.buttonCancel:hover{
  border: 1px solid rgba(212, 212, 212, 0.5);
  background-color: #eeeeee;
  transition: all 0.2s ease-in-out;
}
.buttonGuardar{
  color: #ffff;
  background: #006EF2;
}
@media screen and (max-width:568px) {
  .buttonCancel,
  .buttonGuardar{
    width: 100%;
  }
  .headerTitle{
    margin: 0 30px 8px 0;
    font-size: 20px;
  }
  .mainContainer{
    padding:32px 16px 24px 16px ;
    gap: 32px;
  }
}