.welcomeCardSection {
    margin-top: 32px;
    position: relative;
}
.welcomeCardContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 54px 16px 60px;
    background: #0d2675;
    box-shadow: 0px 5px 24px -4px rgba(0, 0, 0, 0.12);
    border-radius: 12px;
    min-height: 412px;
}
.welcomeCardContainer {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
    margin-bottom: 24px;
}
.title {
    width: 245px;
    font-family: "Noway";
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 100%;
    letter-spacing: 0.02em;
    color: #ffffff;
    text-align: center;
    margin: 0;
    padding-left: 5px;
}
.title > b {
    font-weight: 700;
}
.logoPremium {
    width: 325px;
    height: 80px;
    background-image: url(./assets/premium.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
.description {
    width: 252.67px;
    margin: 0 0 27px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 125%;
    text-align: center;
    color: #52606c;
}
.btnContainer {
    position: absolute;
    bottom: 20px;
}
.btnRegresar {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #0d2675 !important;
    width: auto;
    cursor: pointer;
    position: relative;
    z-index: 100;
    background-color: #ffffff !important;
    padding: 15px 20px;
}
.btnRegresar:hover {
    color: #006ef2;
}

@media (min-width: 768px) {
    .title {
        font-size: 24px;
        line-height: 24px;
        width: auto;
    }
    .description {
        width: fit-content;
        font-size: 16px;
        line-height: 24px;
        margin: 0 0 43px;
    }
    .btnRegresar {
        font-size: 18px;
    }
    .welcomeCardContent {
        padding: 133px 0 111px;
    }
}
