.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px;
    background: #ffffff;
    border: 1px solid #d4d4d4;
    box-sizing: border-box;
}
.menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    clear: both;
    transition: min-width 0.2s;
    min-width: 32px;
}
.menuItem {
    display: flex;
    min-width: auto;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    justify-content: flex-start;
    white-space: nowrap;
    margin: 16px 0;
    padding: 5px 0px;
}

.expandido .menu {
    min-width: 200px;
}

.activeItem {
    color: #016ef2 !important;
}

.menuItemText {
    visibility: visible;
    opacity: 1;
    font-size: 16px;
    line-height: 32px;
    color: #4d4d4d;
    margin-left: 8px;
    transition: opacity 0.2s linear;
    cursor: pointer;
}

.colapsado .menuItemText {
    width: 0;
    height: 32px;
    margin-left: 0px;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.2s, opacity 0.2s linear;
}

.menuItemIcon {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin-left: 4px;
}

.herramientasText {
    display: flex;
    justify-content: space-between;
    min-width: 172px;
}

.menuTop {
}
.menuBottom {
}

.toolsDropdown {
    padding-left: 15px;
    border-left: 3px solid #016ef2;
    margin-left: 15px;
    height: 0;
    width: 0;
    overflow: hidden;
    transition: height 0.4s;
}
.toolsDropdown.expandido {
    width: 190px;
}

.premium .toolsDropdown {
    border-left: 3px solid #0d2675;
}

.tool {
    font-weight: normal;
    font-size: 16px;
    line-height: 32px;
    color: #4d4d4db2;
}

.menuItemIcon.herramientas.active {
    background-image: url(./assets/icons/v2/active/herramientas.svg);
}
.menuItemIcon.herramientas {
    background-image: url(./assets/icons/v2/herramientas.svg);
}

.menuItemIcon.recompensas.active {
    background-image: url(./assets/icons/v2/active/ticket-star.svg);
}
.menuItemIcon.recompensas {
    background-image: url(./assets/icons/v2/ticket-star.svg);
}

.menuItemIcon.metodosDePago.active {
    background-image: url(./assets/icons/v2/active/metodos-de-pago.svg);
}
.menuItemIcon.metodosDePago {
    background-image: url(./assets/icons/v2/metodos-de-pago.svg);
}

.menuItemIcon.accountStatus.active {
    background-image: url(./assets/icons/v2/active/account-status.svg);
}
.menuItemIcon.accountStatus {
    background-image: url(./assets/icons/v2/account-status.svg);
}

.menuItemIcon.envioNacional.active {
    background-image: url(./assets/icons/v2/active/envio-nacional.svg);
}
.menuItemIcon.envioNacional {
    background-image: url(./assets/icons/v2/envio-nacional.svg);
}

.menuItemIcon.entregaDomicilio.active {
    background-image: url(./assets/icons/v2/active/entrega-domicilio.svg);
}
.menuItemIcon.entregaDomicilio {
    background-image: url(./assets/icons/v2/entrega-domicilio.svg);
}

.menuItemIcon.cupones.active {
    background-image: url(./assets/icons/v2/active/cupones.svg);
}
.menuItemIcon.cupones {
    background-image: url(./assets/icons/v2/cupones.svg);
}

.menuItemIcon.tienda.active {
    background-image: url(./assets/icons/v2/active/tienda.svg);
}
.menuItemIcon.tienda {
    background-image: url(./assets/icons/v2/tienda.svg);
}
.menuItemIcon.sugerencias.active {
    background-image: url(./assets/icons/v2/active/sugerencias.svg);
}
.menuItemIcon.sugerencias {
    background-image: url(./assets/icons/v2/sugerencias.svg);
    position: relative;
}
.menuItemIcon.premiumCard {
    background-image: url(./assets/icons/v2/premiumCard.svg);
}
.menuItemIcon.premiumCard.active {
    background-image: url(./assets/icons/v2/active/premiumCard.svg);
}

.menuItemIcon.sugerenciasNotif::after {
    content: "";
    position: absolute;
    top: -1px;
    right: -1px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #db586c;
    z-index: 1000;
    -webkit-animation-name: sugerenciasNotiff;
    animation-name: sugerenciasNotiff;
    -webkit-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-duration: 1.2s;
    animation-duration: 1.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-iteration-count: 5;
}

@-webkit-keyframes sugerenciasNotiff {
    0% {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }

    20% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1);
    }

    40% {
        -webkit-transform: scale3d(0.9, 0.9, 0.9);
        transform: scale3d(0.9, 0.9, 0.9);
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(1.03, 1.03, 1.03);
        transform: scale3d(1.03, 1.03, 1.03);
    }

    80% {
        -webkit-transform: scale3d(0.97, 0.97, 0.97);
        transform: scale3d(0.97, 0.97, 0.97);
    }

    100% {
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    10%,
    20% {
        -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    }

    30%,
    50%,
    70%,
    90% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    }

    40%,
    60%,
    80% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    }

    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
    0%,
    20%,
    40%,
    60%,
    80%,
    100% {
        -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
}

@keyframes sugerenciasNotiff {
    0% {
        opacity: 1;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }

    20% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1);
    }

    40% {
        -webkit-transform: scale3d(0.9, 0.9, 0.9);
        transform: scale3d(0.9, 0.9, 0.9);
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(1.03, 1.03, 1.03);
        transform: scale3d(1.03, 1.03, 1.03);
    }

    80% {
        -webkit-transform: scale3d(0.97, 0.97, 0.97);
        transform: scale3d(0.97, 0.97, 0.97);
    }

    100% {
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    10%,
    20% {
        -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    }

    30%,
    50%,
    70%,
    90% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    }

    40%,
    60%,
    80% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    }

    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
    0%,
    20%,
    40%,
    60%,
    80%,
    100% {
        -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
}

.menuItemIcon.logout {
    background-image: url(./assets/icons/v2/logout.svg);
}

@media (max-width: 431px) {
    .menuItem {
        margin: 8px 0;
    }
    .menuItemIcon {
        display: none;
    }
    .container {
        width: max-content;
        position: absolute;
        z-index: 9999;
        min-height: calc(100vh - 50px);
        left: 0;
        transition: all 0.4s;
    }
    .colapsado {
        left: -420px;
    }
    .expandido .toolMenu {
        /* margin-top: 10px; */
    }
}

@media screen and (min-height: 725px) {
    .container {
        touch-action: none;
    }
}
